import axios from "./axios_instance";
import { getAuthToken } from "./axios_helper";
import { getBackendUrl } from "../../config";

const baseURL = getBackendUrl();
const USER_API_BASE_URL = baseURL + "/cdc/user";
const adminURL = baseURL + "/admin";

const token = getAuthToken();

const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

class UserService {
  ////////////////////// User API //////////////////////

  createUser(user) {
    return axios.post(USER_API_BASE_URL, user, config);
  }

  getUserById(id) {
    return axios.get(USER_API_BASE_URL + "/" + id, config);
  }

  updateUser(id, user) {
    return axios.put(USER_API_BASE_URL + "/" + id, user, config);
  }

  eraseUser(id) {
    return axios.delete(USER_API_BASE_URL + "/erase/" + id, config);
  }

  deleteUser(id) {
    return axios.delete(USER_API_BASE_URL + "/delete/" + id, config);
  }

  restoreUser(id) {
    return axios.get(USER_API_BASE_URL + "/restore/" + id, config);
  }

  getUserRole() {
    return axios.get(USER_API_BASE_URL + "/role", config);
  }

  getProfile() {
    return axios.get(USER_API_BASE_URL + "/profile", config);
  }

  getInfo() {
    return axios.get(USER_API_BASE_URL + "/get-info", config);
  }

  ////////////////////// Admin API //////////////////////

  getAdminTables() {
    return axios.get(USER_API_BASE_URL + "/get-admin-tables", config);
  }

  getTableByDepartmentName(name) {
    return axios.get(
      USER_API_BASE_URL + "/get-table-by-department/" + name,
      config
    );
  }

  getAccountantTablesByDepartmentName(name) {
    return axios.get(
      USER_API_BASE_URL + "/get-accountant-tables/" + name,
      config
    );
  }

  getBinList() {
    return axios.get(USER_API_BASE_URL + "/get-erased", config);
  }

  toManager(id) {
    return axios.get(adminURL + `/to-manager/${id}`, config);
  }

  toAccountant(id) {
    return axios.get(adminURL + `/to-accountant/${id}`, config);
  }

  toUser(id) {
    return axios.get(adminURL + `/to-user/${id}`, config);
  }

  toAdmin(id) {
    return axios.get(adminURL + `/to-admin/${id}`, config);
  }

  activateUser(id) {
    return axios.get(adminURL + `/activate-user/${id}`, config);
  }

  inactivateUser(id) {
    return axios.get(adminURL + `/inactivate-user/${id}`, config);
  }

  ////////////////////// Manager API //////////////////////

  getUserList(departmentId) {
    return axios.get(USER_API_BASE_URL + `/get-users/${departmentId}`, config);
  }

  getManagerTables() {
    return axios.get(USER_API_BASE_URL + "/get-manager-tables", config);
  }

  getManagersList() {
    return axios.get(USER_API_BASE_URL + "/get-managers-list", config);
  }

  getAdminsList() {
    return axios.get(USER_API_BASE_URL + "/get-admins-list", config);
  }
}
const instance = new UserService();
export default instance;
