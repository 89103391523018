import React from "react";
import { AdminHome } from "./Admin";
import { UserHome } from "./User";
import { ManagerHome } from "./Manager";
import AccHome from "../../Accounting/AccHome";

const validRoles = ["ADMIN", "SUPERADMIN", "MANAGER", "USER"];

export const Home = ({ userRole, profession }) => {
  if (!validRoles.includes(userRole)) {
    return <div>Acceso no autorizado</div>;
  }
  switch (userRole) {
    case "ADMIN":
    case "SUPERADMIN":
      return <AdminHome />;
    case "MANAGER":
      if (profession === "ACCOUNTANT") {
        return <AccHome />;
      } else return <ManagerHome />;
    case "USER":
      return <UserHome />;
    default:
      return <div>Acceso no autorizado</div>;
  }
};
