import axios from "./axios_instance.js";
import { getAuthToken } from "../axios/axios_helper.js";
import { getBackendUrl } from "../../config.js";

const baseURL = getBackendUrl();
const CALENDAR_API_BASE_URL = baseURL + "/calendar";
const token = getAuthToken();
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

class CalendarService {
  createEvent(id, event) {
    return axios
      .post(CALENDAR_API_BASE_URL + "/add-event/" + id, event, config)
      .then((r) => {
        return r;
      })
      .catch((e) => {
        throw e;
      });
  }

  getEvents(start, end, departmentId) {
    return axios
      .get(
        `${CALENDAR_API_BASE_URL}/get-events?start=${start}&end=${end}&id=${departmentId}`,
        config
      )
      .then((r) => {
        return r;
      })
      .catch((e) => {
        throw e;
      });
  }

  getUnpublishedEvents(start, end, departmentId) {
    return axios
      .get(
        `${CALENDAR_API_BASE_URL}/get-unpublished-events?start=${start}&end=${end}&id=${departmentId}`,
        config
      )

      .then((r) => {
        return r;
      })
      .catch((e) => {
        throw e;
      });
  }

  editEvent(id, event) {
    return axios.put(
      CALENDAR_API_BASE_URL + "/edit-event/" + id,
      event,
      config
    );
  }

  deleteEvent(id) {
    return axios.delete(CALENDAR_API_BASE_URL + "/delete/" + id, config);
  }

  getUserEvents(start, end, id) {
    return axios
      .get(
        `${CALENDAR_API_BASE_URL}/get-user-events?start=${start}&end=${end}`,
        config
      )
      .then((r) => {
        return r;
      })
      .catch((e) => {
        throw e;
      });
  }

  getAccountantEvents(start, end, id) {
    return axios
      .get(`${CALENDAR_API_BASE_URL}/get-accountant-events/${id}?start=${start}&end=${end}`, config)
      .then((r) => {
        return r;
      })
      .catch((e) => {
        throw e;
      });
}

  getUserRequestedEvents(start, end) {
    return axios.get(
      `${CALENDAR_API_BASE_URL}/get-requested-events?start=${start}&end=${end}`,
      config
    );
  }

  getOtherRequestedEvents(start, end) {
    return axios.get(
      `${CALENDAR_API_BASE_URL}/get-requested-other?start=${start}&end=${end}`,
      config
    );
  }

  publishEvents(id, events) {
    return axios
      .post(CALENDAR_API_BASE_URL + "/publish-events/" + id, events, config)
      .then((r) => {
        return r;
      })
      .catch((e) => {
        throw e;
      });
  }

  requestChange(id) {
    return axios.get(CALENDAR_API_BASE_URL + "/req-change/" + id, config);
  }

  requestCancel(id) {
    return axios.get(CALENDAR_API_BASE_URL + "/req-cancel/" + id, config);
  }

  requestAccept(id) {
    return axios.get(CALENDAR_API_BASE_URL + "/req-accept/" + id, config);
  }

  requestPartialAccept(event) {
    return axios.post(
      CALENDAR_API_BASE_URL + "/req-partial-accept",
      event,
      config
    );
  }

  requestPartial(event) {
    return axios.post(CALENDAR_API_BASE_URL + "/req-partial", event, config);
  }

  getChangedEvents(start, end, id) {
    return axios.get(
      `${CALENDAR_API_BASE_URL}/get-changed-events?start=${start}&end=${end}&id=${id}`,
      config
    );
  }

  requestChangeAccept(id) {
    return axios.get(CALENDAR_API_BASE_URL + "/req-approve/" + id, config);
  }

  handleChangeCancel(id) {
    return axios.get(
      CALENDAR_API_BASE_URL + "/req-change-cancel/" + id,
      config
    );
  }
}

const instance = new CalendarService();
export default instance;
