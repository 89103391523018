import React, { useState } from "react";
// eslint-disable-next-line no-unused-vars
import Chart from "chart.js/auto";
import {
  Box,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import { TableTypography, paperStyle } from "../../../../Styles";
import { Bar, Doughnut } from "react-chartjs-2";
import { Date, Object } from "core-js";
import { useHome } from "../../../../hooks/useHome";
import { useEffect } from "react";
import WhoIsNow from "./WhoIsNow";
import { useUserList } from "../../../../hooks/useUserList";
import { departmentTitles, monthLabels } from "../../../../constants/Options";

export const ManagerHome = () => {
  const [user, setUser] = useState({});
  const [bar, setBar] = useState({});
  const [doughnut, setDoughnut] = useState({});
  const [selectedOption, setSelectedOption] = useState("");
  const [genderdOption, setGenderOption] = useState("");
  const { fetchProfile, fetchManagerBar, fetchManagerDoughnut } = useHome();
  const [departmentList, setDepartmentList] = useState([]);
  const { fetchDepartmentList } = useUserList(departmentList);
  const handleClick = (event) => {
    fetchDepartmentList(setDepartmentList);
  };
  const handleBarChange = (event) => {
    const newValue = event.target.value;
    setSelectedOption(newValue);
    fetchManagerBar(newValue, setBar);
  };
  const handleDoughnutChange = (event) => {
    const newValue = event.target.value;
    setGenderOption(newValue);
    fetchManagerDoughnut(newValue, setDoughnut);
  };
  useEffect(() => {
    fetchProfile(setUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const memoizedDoughnutData = React.useMemo(() => {
    return {
      labels: ["Femenino", "Masculino", "Otro"],
      datasets: [
        {
          label: ["Cantidad"],
          data: [doughnut[0], doughnut[1], doughnut[2]],
          borderRadius: 10,
          backgroundColor: ["#ac68ae", "#5885c8", "#397750"],
          hoverOffset: 5,
        },
      ],
    };
  }, [doughnut]);
  const doughnutConfig = {
    type: "doughnut",
    data: memoizedDoughnutData,
  };
  const barConfig = {
    type: "bar",
    data: {
      labels: monthLabels,
      datasets: [
        {
          label: "total de hs/servicio",
          data: [
            bar[1],
            bar[2],
            bar[3],
            bar[4],
            bar[5],
            bar[6],
            bar[7],
            bar[8],
            bar[9],
            bar[10],
            bar[11],
            bar[12]
          ],
          fill: false,
          backgroundColor: ["#137136", "#747474"],
          borderRadius: 10,
        },
      ],
    },
  };

  return (
    <Container>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <Paper sx={paperStyle}>
            <TableTypography>
              {user.name
                ? user.gender === "MALE"
                  ? `Bienvenido ${user.name} ${user.lastname}`
                  : `Bienvenida ${user.name} ${user.lastname}`
                : "..."}
            </TableTypography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12}>
          <WhoIsNow />
        </Grid>
        <Grid item xs={12} md={3.9}>
          <Paper sx={{ ...paperStyle, padding: 2 }}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              justifyItems={"center"}
            >
              <TableTypography fontSize={20}>
                {`Personal/servicio`}
              </TableTypography>
              &nbsp; &nbsp;
              <FormControl sx={{ minWidth: "120px" }}>
                <InputLabel size="small">Servicio</InputLabel>
                <Select
                  id="service"
                  label="Servicio"
                  name="service"
                  onOpen={handleClick}
                  onChange={handleDoughnutChange}
                  size="small"
                  value={genderdOption}
                >
                  {departmentList.length !== 0 ? (
                    departmentList.map((dept) => (
                      <MenuItem key={dept.id} value={dept.title}>
                        {departmentTitles[dept.title] || dept.title}
                      </MenuItem>
                    ))
                  ) : (
                    <Grid container alignItems="center" justifyContent="center">
                      <CircularProgress item sx={{ margin: 1 }} />
                    </Grid>
                  )}
                </Select>
              </FormControl>
            </Box>
            <Box mt={1.3}>
              <TableTypography fontSize={15}>
                {`${
                  departmentTitles[genderdOption] === undefined
                    ? "Servicio"
                    : departmentTitles[genderdOption]
                }: ${
                  Object.keys(doughnut).length !== 0
                    ? (doughnut[0] || 0) +
                      (doughnut[1] || 0) +
                      (doughnut[2] || 0)
                    : "0"
                } ${
                  (doughnut[0] || 0) +
                    (doughnut[1] || 0) +
                    (doughnut[2] || 0) ===
                  1
                    ? " persona"
                    : " personas"
                }`}
              </TableTypography>
            </Box>
            <Box mt={2}>
              <Doughnut config={doughnutConfig} data={doughnutConfig.data} />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8.1}>
          <Paper sx={{ ...paperStyle, padding: 2 }}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              justifyItems={"center"}
            >
              <TableTypography fontSize={20}>
                {`Horas/Servicio - Año ${new Date().getFullYear()}`}
              </TableTypography>
              &nbsp; &nbsp;
              <FormControl sx={{ minWidth: "120px" }}>
                <InputLabel size="small">Servicio</InputLabel>
                <Select
                  id="service"
                  label="Servicio"
                  name="service"
                  onOpen={handleClick}
                  onChange={handleBarChange}
                  size="small"
                  value={selectedOption}
                >
                  {departmentList.length !== 0 ? (
                    departmentList.map((dept) => (
                      <MenuItem key={dept.id} value={dept.title}>
                        {departmentTitles[dept.title] || dept.title}
                      </MenuItem>
                    ))
                  ) : (
                    <Grid container alignItems="center" justifyContent="center">
                      <CircularProgress item sx={{ margin: 1 }} />
                    </Grid>
                  )}
                </Select>
              </FormControl>
            </Box>
            <Box mt={1.5}>
              <Bar config={barConfig} data={barConfig.data} />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
