import { request, setAuthHeader } from "../services/axios/axios_helper.js";
import { useEffect, useState } from "react";
import { useSnackBarContext } from "./../context/SnackBarContext";

export const useRegister = ({form, setShowLogin}) => {
  const { triggerSnackbar } = useSnackBarContext();
  const onRegister = (event) => {
    event.preventDefault();
    if (
      form.name.trim() === "" ||
      form.lastname.trim() === "" ||
      form.email.trim() === "" ||
      form.password.trim() === "" ||
      form.dni.trim() === "" ||
      form.medicalRegistration.trim() === "" ||
      form.specialtie.trim() === "" ||
      form.startDate === undefined ||
      form.profession.trim() === "" ||
      form.gender.trim() === "" ||
      form.department.trim() === ""
    ) {
      triggerSnackbar("Debes completar todos los campos!", "info");
      return;
    } else if (form.dni.length >= 15) {
      triggerSnackbar(
        "El DNI ingresado contiene demasiados caracteres",
        "info"
      );
      return;
    } else if (form.medicalRegistration.length >= 15) {
      triggerSnackbar(
        "La matrícula ingresada contiene demasiados caracteres",
        "info"
      );
      return;
    }

    request("POST", "/register", {
      name: form.name.toLowerCase(),
      lastname: form.lastname.toLowerCase(),
      email: form.email.toLowerCase(),
      password: form.password,
      dni: Math.abs(form.dni),
      medicalRegistration: Math.abs(form.medicalRegistration),
      startDate: form.startDate,
      specialtie: form.specialtie,
      gender: form.gender,
      profession: form.profession,
      department: form.department,
    })
      .then((response) => {
        if (response.status === 201) {
          setAuthHeader(null);
          setShowLogin(true);
          triggerSnackbar(response.data, "success");
        }
      })
      .catch((error) => {
        setAuthHeader(null);
        triggerSnackbar(error.response.data, "error");
      });
  };
  // handle password confirmation ////////////////////////////////////////////////
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [areEquals, setAreEqual] = useState(true);
  const [emailFormat, setEmailFormat] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  const handlePasswordConfirmation = (e) => {
    setPasswordConfirmation(e.target.value);
  };

  useEffect(() => {
    const { password } = form;
    setAreEqual(password === passwordConfirmation);
  }, [passwordConfirmation, form]);

  useEffect(() => {
    setEmailFormat(
      form.email === "" ||
        (form.email.includes("@") && form.email.includes(".com"))
    );
  }, [form.email]);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };
  ///////////////////////////////////////////////////////////////////////////////

  return {
    onRegister,
    areEquals,
    handlePasswordConfirmation,
    showPassword,
    handleClickShowPassword,
    handleMouseDownPassword,
    showPassword2,
    handleClickShowPassword2,
    handleMouseDownPassword2,
    emailFormat,
  };
};
