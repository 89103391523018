import React, { useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { paperStyle, TableTypography } from "../../../../../Styles.js";
import { Edit, Undo } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ProtocolService from "../../../../../services/axios/ProtocolService.js";
import { useSnackBarContext } from "../../../../../context/SnackBarContext.js";
import { groupOptions } from "../../../../../constants/Options.js";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import LoadingMain from "../../../../public/LoadingMain.js";

export default function DocumentEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { triggerSnackbar } = useSnackBarContext();
  // API Key de TinyMCE
  const api_key = process.env.REACT_APP_TINYMCE_APIKEY;

  // Estados para el contenido del editor
  const [group, setGroup] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [pDate, setPDate] = React.useState(null);
  const [content, setContent] = React.useState("");
  const [author, setAuthor] = React.useState("");

  // Handlers para los cambios en los campos del formulario
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleGroupChange = (event) => {
    setGroup(event.target.value);
  };
  const handlePDateChange = (newValue) => {
    setPDate(newValue);
  };
  const handleEditorChange = (content, editor) => {
    setContent(content);
  };
  const handleAuthorChange = (event) => {
    setAuthor(event.target.value);
  };

  // Funcion para validar los datos
  const validateData = (data) => {
    if (data.title === "") {
      triggerSnackbar("El título no puede estar vacío", "error");
      return false;
    }
    if (data.author === "") {
      triggerSnackbar("El autor no puede estar vacío", "error");
      return false;
    }
    if (data.protocolGroup === "") {
      triggerSnackbar("El grupo no puede estar vacío", "error");
      return false;
    }
    if (data.publicationDate === null) {
      triggerSnackbar("La fecha de publicación no puede estar vacía", "error");
      return false;
    }
    if (data.content === "") {
      triggerSnackbar("El contenido no puede estar vacío", "error");
      return false;
    }
    return true;
  };
  // Funcion para obtener el contenido segun URL ID
  const getProtocol = async (id) => {
    try {
      const response = await ProtocolService.getProtocol(id);
      const data = response;
      if (data) {
        setTitle(data.title);
        setGroup(data.protocolGroup.toLowerCase());
        setPDate(dayjs(response.publicationDate));
        setContent(data.content);
        setAuthor(data.author);
      } else {
        triggerSnackbar("No se encontraron datos para el protocolo", "error");
      }
    } catch (e) {
      console.log("error: ", e);
      triggerSnackbar(
        e.response?.data?.message || "Error al obtener datos",
        "error"
      );
    }
  };
  useEffect(() => {
    getProtocol(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Funcion para subir el contenido
  const uploadContent = async () => {
    const formData = {
      id,
      title,
      protocolGroup: group.toUpperCase(),
      publicationDate: pDate,
      content,
      author,
    };
    // valido los datos
    validateData(formData) &&
      ProtocolService.editProtocol(formData)
        .then((r) => {
          triggerSnackbar(r.data, "success");
          navigate(
            formData.protocolGroup !== "PROCEDIMIENTO"
              ? "/manager/data?table=protocol"
              : "/manager/data?table=procedure"
          );
        })
        .catch((e) => {
          console.log("error: ", e);
          triggerSnackbar(e.response.data.message, "error");
        });
  };

  // Funcion para borrar el contenido
  const handleGoBack = () => {
    window.history.back();
  };

  if (
    pDate === null &&
    title === "" &&
    content === "" &&
    author === "" &&
    group === ""
  ) {
    return <LoadingMain />;
  }

  return (
    <Container>
      <Paper sx={{ ...paperStyle, mb: 1 }}>
        <TableTypography>
          Edición de documento <Edit sx={{ ml: 1 }} />
        </TableTypography>
      </Paper>
      <Paper sx={{ ...paperStyle, display: "flex", padding: 2 }}>
        <Grid container spacing={2} mt={1}>
          <Grid item sm={12}>
            <FormControl fullWidth>
              <TextField
                label="Título"
                fullWidth
                id="title"
                value={title}
                onChange={handleTitleChange}
              />
            </FormControl>
          </Grid>
          <Grid item sm={6}>
            <FormControl fullWidth>
              <TextField
                label="Autor/es"
                fullWidth
                placeholder="autores separados por coma (Ej.: Juan Perez, Maria Lopez)"
                id="author"
                value={author}
                onChange={handleAuthorChange}
              />
            </FormControl>
          </Grid>
          <Grid item sm={3}>
            <FormControl fullWidth>
              <InputLabel>Grupo</InputLabel>
              <Select
                id="protocolGroup"
                value={group}
                label="Group"
                onChange={handleGroupChange}
              >
                {Object.entries(groupOptions).map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={3}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Fecha Publicación"
                  id="createdDate"
                  value={pDate}
                  onChange={handlePDateChange}
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item sm={12}>
            <Editor
              apiKey={api_key}
              value={content}
              onEditorChange={handleEditorChange}
              init={{
                plugins:
                  "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker",
                toolbar:
                  "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | checklist numlist bullist indent outdent | removeformat",
                language: "es",
                height: 800,
                spellchecker_languages: "Spanish=es",
                spellchecker_language: "es",
                branding: false,
              }}
            />
          </Grid>
          <Grid item sm={12}>
            <Button
              variant="contained"
              color="error"
              disableElevation
              onClick={() => {
                handleGoBack();
              }}
              endIcon={<Undo />}
              sx={{ mr: 2 }}
            >
              Volver
            </Button>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                uploadContent();
              }}
              endIcon={<Edit />}
            >
              Guardar cambios
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
