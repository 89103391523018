import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
} from "@mui/material";
import { useAddEvents } from "../../../../hooks/useAddEvents";
import { TableTypography, TableTypographyLight } from "../../../../Styles";
import { Info, Label } from "@mui/icons-material";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import dayjs from "dayjs";
import {
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
function myDate(date) {
  const myDate = new Date(date);
  const day = myDate.getDate();
  const month = myDate.getMonth() + 1;
  const year = myDate.getFullYear();
  const hours = myDate.getHours();
  const minutes = myDate.getMinutes();

  const formattedDay = day < 10 ? `0${day}` : `${day}`;
  const formattedMonth = month < 10 ? `0${month}` : `${month}`;
  const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

  const formattedDate = `${formattedDay}/${formattedMonth}/${year} ${formattedHours}:${formattedMinutes}`;
  return formattedDate;
}
const EventInfoOtherRequiredDialog = ({
  open,
  handleClose,
  selectedEvent,
  updateKey,
}) => {
  const isMobile = useIsMobile();
  const { handleRequestAccept, handleRequestPartialAccept } = useAddEvents();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [parcialOpen, setParcialOpen] = useState(false);
  const handleChangeOpen = () => {
    setConfirmOpen(true);
  };
  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };
  const handleParcialOpen = () => {
    setParcialOpen(true);
  };
  const handleParcialClose = () => {
    setParcialOpen(false);
  };

  const [startTime, setStartTime] = useState(dayjs(selectedEvent?.startStr));
  const [endTime, setEndTime] = useState(dayjs(selectedEvent?.endStr));
  const start = dayjs(selectedEvent?.startStr);
  const end = dayjs(selectedEvent?.endStr);

  useEffect(() => {
    if (parcialOpen && selectedEvent) {
      setStartTime(dayjs(selectedEvent?.startStr));
      setEndTime(dayjs(selectedEvent?.endStr));
    }
  }, [parcialOpen, selectedEvent]);

  const handleStartTimeChange = (newValue) => {
    setStartTime(newValue);
    setEndTime(dayjs(selectedEvent.endStr));
  };

  const handleEndTimeChange = (newValue) => {
    setEndTime(newValue);
    setStartTime(dayjs(selectedEvent.startStr));
  };

  const isSubmitDisabled = startTime.isSame(endTime);

  const handleSubmit = () => {
    const event = {
      id: selectedEvent.id,
      startDate: startTime,
      endDate: endTime,
    };
    handleRequestPartialAccept(event, updateKey);
    handleClose();
    handleParcialClose();
  };

  return (
    <Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <TableTypography>
            <Info sx={{ mr: 1 }} />
            Información
          </TableTypography>
        </DialogTitle>
        <DialogContent
          sx={{
            minWidth: isMobile ? "100%" : 400,
          }}
        >
          {selectedEvent ? (
            <>
              <TableTypographyLight fontSize={"1.4rem"} color={"#137136"}>
                {selectedEvent.title}
              </TableTypographyLight>
              {selectedEvent.extendedProps.customProperty && (
                <TableTypographyLight>
                  Estado: {selectedEvent.extendedProps.customProperty}
                </TableTypographyLight>
              )}
              <Divider sx={{ mt: 1, mb: 1 }} />
              <TableTypographyLight>
                Desde: {myDate(selectedEvent.startStr)} hs
              </TableTypographyLight>
              <TableTypographyLight>
                Hasta: {myDate(selectedEvent.endStr)} hs
              </TableTypographyLight>
              <Divider sx={{ mt: 1, mb: 2 }} />
              <TableTypographyLight>
                Tomar parcialidad o totalidad de la guardia?
              </TableTypographyLight>
            </>
          ) : (
            <CircularProgress color="success" />
          )}
        </DialogContent>
        <Box
          sx={{
            textAlign: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <DialogActions>
            <Button onClick={handleClose} color="error">
              cerrar
            </Button>
            <Button onClick={handleParcialOpen}>parcial</Button>
            <Button onClick={handleChangeOpen}>totalidad</Button>
          </DialogActions>
        </Box>
      </Dialog>
      {/*Dialogo de aceptar totalmente una solicitud de cambio*/}
      <Dialog open={confirmOpen} onClose={handleChangeOpen}>
        <DialogTitle>
          <TableTypography>Confirme</TableTypography>
        </DialogTitle>
        <DialogContent
          sx={{ maxWidth: isMobile ? "100%" : 400, minHeight: 100 }}
        >
          <TableTypographyLight>
            Esta acción aceptará el cambio solicitado por su colega y quedará
            pendiente de aprobación por el administrador de su departamento.
          </TableTypographyLight>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <TableTypographyLight>Desea continuar?</TableTypographyLight>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="error">
            cerrar
          </Button>
          <Button
            onClick={() => {
              handleRequestAccept(selectedEvent.id, updateKey);
              handleClose();
              handleConfirmClose();
            }}
          >
            Aceptar Solicitud
          </Button>
        </DialogActions>
      </Dialog>
      {/*Dialogo de aceptar parcialmente una solicitud de cambio*/}
      <Dialog open={parcialOpen} onClose={handleParcialClose}>
        <DialogTitle>
          <TableTypography>¡Atención!</TableTypography>
        </DialogTitle>
        <DialogContent
          sx={{ minWidth: isMobile ? "100%" : 400, minHeight: 100 }}
        >
          <TableTypographyLight>
            Seleccione el rango horario de la guardia que desea aceptar.
          </TableTypographyLight>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                sx={{ marginTop: 2 }}
                label="Desde"
                name="startDate"
                format="DD/MM/YY HH:mm"
                ampm={false}
                value={startTime}
                minDate={start.startOf("day")}
                maxDate={end.endOf("day")}
                minTime={
                  startTime.day() === start.day()
                    ? start
                    : dayjs().startOf("day")
                }
                maxTime={
                  startTime.day() === end.day() ? end : dayjs().endOf("day")
                }
                onChange={handleStartTimeChange}
                views={[
                  startTime.day() === endTime.day() ? "hours" : "day",
                  "hours",
                ]}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: null,
                  seconds: null,
                }}
              />
              <DateTimePicker
                sx={{ marginTop: 2, mb: 2 }}
                label="Hasta"
                name="endDate"
                format="DD/MM/YY HH:mm"
                ampm={false}
                value={endTime}
                minDate={start.startOf("day")}
                maxDate={end.endOf("day")}
                minTime={
                  endTime.day() === start.day() ? start : dayjs().startOf("day")
                }
                maxTime={
                  endTime.day() !== start.day() ? end : dayjs().endOf("day")
                }
                onChange={handleEndTimeChange}
                views={[
                  startTime.day() === endTime.day() ? "hours" : "day",
                  "hours",
                ]}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: null,
                  seconds: null,
                }}
              />
            </LocalizationProvider>
          </FormControl>
          {isSubmitDisabled ? (
            <TableTypography fontSize={"1rem"} color={"red"}>
              La hora de inicio y finalizacion no puede ser la misma!
            </TableTypography>
          ) : (
            <>
              <Box
                display={isMobile ? "" : "flex"}
                textAlign={"center"}
                justifyContent={"center"}
              >
                <TableTypography fontSize={"1rem"}>
                  <Label sx={{ mr: 1, color: "green" }} />
                  {"Horario a cubrir:"} &nbsp;
                </TableTypography>
                <TableTypographyLight>
                  {`desde ${startTime.format("D/M HH")} hs`} &nbsp;
                </TableTypographyLight>
                <TableTypographyLight>
                  {`hasta ${endTime.format("D/M HH")} hs`}
                </TableTypographyLight>
              </Box>
              <Box
                display={isMobile ? "" : "flex"}
                textAlign={"center"}
                justifyContent={"center"}
                mt={1}
              >
                <TableTypography fontSize={"1rem"}>
                  <Label sx={{ mr: 1, color: "green" }} />
                  {"Total:"} &nbsp;
                </TableTypography>
                <TableTypographyLight>{`${endTime.diff(
                  startTime,
                  "hour"
                )} hs`}</TableTypographyLight>
              </Box>
            </>
          )}
          <Divider sx={{ mt: 2, mb: 2 }} />
          <TableTypographyLight>Desea continuar?</TableTypographyLight>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleParcialClose} color="error">
            cerrar
          </Button>
          <Button
            disabled={isSubmitDisabled}
            onClick={() => {
              handleSubmit();
            }}
          >
            Aceptar Solicitud
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EventInfoOtherRequiredDialog;
