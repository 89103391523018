import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Input,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  TableTypography,
  TableTypographyLight,
  paperStyle,
} from "../../../../../Styles.js";
import { Cancel, Delete, Send, YouTube } from "@mui/icons-material";
import VideoService from "../../../../../services/axios/VideoService.js";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useSnackBarContext } from "../../../../../context/SnackBarContext.js";
import axios from "axios";
import "video-react/dist/video-react.css";
import dayjs from "dayjs";
import { useVideoCRUD } from './../../../../../hooks/useVideoCRUD';
import { useUserList } from "../../../../../hooks/useUserList.js";
import { useIsMobile } from "../../../../../hooks/useIsMobile.js";

const UploadVideo = () => {
  const isMobile = useIsMobile();
  const [videoFile, setVideoFile] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [videoTitle, setVideoTitle] = useState();
  const [group, setgroup] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [videoDate, setDate] = useState();
  const { triggerSnackbar } = useSnackBarContext();
  const [progress, setProgress] = useState(0);
  const [departmentList, setDepartmentList] = useState([]);
  const [isRegistered, setIsRegistered] = useState(false);
  const { fetchDepartmentList } = useUserList(departmentList);
  const { hashFile } = useVideoCRUD();
  const [isUploading, setIsUploading] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [hash, setHash] = useState("");
  const handleClick = (event) => {
    fetchDepartmentList(setDepartmentList);
  };
  const formatData = async () => {
    setIsRegistered(false);
    setProgress(0);
    setVideoFile(null);
    setVideoPreview(null);
    setVideoTitle("");
    setgroup("");
    setDate(null);
    setSelectedDepartment("");
    setIsUploading(false);
  };
  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setVideoFile(file);
      const previewUrl = URL.createObjectURL(file);
      setVideoPreview(previewUrl);
      triggerSnackbar("Analizando video ...", "warning");
    }
    const hashedVideo = await hashFile(file);
    await VideoService.checkHashedVideo(hashedVideo)
      .then((response) => {
        setHash(hashedVideo);
        triggerSnackbar(response.data, "success");
      })
      .catch((error) => {
        setIsRegistered(true);
        triggerSnackbar(error.response.data.message, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "video/mp4": [],
      "video/webm": [],
      "video/ogg": [],
    },
  });
  const handleUpload = async (e) => {
    e.preventDefault();
    if (isRegistered) {
      triggerSnackbar("Este video ya ha sido subido anteriormente!", "error");
      return;
    } else if (videoFile.size > 152000000) {
      triggerSnackbar("El video es demasiado grande! - MAX 150 MB", "warning");
      return;
    } else if (
      videoTitle === undefined ||
      group === undefined ||
      videoDate === undefined ||
      selectedDepartment === undefined ||
      selectedDepartment === "" ||
      videoTitle === "" ||
      group === ""
    ) {
      triggerSnackbar("Algunos campos se encuentran vacíos!", "warning");
      return;
    } else if (videoFile === null) {
      triggerSnackbar("No se ha seleccionado ningún video!", "warning");
      return;
    }
    // Crear un objeto CancelToken para cancelar la solicitud si es necesario
    const cancelTokenSource = axios.CancelToken.source();
    setCancelTokenSource(cancelTokenSource);
    // Obtener la URL pre-firmada
    await VideoService.getPresignedUrl(videoFile.type, hash)
      .then(async (response) => {
        // Subir el archivo directamente a S3 usando la URL pre-firmada
        const videoData = {
          title: videoTitle.toLowerCase(),
          group: group.toLowerCase(),
          date: videoDate,
          department: selectedDepartment.toLowerCase(),
          hash: await hashFile(videoFile),
        };
        await axios
          .put(response.data, videoFile, {
            headers: {
              "Content-Type": videoFile.type,
            },
            onUploadProgress: (progressEvent) => {
              setIsUploading(true);
              const totalLength = videoFile.size;
              if (totalLength) {
                setProgress(
                  Math.round((progressEvent.loaded * 100) / totalLength)
                );
              }
            },
            cancelToken: cancelTokenSource.token,
          })
          .then(async (response) => {
            VideoService.addVideo(videoData).then((response) => {
              formatData();
              triggerSnackbar(response.data, "success");
            });
          })
          .catch((error) => {
            if (error.response.status === 403) {
              formatData();
              triggerSnackbar(
                "Su conexion es demasiado lenta, por favor intente nuevamente!",
                "error"
              );
            } else {
              triggerSnackbar(
                "Error del servidor, intente nuevamente en unos instantes!",
                "error"
              );
            }
          });
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  };
  const handleUploadCancel = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Upload cancelled by the user.");
    }
    triggerSnackbar("Subida de video cancelada!", "warning");
    formatData();
  };
  const handleDateChange = (e) => {
    setDate(e);
  };
  const handleInputChange2 = (e) => {
    setgroup(e.target.value);
  };
  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
  };
  const handleInputChangeV2 = (e) => {
    setVideoTitle(e.target.value);
  };

  return (
    <Paper
      sx={{
        ...paperStyle,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flexGrow: 1,
        padding: 2
      }}
    >
      <TableTypography fontSize={"1.4rem"}>
        Subir video <YouTube sx={{ ml: 1 }} />
      </TableTypography>
      <Grid container alignItems="center" justifyContent="center" flexGrow={1}>
        <Grid
          item
          container
          mt={1}
          spacing={1}
          display={"flex"}
          alignItems="center"
          justifyContent={!isMobile ? "space-between" : "center"}
        >
          <Grid item sm={3} xs={12}>
            <TextField
              fullWidth
              id="title"
              label="Título"
              value={videoTitle}
              onChange={handleInputChangeV2}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <FormControl fullWidth>
              <InputLabel>Área de interés</InputLabel>
              <Select
                id="group"
                label="Area de interés"
                value={group}
                onChange={handleInputChange2}
              >
                <MenuItem value={"CARDIOVASCULAR"}>Cardiología</MenuItem>
                <MenuItem value={"CIRUGIA"}>Cirugía</MenuItem>
                <MenuItem value={"ENDOCRINOMETABOLICO"}>
                  Endocrinometabolico
                </MenuItem>
                <MenuItem value={"GASTROENTEROLOGIA"}>
                  Gastroenterología
                </MenuItem>
                <MenuItem value={"GENERALES"}>Generales</MenuItem>
                <MenuItem value={"GINECOOBSTETRICIA"}>
                  Ginecoobstetricia
                </MenuItem>
                <MenuItem value={"HOMEOSTASIS"}>Homeostasis</MenuItem>
                <MenuItem value={"INFECTOLOGIA"}>Infectología</MenuItem>
                <MenuItem value={"NEFROLOGIA"}>Nefrología</MenuItem>
                <MenuItem value={"NEUMONOLOGIA"}>Neumonología</MenuItem>
                <MenuItem value={"NEUROLOGIA"}>Neurologia</MenuItem>
                <MenuItem value={"OTORRINOLARINGOLOGIA"}>ORL</MenuItem>
                <MenuItem value={"TOXICOLOGIA"}>Toxicología</MenuItem>
                <MenuItem value={"TRAUMATOLOGIA"}>Traumatología</MenuItem>
                <MenuItem value={"UROLOGIA"}>Urología</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={3} xs={12}>
            <FormControl fullWidth>
              <InputLabel>Departamento</InputLabel>
              <Select
                id="videoDepartment"
                label="Departmentoc"
                value={selectedDepartment}
                onChange={handleDepartmentChange}
                onOpen={handleClick}
              >
                {departmentList.length !== 0 ? (
                  departmentList.map((department) => (
                    <MenuItem key={department.id} value={department.title}>
                      {department.title === "MANAGER"
                        ? "Administradores"
                        : department.title === "PHARMACY"
                        ? "Farmacia"
                        : department.title === "URGENCY"
                        ? "Guardia"
                        : department.title === "MANAGEMENT"
                        ? "Gerencia"
                        : department.title}
                    </MenuItem>
                  ))
                ) : (
                  <Grid container alignItems="center" justifyContent="center">
                    <CircularProgress item sx={{ margin: 2 }} />
                  </Grid>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={3} xs={12}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Publicación"
                  id="date"
                  value={videoDate}
                  onChange={handleDateChange}
                  format="DD/MM/YYYY"
                  maxDate={dayjs(new Date())}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
        </Grid>
        {!videoPreview && (
          <Grid
            item
            {...getRootProps()}
            sm={12}
            mt={isMobile ? 1 : 2}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: 1,
              minHeight: "300px",
              borderRadius: 5,
              "&:hover": {
                cursor: "pointer",
                transform: "scale(0.98)",
                boxShadow:
                  "inset 7px 7px 14px #bcbcbc, inset -7px -7px 14px #fefefe",
              },
            }}
          >
            <Input {...getInputProps()} />
            <Box>
              {isDragActive ? (
                <TableTypographyLight>
                  Suelte el video aquí ...
                </TableTypographyLight>
              ) : (
                <TableTypographyLight>
                  Arrastre y suelte el video aquí, o presione para buscarlo en
                  su PC ...
                </TableTypographyLight>
              )}
            </Box>
          </Grid>
        )}
        {videoPreview && (
          <Grid
            item
            sm={12}
            display={"flex"}
            justifyContent={"center"}
            mb={isMobile ? 3 : 1}
            mt={isMobile ? 2 : 5}
          >
            <video
              src={videoPreview}
              controls
              style={{
                width: "100%",
                maxWidth: "500px",
                borderRadius: "15px",
              }}
            />
          </Grid>
        )}
        <Grid item sm={12} mb={isMobile ? 2 : 5}>
          {!isRegistered ? (
            <TableTypographyLight fontSize={"1rem"}>
              {`${
                videoFile
                  ? "nombre: " + videoFile.name
                  : "ningún video seleccionado"
              } ${
                videoFile &&
                "- tamaño: " + (videoFile.size / 1000000).toFixed(2) + " MB"
              }`}
            </TableTypographyLight>
          ) : (
            <>
              <TableTypographyLight fontSize={"1rem"}>
                Este video ya se encuentra en nuestra base de datos.
              </TableTypographyLight>
              <TableTypographyLight fontSize={"1rem"}>
                Por favor eliga un video diferente.
              </TableTypographyLight>
            </>
          )}
          {progress !== 0 && (
            <Grid item>
              <LinearProgress
                color="success"
                variant="determinate"
                value={progress}
                sx={{ mt: 1, mb: 1 }}
              />
              <TableTypographyLight fontSize={"1rem"}>
                Este proceso puede tardar unos minutos dependiendo de su
                conexión a internet
              </TableTypographyLight>
              <TableTypographyLight fontSize={"1rem"}>
                Por favor aguarde en esta página hasta que el video se haya
                subido correctamente
              </TableTypographyLight>
            </Grid>
          )}
        </Grid>
        {videoPreview != null && !isUploading && (
          <Grid item>
            {!isRegistered && (
              <Button
                onClick={handleUpload}
                endIcon={<Send />}
                disabled={isRegistered}
                sx={{ mr: 2 }}
              >
                Subir
              </Button>
            )}
            <Button color="error" onClick={formatData} endIcon={<Delete />}>
              Borrar
            </Button>
          </Grid>
        )}
        {isUploading && (
          <Grid item>
            <Button
              color="error"
              onClick={handleUploadCancel}
              endIcon={<Cancel />}
            >
              Cancelar
            </Button>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default UploadVideo;
