import { Box, Chip, Grid, Paper } from "@mui/material";
import { useGetEvents } from "../../../../hooks/useGetEvents.js";
import { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es";
import EventInfoUserRequiredDialog from "../Dialogs/EventInfoUserRequiredDialog.js";
import EventInfoOtherRequiredDialog from "../Dialogs/EventInfoOtherRequiredDialog.js";
import {
  paperStyle,
  TableTypography,
} from "../../../../Styles.js";
import { Label } from "@mui/icons-material";
import { useIsMobile } from "../../../../hooks/useIsMobile.js";

function RequiredEvents({ updateKey }) {
  const isMobile = useIsMobile();
  const [requiredCurrentDates, setRequiredCurrentDates] = useState(null);
  const [otherCurrentDates, setOtherCurrentDates] = useState(null);
  const { userReqEvents, otherReqEvents } = useGetEvents(
    "",
    "",
    "",
    "",
    "",
    requiredCurrentDates,
    otherCurrentDates
  );
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [userReqOpen, setUserReqOpen] = useState(false);
  const [otherReqOpen, setOtherReqOpen] = useState(false);
  const handleClose = () => {
    setUserReqOpen(false);
    setOtherReqOpen(false);
  };
  const handleEventUserClick = (eventClickInfo) => {
    setSelectedEvent(eventClickInfo.event);
    setUserReqOpen(true);
  };
  const handleEventOtherClick = (eventClickInfo) => {
    setSelectedEvent(eventClickInfo.event);
    setOtherReqOpen(true);
  };

  const userRequestedEvents = () => {
    return userReqEvents.map((event) => ({
      id: event.id,
      title: event.title,
      start: new Date(event.startDate),
      end: new Date(event.endDate),
      color: event.color,
      userId: event.userId,
      eventStatus: event.eventStatus,
      extendedProps: {
        customProperty:
          event.eventStatus === "REQUESTED"
            ? "⏳ Cambio solicitado"
            : "✅ Asignada",
      },
    }));
  };
  const otherRequestedEvents = () => {
    return otherReqEvents.map((event) => ({
      id: event.id,
      title: event.title,
      start: new Date(event.startDate),
      end: new Date(event.endDate),
      color: event.color,
      userId: event.userId,
      eventStatus: event.eventStatus,
      extendedProps: {
        customProperty:
          event.eventStatus === "REQUESTED"
            ? "⏳ Cambio solicitado"
            : "✅ Asignada",
      },
    }));
  };
  const handleRequiredDatesSet = (dateInfo) => {
    setRequiredCurrentDates({
      start: dateInfo.startStr,
      end: dateInfo.endStr,
    });
  };
  const handleOtherDatesSet = (dateInfo) => {
    setOtherCurrentDates({
      start: dateInfo.startStr,
      end: dateInfo.endStr,
    });
  };

  return (
    <Grid item xs={12} sm={7}>
      <Grid xs={12} sm={12}>
        <Paper sx={{ ...paperStyle, padding: 1, mb: 1 }}>
          <TableTypography>Cambios</TableTypography>
        </Paper>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Paper sx={paperStyle}>
            <Paper sx={{ ...paperStyle, backgroundColor: "#cccccc", mb: 1 }}>
              <TableTypography fontSize={"1.4rem"}>
                Mis cambios &nbsp;
                {
                  <Chip
                    icon={<Label />}
                    label={
                      <TableTypography fontSize={"1rem"}>
                        {userReqEvents.length}
                      </TableTypography>
                    }
                  />
                }
              </TableTypography>
            </Paper>
            <FullCalendar
              eventContent={(eventInfo) => {
                return (
                  <>
                    <Box>{eventInfo.event.title}</Box>
                    <Box>{eventInfo.event.extendedProps.customProperty}</Box>
                  </>
                );
              }}
              headerToolbar={{
                right: "today next",
              }}
              views={{
                listMonth: { titleFormat: { month: "long", year: "numeric" } },
              }}
              plugins={[listPlugin, interactionPlugin]}
              locale={esLocale}
              initialView="listMonth"
              events={userReqEvents ? userRequestedEvents() : null}
              selectable={true}
              eventClick={handleEventUserClick}
              datesSet={handleRequiredDatesSet}
              height={isMobile ? "auto" : 600}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper sx={paperStyle}>
            <Paper sx={{ ...paperStyle, backgroundColor: "#cccccc", mb: 1 }}>
              <TableTypography fontSize={"1.4rem"}>
                Solicitados &nbsp;
                <Chip
                  icon={<Label />}
                  label={
                    <TableTypography fontSize={"1rem"}>
                      {userReqEvents.length}
                    </TableTypography>
                  }
                />
              </TableTypography>
            </Paper>
            <FullCalendar
              eventContent={(eventInfo) => {
                return (
                  <>
                    <Box>{eventInfo.event.title}</Box>
                    <Box>{eventInfo.event.extendedProps.customProperty}</Box>
                  </>
                );
              }}
              headerToolbar={{
                right: "today next",
              }}
              views={{
                listMonth: {
                  titleFormat: { month: "long", year: "numeric" },
                },
              }}
              plugins={[listPlugin, interactionPlugin]}
              locale={esLocale}
              initialView="listMonth"
              events={otherReqEvents ? otherRequestedEvents() : null}
              selectable={true}
              eventClick={handleEventOtherClick}
              datesSet={handleOtherDatesSet}
              height={isMobile ? "auto" : 600}
            />
          </Paper>
        </Grid>
        <EventInfoUserRequiredDialog
          open={userReqOpen}
          handleClose={handleClose}
          selectedEvent={selectedEvent}
          updateKey={updateKey}
        />
        <EventInfoOtherRequiredDialog
          open={otherReqOpen}
          handleClose={handleClose}
          selectedEvent={selectedEvent}
          updateKey={updateKey}
        />
      </Grid>
    </Grid>
  );
}
export default RequiredEvents;
