import { useState } from "react";
import ProtocolService from "../services/axios/ProtocolService.js";
import { useParams } from "react-router-dom";

export const useProtocolView = ({setLoading}) => {
  // LOGIC FOR PROTOCOL RENDERING ////////////////////////////////////////////////
  const { id } = useParams();

  const fetchProtocol = async (setProtocol) => {
    await ProtocolService.getProtocol(id)
      .then((data) => {
        setProtocol(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error seteando protocolo! ", error);
      });
  };

  ////////////////////////////////////////////////////////////////////////////////
  // LOGIC FOR IMPROVE NUMBER OF PROTOCOL VIEWS //////////////////////////////////
  const ImproveViews = async () => {
    await ProtocolService.improveViews(id);
  };
  ////////////////////////////////////////////////////////////////////////////////
  // LOGIC FOR SIDEBAR CONTENT //////////////////////////////////////////////////

  const [notice, setNotice] = useState();

  const fetchNotice = async () => {
    await ProtocolService.getNotice()
      .then((r) => {
        setNotice(r.data);
      })
      .catch((e) => {});
  };
  ////////////////////////////////////////////////////////////////////////////////
  return {
    notice,
    ImproveViews,
    fetchNotice,
    fetchProtocol,
  };
};
