import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Tooltip,
} from "@mui/material";
import { Button } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { useVideoList } from "../../../../../hooks/useVideoList";
import LoadingMain from "../../../../public/LoadingMain";
import { TableTypography, paperStyle, tableStyle } from "../../../../../Styles";
import { tooltipEdit, tooltipDelete } from "../../../../../Styles";
import { esESGrid } from './../../../../../constants/esESGrid';

export function VideoTable() {
  const {
    videoList,
    deleteDialogOpen,
    editVideo,
    deleteVideo,
    handleCloseDeleteDialog,
    handleDeleteConfirmed,
    myDate,
  } = useVideoList();
  if (!videoList || videoList.length === 0) {
    return <LoadingMain />;
  }
  const formattedVideoList = videoList.map((video) => ({
    ...video,
    date: myDate(video.date),
  }));
  const columns = [
    {
      field: "videoGroup",
      headerName: "Grupo",
      flex: 2,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "title",
      headerName: "Título",
      flex: 4,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "date",
      headerName: "Publicado",
      flex: 1,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actions",
      headerName: "Acciones",
      flex: 2,
      editable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Box>
          <Tooltip title="Editar video" onClick={() => editVideo(params.id)}>
            <IconButton size="small" sx={tooltipEdit}>
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Eliminar" onClick={() => deleteVideo(params.id)}>
            <IconButton size="small" sx={tooltipDelete}>
              <Delete />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
  return (
    <Container>
      <Paper sx={{ ...paperStyle, mb: 1 }}>
        <TableTypography>Videos Institucionales</TableTypography>
      </Paper>
      <Paper sx={paperStyle}>
        <DataGrid
          sx={tableStyle}
          rows={formattedVideoList}
          columns={[...columns]}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
            filter: {
              filterModel: {
                items: [],
                quickFilterValues: [""],
              },
            },
          }}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableRowSelectionOnClick
          disableColumnMenu
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          pageSizeOptions={[5, 10, 20, 30]}
          localeText={esESGrid}
        />
      </Paper>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        style={{ textAlign: "center" }}
      >
        <DialogTitle>Confirmar Borrado</DialogTitle>
        <DialogContent>
          Seguro/a desea borrar el video seleccionado?
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteConfirmed} color="error">
            Borrar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
