import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material/styles";
import { customTheme } from "./Styles";
import ErrorBoundary from "./ErrorBoundary";
import { SnackBarProvider } from "./context/SnackBarContext";
import { CssBaseline } from "@mui/material";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <ThemeProvider theme={customTheme}>
        <CssBaseline />
        <SnackBarProvider>
          <App />
        </SnackBarProvider>
      </ThemeProvider>
    </ErrorBoundary>
  </React.StrictMode>
);
reportWebVitals();
