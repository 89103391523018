import React, { useState, useEffect } from "react";
import UserService from "../../../services/axios/UserService.js";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import {
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Paper,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBack, Update } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/es-us.js";
import dayjs from "dayjs";
import { paperStyle, TableTypography } from "../../../Styles.js";
import LoadingMain from "../../public/LoadingMain.js";
import {
  fieldLabels,
  genderOptions,
  professionOptions,
  profileInitialForm,
  specialtieOptions,
} from "../../../constants/Options.js";
import { useSnackBarContext } from "../../../context/SnackBarContext.js";
dayjs.locale("es-us");

function Profile() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { triggerSnackbar } = useSnackBarContext();
  const [user, setUser] = useState(profileInitialForm);

  const handleGoBack = () => {
    window.history.back();
  };

  useEffect(() => {
    UserService.getProfile(id).then((res) => {
      setUser(res.data);
    });
  }, [id]);

  if (user.gender === "") {
    return <LoadingMain />;
  }

  const updateUser = (e) => {
    e.preventDefault();
    UserService.updateUser(user.id, user)
      .then((r) => {
        triggerSnackbar(r.data, "success");
        navigate("/home");
      })
      .catch((error) => {
        if (error.response) {
          triggerSnackbar(error.response.data.message, "error");
        } else {
          triggerSnackbar("Error de conexión", "warning");
        }
      });
  };

  const handleDateChange = (e) => {
    setUser((prevUser) => ({
      ...prevUser,
      startDate: e,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  return (
    <Container maxWidth="sm">
      <Paper
        sx={{
          ...paperStyle,
          mb: 1,
        }}
      >
        <TableTypography>Datos Personales</TableTypography>
      </Paper>
      {(user?.role === "ADMIN" ||
        user?.role === "SUPERADMIN" ||
        user?.role === "MANAGER") && (
        <Paper sx={{ ...paperStyle, padding: 2, mb: 1 }}>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Cargo"
              name="position"
              value={user.position}
              onChange={handleChange}
              placeholder="ejemplo: Jefe/a de área ..."
            />
          </Grid>
        </Paper>
      )}
      <Paper sx={{ ...paperStyle, p: 2 }}>
        <Grid container spacing={1.5} display={"flex"} alignItems={"center"}>
          {Object.keys(fieldLabels).map((fieldName) => (
            <Grid
              item
              xs={12}
              sm={fieldName === "email" ? 12 : 6}
              key={fieldName}
            >
              <TextField
                fullWidth
                label={fieldLabels[fieldName]}
                name={fieldName}
                InputProps={{
                  inputProps: {
                    type:
                      fieldName === "dni" ||
                      fieldName === "medicalRegistration" ||
                      fieldName === "phone"
                        ? "number"
                        : "text",
                  },
                }}
                value={user[fieldName]}
                onChange={handleChange}
              />
            </Grid>
          ))}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Profesión</InputLabel>
              <Select
                name="profession"
                value={user.profession}
                onChange={handleChange}
                label="Profesion"
              >
                {Object.entries(professionOptions).map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <InputLabel>Especialidad</InputLabel>
              <Select
                name="specialtie"
                value={user.specialtie}
                onChange={handleChange}
                label="Especialidad"
              >
                {Object.entries(specialtieOptions).map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Sexo</InputLabel>
              <Select
                name="gender"
                value={user.gender}
                onChange={handleChange}
                label="Sexo"
              >
                {Object.entries(genderOptions).map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: "100%" }}
                label="Inicio de actividad"
                name="startDate"
                value={dayjs(user.startDate)}
                onChange={handleDateChange}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={12} display={"flex"} mt={3}>
            <Button
              type="button"
              fullWidth
              variant="contained"
              disableElevation
              endIcon={<Update />}
              onClick={updateUser}
              sx={{ mr: 1 }}
            >
              Actualizar
            </Button>
            <Button
              type="button"
              fullWidth
              variant="contained"
              disableElevation
              endIcon={<ArrowBack />}
              sx={{
                backgroundColor: "#942121",
              }}
              onClick={handleGoBack}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

export default Profile;
