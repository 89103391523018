import { useEffect } from "react";
import { useState } from "react";
import CalendarServices from "../services/axios/CalendarService.js";
import { useSnackBarContext } from "../context/SnackBarContext.js";

export const useGetEvents = (
  currentDates,
  userCurrentDates,
  changedCurrentDates,
  departmentId,
  updateKey,
  requiredCurrentDates,
  otherCurrentDates,
) => {
  const { triggerSnackbar } = useSnackBarContext();
  const [events, setEvents] = useState([]);
  const [unpublishedEvents, setUnpublishedEvents] = useState([]);
  const [userEvents, setUserEvents] = useState([]);
  const [userReqEvents, setUserReqEvents] = useState([]);
  const [otherReqEvents, setOtherReqEvents] = useState([]);
  const [changedEvents, setChangedEvents] = useState([]);

  useEffect(() => {
    if (currentDates) {
      const start = currentDates.start;
      const end = currentDates.end;
      fetchEvents(start, end, departmentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDates, departmentId]);

  useEffect(() => {
    if (userCurrentDates) {
      const start = userCurrentDates.start;
      const end = userCurrentDates.end;
      fetchUserEvents(start, end);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCurrentDates]);

  useEffect(() => {
    if (changedCurrentDates) {
      const start = changedCurrentDates.start;
      const end = changedCurrentDates.end;
      fetchChangedEvents(start, end, departmentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changedCurrentDates, departmentId]);

  useEffect(() => {
    if (requiredCurrentDates) {
      const start = requiredCurrentDates.start;
      const end = requiredCurrentDates.end;
      fetchUserReqEvents(start, end);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredCurrentDates]);

  useEffect(() => {
    if (otherCurrentDates) {
      const start = otherCurrentDates.start;
      const end = otherCurrentDates.end;
      fetchOtherReqEvents(start, end);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherCurrentDates]);

  const fetchEvents = async (start, end, departmentId) => {
    await CalendarServices.getEvents(start, end, departmentId)
      .then((r) => {
        setEvents(r.data);
      })
      .catch((err) => {
        setEvents([]);
        triggerSnackbar(err.response.data.message, "error");
      });
  };

  const fetchUnpublishedEvents = async (currentDates) => {
    try {
      const response = await CalendarServices.getUnpublishedEvents(
        currentDates.start,
        currentDates.end,
        departmentId
      );
      setUnpublishedEvents(response.data);
    } catch (error) {
      setUnpublishedEvents([]);
      triggerSnackbar(error.response.data.message, "info");
    }
  };

  const fetchUserEvents = async (start, end, departmentId) => {
    try {
      const response = await CalendarServices.getUserEvents(start, end);
      setUserEvents(response.data);
    } catch (error) {
      console.error("Error fetching user events:", error);
    }
  };

  const fetchUserReqEvents = async (start, end) => {
    try {
      const response = await CalendarServices.getUserRequestedEvents(
        start,
        end
      );
      setUserReqEvents(response.data);
    } catch (error) {
      console.error("Error fetching requested events:", error);
    }
  };

  const fetchOtherReqEvents = async (start, end) => {
    try {
      const response = await CalendarServices.getOtherRequestedEvents(
        start,
        end
      );
      setOtherReqEvents(response.data);
    } catch (error) {
      console.error("Error fetching requested events:", error);
    }
  };

  const fetchChangedEvents = async (start, end, departmentId) => {
    try {
      const response = await CalendarServices.getChangedEvents(start, end, departmentId);
      setChangedEvents(response.data);
    } catch (error) {
      console.log("Error fetching changed events");
    }
  };

  return {
    events,
    userEvents,
    unpublishedEvents,
    userReqEvents,
    otherReqEvents,
    changedEvents,
    fetchUnpublishedEvents,
    fetchUserReqEvents,
    fetchOtherReqEvents,
    fetchChangedEvents,
    fetchEvents,
    fetchUserEvents,
  };
};
