import * as React from "react";
import { useState } from "react";
import ProtocolService from "../../../../../services/axios/ProtocolService.js";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {
  Alert,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
  Radio,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Send } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/es-us";
import {
  protocolFormTitleBox,
  paperStyle,
  protocolFormTextField,
  TableTypography,
} from "../../../../../Styles.js";
import { useUserList } from "../../../../../hooks/useUserList.js";
import { useIsMobile } from "../../../../../hooks/useIsMobile.js";
import DocumentUpload from "./DocumentUpload.js";
import UploadVideo from "./../../Video/Admin/UploadVideo";

export default function ProtocolForm() {
  const isMobile = useIsMobile();
  const [departmentList, setDepartmentList] = useState([]);
  const { fetchDepartmentList } = useUserList(departmentList);
  dayjs.locale("es-us");
  const [mssg, setMssg] = useState("");
  const [link, setLink] = useState("");
  const navigate = useNavigate();
  const [selectedForm, setSelectedForm] = useState("protocol");
  const [checkBox, setCheckBox] = useState([]);
  const handleFormChange = (event) => {
    setSelectedForm(event.target.value);
  };
  const handleNoticeSelect = () => {
    if (selectedForm !== "notice") {
      fetchDepartmentList(setDepartmentList);
    }
  };
  const handleCheckBox = (id) => {
    setCheckBox((prevSelected) => {
      const isAlreadySelected = prevSelected.includes(id);
      if (isAlreadySelected) {
        return prevSelected.filter((departmentId) => departmentId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };
  const handleNoticeSubmit = (e) => {
    e.preventDefault();
    if (mssg === "") {
      setSnackbarMessage("Algunos campos se encuentran vacíos!");
      setSeverity("warning");
      setShowSnackbar(true);
      return;
    }
    const notice = {
      title: mssg,
      linkUrl: link,
      departments: checkBox,
    };
    ProtocolService.createNotice(notice)
      .then((response) => {
        const messageParam = encodeURIComponent(response.data);
        navigate(
          `/manager/data?table=protocol&status=success&mssg=${messageParam}`
        );
      })
      .catch((error) => {
        setSnackbarMessage(error.response.data.message);
        setSeverity("error");
        setShowSnackbar(true);
      });
  };
  const handleMssg = (e) => {
    setMssg(e.target.value);
  };
  const handleLink = (e) => {
    setLink(e.target.value);
  };
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  return (
    <Container>
      <Paper
        sx={{
          ...paperStyle,
          display: "flex",
          mb: 1,
        }}
      >
        <TableTypography fontSize={!isMobile ? "2rem" : "1.5rem"}>
          ¿Que contenido desea publicar?
        </TableTypography>
        <FormGroup
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            mt: 1,
          }}
        >
          <FormControlLabel
            control={
              <Radio
                checked={selectedForm === "protocol"}
                onChange={handleFormChange}
                value="protocol"
              />
            }
            label="Protocolo"
          />
          <FormControlLabel
            control={
              <Radio
                checked={selectedForm === "video"}
                onChange={handleFormChange}
                value="video"
              />
            }
            label="Video"
          />
          <FormControlLabel
            control={
              <Radio
                checked={selectedForm === "notice"}
                onChange={handleFormChange}
                onClick={handleNoticeSelect}
                value="notice"
              />
            }
            label="Aviso"
          />
        </FormGroup>
      </Paper>
      {selectedForm === "protocol" && <DocumentUpload />}
      {selectedForm === "video" && <UploadVideo />}
      {selectedForm === "notice" && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            minWidth: "100%",
          }}
        >
          <Box minWidth={"100%"} mb={5}>
            <Box sx={protocolFormTitleBox}>
              <Typography variant="h6" letterSpacing={0.5}>
                Mensaje del aviso
              </Typography>
            </Box>
            <TextField
              fullWidth
              id="notice"
              value={mssg}
              onChange={handleMssg}
              sx={protocolFormTextField}
            />
          </Box>
          <Box minWidth={"100%"} mb={5}>
            <Box sx={protocolFormTitleBox}>
              <Typography variant="h6" letterSpacing={0.5}>
                Link (cuestionario, video, drive, etc)
              </Typography>
            </Box>
            <TextField
              fullWidth
              id="notice"
              value={link}
              onChange={handleLink}
              sx={protocolFormTextField}
            />
          </Box>
          <Box
            minWidth={"100%"}
            mb={5}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography variant="h6">Aviso por mail:</Typography>
            {departmentList.map((d) => (
              <FormControlLabel
                sx={{ ml: 0 }}
                control={
                  <Checkbox
                    checked={checkBox.includes(d.id)}
                    onChange={() => handleCheckBox(d.id)}
                  />
                }
                label={
                  d.title === "PHARMACY"
                    ? "Farmacia"
                    : d.title === "URGENCY"
                    ? "Guardia"
                    : d.title === "MANAGEMENT"
                    ? "Gerencia"
                    : d.title
                }
                key={d.id}
              />
            ))}
          </Box>
          <Button
            type="button"
            variant="contained"
            endIcon={<Send />}
            disableElevation
            onClick={handleNoticeSubmit}
          >
            Publicar
          </Button>
        </Box>
      )}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3500}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}
