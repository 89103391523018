import { React, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Box, Container, Divider, Paper } from "@mui/material";
import { useProtocolView } from "../../../../../hooks/useProtocolView.js";
import { useMostViewedProtocol } from "../../../../../hooks/useMostViewedProtocol.js";
import Sidebar from "./Sidebar.js";
import {
  paperStyle,
  TableTypography,
  TableTypographyLight,
} from "../../../../../Styles.js";
import LoadingMain from "../../../../public/LoadingMain.js";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import dayjs from "dayjs";

function PurifiedContent({ content }) {
  const cleanHtml = DOMPurify.sanitize(content);
  return (
    <Box
      sx={{
        width: "100%",
        wordBreak: "break-word",
      }}
      dangerouslySetInnerHTML={{ __html: cleanHtml }}
    />
  );
}

function ProtocolView() {
  const { id } = useParams();
  const [loading , setLoading] = useState(true);
  const initialState = {
    title: "",
    protocolGroup: "",
    publicationDate: null,
    content: "",
  };
  const [mostViewedProtocol, setMostViewedProtocol] = useState();
  const [protocol, setProtocol] = useState(initialState);
  const { fetchMostViewedProtocol } = useMostViewedProtocol();
  const { ImproveViews, fetchNotice, fetchProtocol } = useProtocolView({setLoading});
  useEffect(() => {
    fetchProtocol(setProtocol);
    fetchMostViewedProtocol(setMostViewedProtocol);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    fetchNotice();
    ImproveViews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading) {
    return <LoadingMain />;
  }
  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item xs={12} md={8} container>
          <Paper sx={{ ...paperStyle, textAlign: "left", padding: 3 }}>
            <Grid item sm={12}>
              <TableTypographyLight>
                {protocol.protocolGroup}
              </TableTypographyLight>
              <Divider />
            </Grid>
            <Grid item sm={12} display={"flex"} textAlign={"left"}>
              <TableTypography>{protocol.title}</TableTypography>
            </Grid>
            <Grid item sm={12}>
              <TableTypographyLight fontSize={"0.9rem"}>
                {`Por ${protocol.author} - ${dayjs(protocol.publicationDate).format("DD/MM/YYYY")}`}
              </TableTypographyLight>
            </Grid>
            <Grid item sm={12}>
              <PurifiedContent content={protocol.content} />
            </Grid>
          </Paper>
        </Grid>
        <Sidebar mostViewedProtocol={mostViewedProtocol} setLoading={setLoading}/>
      </Grid>
    </Container>
  );
}

export default ProtocolView;
