import { getBackendUrl } from "../../config";
import axios from "./axios_instance";

const restoreURL = getBackendUrl() + "/password/restore/";
const resetURL = getBackendUrl() + "/password/reset/";

////////////////////// Restore Password API //////////////////////
class RestorePassword {
  restorePassword(email) {
    return axios.post(restoreURL+email);
  }
  resetPassword(token, form) {
    return axios.post(resetURL+token, form);
  }
}
const instance = new RestorePassword();
export default instance;