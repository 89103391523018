import ProtocolService from "../services/axios/ProtocolService.js";

export const useMostViewedProtocol = () => {

  const fetchMostViewedProtocol = async (setMostViewedProtocol) => {
    await ProtocolService.mostViewedProtocol().then((r) => {
      setMostViewedProtocol(r.data);
    });
  };

  return {
    fetchMostViewedProtocol,
  };
};
