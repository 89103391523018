import { Container, Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  paperStyle,
  TableTypography,
  TableTypographyLight,
  VideoPlayer,
} from "../../../../../Styles.js";
import VideoService from "../../../../../services/axios/VideoService.js";
import LoadingMain from "../../../../public/LoadingMain.js";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

const VideoView = () => {
  const { id } = useParams();
  const [video, setVideo] = useState(undefined);

  useEffect(() => {
    VideoService.getVideoById(id).then((r) => {
      setVideo(r.data);
    });
  }, [id]);

  if (video === undefined) {
    return <LoadingMain />;
  }

  return (
    <Container>
      <Paper sx={paperStyle}>
        <TableTypographyLight>{`${video.group} - ${dayjs(video.date).format('DD/MM/YY')}`}</TableTypographyLight>
        <TableTypography>
          {video.title}
        </TableTypography>
      </Paper>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid xs={12}>
          <VideoPlayer url={video.url} mt={"10px"} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default VideoView;
