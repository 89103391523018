import React from "react";
import { Box, Container, GlobalStyles, LinearProgress } from "@mui/material";
import pic from "../public/assets/images/loading-logo.svg";
const LoadingMain = () => {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box>
      <Box sx={{ width: "100%" }}>
        <LinearProgress
          color="success"
          variant="determinate"
          value={progress}
        />
      </Box>
      <Container sx={{ display: "flex", justifyContent:"center" }}>
        <GlobalStyles
          styles={{
            "@keyframes pulse": {
              "0%, 100%": { transform: "scale(1)" },
              "50%": { transform: "scale(1.2)" },
            },
          }}
        />
        <Box
          sx={{ backgroundImage: `url(${pic})` }}
          alt="Loading Logo"
          style={{
            width: 100,
            height: 100,
            borderRadius: "50%",
            boxShadow: "0 4px 8px rgba(61, 32, 92, 0.5)",
            animation: "pulse 0.8s infinite ease-in-out",
          }}
        />
      </Container>
    </Box>
  );
};

export default LoadingMain;
