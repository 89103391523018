import React from "react";
import { Backdrop, Box, GlobalStyles } from "@mui/material";
import pic from "./assets/images/loading-logo.svg";
const LoadingPage = () => {
  return (
    <>
      <GlobalStyles
        styles={{
          "@keyframes pulse": {
            "0%, 100%": { transform: "scale(1)" },
            "50%": { transform: "scale(1.2)" },
          },
        }}
      />
      <Backdrop
        sx={{
          backgroundColor: "#ffffff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={true}
      >
        <Box
          sx={{ backgroundImage: `url(${pic})` }}
          alt="Loading Logo"
          style={{
            width: 100,
            height: 100,
            borderRadius: "50%",
            boxShadow: "0 4px 8px rgba(61, 32, 92, 0.5)",
            animation: "pulse 0.8s infinite ease-in-out",
          }}
        />
      </Backdrop>
    </>
  );
};

export default LoadingPage;
