import SparkMD5 from "spark-md5";


export const useVideoCRUD = () => {
    
    /// Hashing md5 for the video file, to prevent duplicate uploads (usign spark-md5 library)
    const hashFile = async (file) => {
        return new Promise((resolve, reject) => {
            const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice,
                chunkSize = 2097152, // Read in chunks of 2MB
                chunks = Math.ceil(file.size / chunkSize),
                spark = new SparkMD5.ArrayBuffer(),
                fileReader = new FileReader();
            let currentChunk = 0;
            fileReader.onload = (e) => {
                spark.append(e.target.result); // Append array buffer
                currentChunk++;
                if (currentChunk < chunks) {
                    loadNext();
                } else {
                    resolve(spark.end());
                }
            };
            fileReader.onerror = (e) => {
                reject(e);
            };
            function loadNext() {
                const start = currentChunk * chunkSize,
                    end = start + chunkSize >= file.size ? file.size : start + chunkSize;
                fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
            }
            loadNext();
        });
    };

    
    return { hashFile };
};