import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  InputAdornment,
  IconButton,
  FormControl,
  OutlinedInput,
  InputLabel,
  MenuItem,
  Select,
  Paper,
  Fade,
  Box,
} from "@mui/material";
import { useForm } from "./../../hooks/useForm";
import { useRegister } from "../../hooks/useRegister";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import "dayjs/locale/es";
import { useState, useEffect } from "react";
import {
  departmentGroups,
  genderGroups,
  initialForm,
  professionGroups,
  specialtieGroups,
} from "../../constants/Options";
import { TableTypography } from "../../Styles";
import { useIsMobile } from "../../hooks/useIsMobile";
dayjs.locale("es");

export default function Register({ showLogin, setShowLogin }) {
  const isMobile = useIsMobile();
  const [selectedDate, setSelectedDate] = useState();
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const { form, handleChange, setForm } = useForm(initialForm);
  const {
    onRegister,
    areEquals,
    handlePasswordConfirmation,
    showPassword,
    handleClickShowPassword,
    handleMouseDownPassword,
    showPassword2,
    handleClickShowPassword2,
    handleMouseDownPassword2,
    emailFormat,
  } = useRegister({ form, setShowLogin });
  useEffect(() => {
    setForm((prevForm) => ({
      ...prevForm,
      startDate: selectedDate,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  return (
    <Fade in={!showLogin} timeout={800}>
      <Grid
        container
        justifyContent={"center"}
        display={!showLogin === false && "none"}
      >
        <Box maxWidth={isMobile ? "95%" : "sm"}>
          <Paper
            sx={{
              backgroundColor: "#dddddd",
              boxShadow: 0,
              padding: 3,
              borderRadius: 5,
              textAlign: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TableTypography>Regístrate</TableTypography>
            <Grid container display={"flex"} spacing={1} mt={1}>
              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Área</InputLabel>
                  <Select
                    label="Área"
                    id="department"
                    name="department"
                    onChange={handleChange}
                  >
                    {departmentGroups.map((e) => (
                      <MenuItem value={e.value} key={e.value}>
                        {e.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Profesión</InputLabel>
                  <Select
                    label="Profesion"
                    id="profession"
                    name="profession"
                    onChange={handleChange}
                  >
                    {professionGroups.map((e) => (
                      <MenuItem value={e.value} key={e.value}>
                        {e.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Especialidad</InputLabel>
                  <Select
                    id="specialtie"
                    label="Especialidad"
                    name="specialtie"
                    onChange={handleChange}
                  >
                    {specialtieGroups.map((g) => (
                      <MenuItem value={g.value} key={g.value}>
                        {g.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  id="medicalRegistration"
                  label="Nro. de Matrícula"
                  name="medicalRegistration"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6} sm={6} mb={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Inicio de actividad"
                    id="selectedDate"
                    value={selectedDate}
                    onChange={handleDateChange}
                    format="DD/MM/YYYY"
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-root": {},
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="name"
                  fullWidth
                  id="name"
                  label="Nombre/s"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="lastname"
                  label="Apellido/s"
                  name="lastname"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  type="number"
                  name="dni"
                  fullWidth
                  id="dni"
                  label="DNI"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Género</InputLabel>
                  <Select
                    fullWidth
                    name="gender"
                    id="gender"
                    label="Género"
                    onChange={handleChange}
                  >
                    {genderGroups.map((g) => (
                      <MenuItem value={g.value} key={g.value}>
                        {g.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  id="email"
                  label="@ e-mail"
                  name="email"
                  onChange={handleChange}
                />
                {!emailFormat && (
                  <Typography
                    align="center"
                    fontSize={"small"}
                    color={"red"}
                    marginTop={0}
                  >
                    El formato del email no es válido
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Contraseña</InputLabel>
                  <OutlinedInput
                    fullWidth
                    label="Contraseña"
                    id="password"
                    name="password"
                    onChange={handleChange}
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Repite tu contraseña</InputLabel>
                  <OutlinedInput
                    label="Repite tu contraseña"
                    name="password2"
                    id="password2"
                    onChange={handlePasswordConfirmation}
                    type={showPassword2 ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword2}
                          onMouseDown={handleMouseDownPassword2}
                          edge="end"
                        >
                          {showPassword2 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                {!areEquals && (
                  <Typography
                    align="center"
                    fontSize={"small"}
                    color={"red"}
                    marginTop={0.1}
                  >
                    Las contraseñas no coinciden!
                  </Typography>
                )}
              </Grid>
              <Button
                type="button"
                fullWidth
                variant="contained"
                disableElevation
                sx={{
                  mt: 3,
                  mb: 1,
                }}
                onClick={onRegister}
                disabled={!areEquals || !emailFormat}
              >
                Registrarme
              </Button>
            </Grid>
          </Paper>
        </Box>
      </Grid>
    </Fade>
  );
}
