import React, { useState, useEffect } from "react";
import UserService from "../../../services/axios/UserService.js";
import StatsService from "../../../services/axios/StatsService.js";
import Grid from "@mui/material/Grid";
import {
  FormControlLabel,
  Checkbox,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  IconButton,
  Box,
} from "@mui/material";
import {
  genderGroups,
  monthLabels,
  professionGroups,
  specialtieGroups,
  updateUserInitialForm,
} from "../../../constants/Options.js";
import {
  TableTypography,
  TableTypographyLight,
  paperStyle,
  tableStyle,
  tooltipAdmin,
  tooltipDelete,
} from "../../../Styles.js";
import LoadingMain from "../../public/LoadingMain.js";
import { Close, Download, Info, Visibility } from "@mui/icons-material";
import dayjs from "dayjs";
import { Bar } from "react-chartjs-2";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { esESGrid } from "../../../constants/esESGrid.js";

const Bills = ({ id }) => {
  const bills = [
    {
      id: 1,
      title: "Factura 1",
      date: "2021-10-01",
    },
    {
      id: 2,
      title: "Factura 2",
      date: "2021-10-02",
    },
    {
      id: 3,
      title: "Factura 3",
      date: "2021-10-03",
    },
    {
      id: 4,
      title: "Factura 4",
      date: "2021-10-04",
    },
    {
      id: 5,
      title: "Factura 5",
      date: "2021-10-05",
    },
    {
      id: 6,
      title: "Factura 6",
      date: "2021-10-06",
    },
    {
      id: 7,
      title: "Factura 7",
      date: "2021-10-07",
    },
    {
      id: 8,
      title: "Factura 8",
      date: "2021-10-08",
    },
    {
      id: 9,
      title: "Factura 9",
      date: "2021-10-09",
    },
    {
      id: 10,
      title: "Factura 10",
      date: "2021-10-10",
    },
    {
      id: 11,
      title: "Factura 11",
      date: "2021-10-11",
    },
    {
      id: 12,
      title: "Factura 12",
      date: "2021-10-12",
    },
  ];
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper sx={paperStyle}>
          <TableTypography fontSize={"1.5rem"}>
            Facturas emitidas
          </TableTypography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={paperStyle}>
          <DataGrid
            sx={tableStyle}
            rows={bills ? bills : []}
            columns={[
              {
                field: "title",
                headerName: "Titulo",
                flex: 1.5,
                editable: false,
                align: "center",
                headerAlign: "center",
              },
              {
                field: "date",
                headerName: "Fecha",
                flex: 1.5,
                editable: false,
                align: "center",
                headerAlign: "center",
              },
              {
                field: "actions",
                headerName: "Acciones",
                flex: 1.5,
                editable: false,
                align: "center",
                headerAlign: "center",
                renderCell: (params) => (
                  <Box>
                    <Tooltip
                      size="small"
                      title={`Mostrar factura`}
                      onClick={() => {
                        "";
                      }}
                    >
                      <IconButton sx={tooltipAdmin}>
                        <Visibility />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={`Descargar factura`}>
                      <IconButton
                        onClick={() => {
                          "";
                        }}
                      >
                        <Download />
                      </IconButton>
                    </Tooltip>
                  </Box>
                ),
              },
            ]}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
              filter: {
                filterModel: {
                  items: [],
                  quickFilterValues: [""],
                },
              },
            }}
            disableDensitySelector
            disableRowSelectionOnClick
            disableColumnMenu
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            pageSizeOptions={[5, 10, 20, 30]}
            localeText={esESGrid}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

const Licences = ({ id }) => {
  const licences = [
    // licencias segun las columnas de la tabla
    {
      id: 1,
      name: "Licencia 1",
      lastname: "Apellido 1",
      from: "2021-10-01",
      to: "2021-10-02",
      duration: "1 día",
    },
    {
      id: 2,
      name: "Licencia 2",
      lastname: "Apellido 2",
      from: "2021-10-03",
      to: "2021-10-04",
      duration: "1 día",
    },
    {
      id: 3,
      name: "Licencia 3",
      lastname: "Apellido 3",
      from: "2021-10-05",
      to: "2021-10-06",
      duration: "1 día",
    },
    {
      id: 4,
      name: "Licencia 4",
      lastname: "Apellido 4",
      from: "2021-10-07",
      to: "2021-10-08",
      duration: "1 día",
    },
    {
      id: 5,
      name: "Licencia 5",
      lastname: "Apellido 5",
      from: "2021-10-09",
      to: "2021-10-10",
      duration: "1 día",
    },
    {
      id: 6,
      name: "Licencia 6",
      lastname: "Apellido 6",
      from: "2021-10-11",
      to: "2021-10-12",
      duration: "1 día",
    },
    {
      id: 7,
      name: "Licencia 7",
      lastname: "Apellido 7",
      from: "2021-10-13",
      to: "2021-10-14",
      duration: "1 día",
    },
    {
      id: 8,
      name: "Licencia 8",
      lastname: "Apellido 8",
      from: "2021-10-15",
      to: "2021-10-16",
      duration: "1 día",
    },
    {
      id: 9,
      name: "Licencia 9",
      lastname: "Apellido 9",
      from: "2021-10-17",
      to: "2021-10-18",
      duration: "1 día",
    },
  ];
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper sx={paperStyle}>
          <TableTypography fontSize={"1.5rem"}>
            Licencias
          </TableTypography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={paperStyle}>
          <DataGrid
            sx={tableStyle}
            rows={licences ? licences : []}
            columns={[
              {
                field: "name",
                headerName: "Nombre",
                flex: 1.5,
                editable: false,
                align: "center",
                headerAlign: "center",
              },
              {
                field: "lastname",
                headerName: "Apellido",
                flex: 1.5,
                editable: false,
                align: "center",
                headerAlign: "center",
              },
              {
                field: "from",
                headerName: "Desde",
                flex: 1.5,
                editable: false,
                align: "center",
                headerAlign: "center",
              },
              {
                field: "to",
                headerName: "Hasta",
                flex: 1.5,
                editable: false,
                align: "center",
                headerAlign: "center",
              },
              {
                field: "duration",
                headerName: "Duración",
                flex: 1.5,
                editable: false,
                align: "center",
                headerAlign: "center",
              },
              {
                field: "actions",
                headerName: "Acciones",
                flex: 1.5,
                editable: false,
                align: "center",
                headerAlign: "center",
                renderCell: (params) => (
                  <Box>
                    <Tooltip
                      size="small"
                      title={`Mostrar factura`}
                      onClick={() => {
                        "";
                      }}
                    >
                      <IconButton sx={tooltipAdmin}>
                        <Visibility />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={`Descargar factura`}>
                      <IconButton
                        onClick={() => {
                          "";
                        }}
                      >
                        <Download />
                      </IconButton>
                    </Tooltip>
                  </Box>
                ),
              },
            ]}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
              filter: {
                filterModel: {
                  items: [],
                  quickFilterValues: [""],
                },
              },
            }}
            disableDensitySelector
            disableRowSelectionOnClick
            disableColumnMenu
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            pageSizeOptions={[5, 10, 20, 30]}
            localeText={esESGrid}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

const AccountantBar = ({ id }) => {
  const [bar, setBar] = useState({});
  useEffect(() => {
    StatsService.getAccountantBar(id)
      .then((res) => {
        setBar(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [id]);
  const barConfig = {
    type: "bar",
    data: {
      labels: monthLabels,
      datasets: [
        {
          label: "total de hs",
          data: [
            bar[1],
            bar[2],
            bar[3],
            bar[4],
            bar[5],
            bar[6],
            bar[7],
            bar[8],
            bar[9],
            bar[10],
            bar[11],
            bar[12],
          ],
          fill: false,
          backgroundColor: ["#0d604c", "#747474"],
          borderRadius: 10,
        },
      ],
    },
  };
  return <Bar config={barConfig} data={barConfig.data} />;
};

function User({ id }) {
  const [user, setUser] = useState(updateUserInitialForm);
  useEffect(() => {
    UserService.getUserById(id).then((res) => {
      setUser(res.data);
    });
  }, [id]);
  if (user.profession === "") {
    return <LoadingMain />;
  }
  const info = [
    { title: "Nombre", value: user.name },
    { title: "Apellido", value: user.lastname },
    { title: "DNI", value: user.dni },
    {
      title: "Género",
      value: genderGroups.find((g) => g.value === user.gender).name,
    },
    { title: "Email", value: user.email },
    { title: "Nro. teléfono", value: user.phone },
    {
      title: "Profesión",
      value: professionGroups.find((p) => p.value === user.profession).name,
    },
    { title: "Matrícula Médica", value: user.medicalRegistration },
    {
      title: "Especialidad",
      value: specialtieGroups.find((d) => d.value === user.specialtie).name,
    },
    {
      title: "Inicio de Actividades",
      value: dayjs(user.startDate).format("DD/MM/YYYY"),
    },
    {
      title: "Antigüedad",
      value: `${dayjs().diff(dayjs(user.startDate), "year")} años, ${
        dayjs().diff(dayjs(user.startDate), "month") % 12
      } meses y ${dayjs().diff(dayjs(user.startDate), "day") % 30} días`,
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12} sm={4} paddingX={2} alignContent={"center"}>
        {info.map((i) => (
          <TableTypography
            jc={"left"}
            fontSize={"1.5rem"}
            key={i.medicalRegistration}
          >
            {i.title}: &nbsp;
            <TableTypographyLight fontSize={"1.4rem"}>
              {i.value}
            </TableTypographyLight>
          </TableTypography>
        ))}
      </Grid>
      <Grid
        item
        xs={12}
        sm={2}
        container
        display={"flex"}
        flexDirection={"column"}
        textAlign={"left"}
        justifyContent={"center"}
      >
        <Grid item>
          <TableTypography fontSize={"1.5rem"}>
            Areas de trabajo:
          </TableTypography>
        </Grid>
        <Grid item display={"flex"} flexDirection={"column"}>
          {user.allDepartments.map((d) => (
            <FormControlLabel
              sx={{ ml: 1 }}
              control={
                <Checkbox
                  checked={user.departments.some((dep) => dep.id === d.id)}
                />
              }
              label={
                d.title === "PHARMACY"
                  ? "Farmacia"
                  : d.title === "URGENCY"
                  ? "Guardia"
                  : d.title === "MANAGEMENT"
                  ? "Gerencia"
                  : d.title === "ACCOUNTING"
                  ? "Contaduría"
                  : d.title
              }
              key={d.id}
            />
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Paper sx={paperStyle}>
          <TableTypography>Horas trabajadas por mes</TableTypography>
          <AccountantBar id={id} />
        </Paper>
      </Grid>
    </Grid>
  );
}

const ShowUser = ({ open, handleClose, userId }) => {
  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <DialogTitle>
        <Paper sx={paperStyle}>
          <Grid container>
            <Grid item xs={11.5}>
              <TableTypography>
                <Info sx={{ mr: 1 }}></Info>Información de usuario
              </TableTypography>
            </Grid>
            <Grid item xs={0.5}>
              <Tooltip title="cerrar" sx={tooltipDelete}>
                <IconButton onClick={handleClose}>
                  <Close />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Paper>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <User id={userId} />
          </Grid>
          <Grid item xs={6}>
            <Bills id={userId} />
          </Grid>
          <Grid item xs={6}>
            <Licences id={userId} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ShowUser;
