import * as React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useSidebar } from "../../../../../hooks/useSidebar";
import {
  Avatar,
  Badge,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  IconButton,
  Skeleton,
} from "@mui/material";
import { CloudDownload } from "@mui/icons-material";
import {
  paperStyle,
  TableTypography,
  TableTypographyLight,
} from "../../../../../Styles";
import { useNavigate } from "react-router-dom";
import "./CardAnimation.css";

function Sidebar({ driveLink, mostViewedProtocol, setLoading }) {
  const navigate = useNavigate();
  const { notice } = useSidebar();
  return (
    <Grid
      item
      md={4}
      xs={12}
      sm={12}
      container
      display={"flex"}
      flexDirection={"row"}
    >
      <Grid item xs={12} sm={12}>
        {mostViewedProtocol && (
          <Badge
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{ width: "100%" }}
            badgeContent={`Más leído`}
            color="success"
          >
            <CardActionArea
              onClick={() => {
                setLoading(true);
                navigate(`../protocol/view/${mostViewedProtocol.id}`);
              }}
            >
              <Card
                className="element"
                sx={{
                  display: "flex",
                  color: "white",
                  padding: 1,
                  opacity: "0.90",
                  boxShadow: 0,
                  borderRadius: 5,
                  height: "100%",
                }}
              >
                <CardContent
                  sx={{ flex: 1, zIndex: 1, backdropFilter: "blur(0.5px)" }}
                >
                  <Typography variant="h7">
                    {mostViewedProtocol.protocolGroup}
                  </Typography>
                  <Divider sx={{ mt: 1, mb: 1 }} />
                  <Grid>
                    <Typography variant="h5" fontWeight={"bold"} paragraph>
                      {mostViewedProtocol.title}
                    </Typography>
                  </Grid>
                  <TableTypographyLight>
                    Continuar leyendo ...
                  </TableTypographyLight>
                </CardContent>
              </Card>
            </CardActionArea>
          </Badge>
        )}
        <Paper
          sx={{
            ...paperStyle,
            display: "flex",
            textAlign: "left",
            mt: 1,
            mb: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              textAlign: "left",
              width: "100%",
              paddingX: 2,
            }}
          >
            <TableTypography>Aviso!</TableTypography>
          </Box>
          <Box>
            {notice ? (
              <Box padding={2}>
                <TableTypographyLight>{notice.title}</TableTypographyLight>
                <Typography marginTop={2}>
                  <Link
                    href={notice.linkUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Link...
                  </Link>
                </Typography>
              </Box>
            ) : (
              <Skeleton variant="" height={100} sx={{ borderRadius: 5 }} />
            )}
          </Box>
        </Paper>
        <Paper sx={paperStyle}>
          <IconButton
            href={`${driveLink?.driveLink}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Avatar sx={{ backgroundColor: "#083a2e", color: "#ffffff" }}>
              <CloudDownload />
            </Avatar>
          </IconButton>
          <Link
            href={`${driveLink?.driveLink}`}
            target="_blank"
            rel="noopener noreferrer"
            underline="hover"
          >
            Descargar protocolo
          </Link>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Sidebar;
