import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
  Grid,
  Box,
  ListSubheader,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useAddEvents } from "../../../../hooks/useAddEvents";
import { useState } from "react";
import { customTheme } from "../../../../Styles";
import CircularProgress from "@mui/material/CircularProgress";
import { Circle } from "@mui/icons-material";
import { ColorOptions } from "../../../../constants/Options";

const EventEditDialog = ({
  open,
  handleClose,
  setEditOpen,
  initialEvent,
  currentDates,
  updateKey,
  departmentId,
  userList,
}) => {
  const { event, handleChange, handleEditEvent } = useAddEvents(initialEvent);
  const [formChanged, setFormChanged] = useState(false);
  const shouldDisableEndDate = (day) => {
    return event.startDate && day.isBefore(event.startDate, "day");
  };

  const handleFormChange = () => {
    setFormChanged(true);
  };

  const handleCancelFormChange = () => {
    setFormChanged(false);
    setEditOpen(false);
  };

  return (
    <ThemeProvider theme={customTheme}>
      <Dialog open={open} onClose={handleCancelFormChange}>
        <DialogTitle sx={{ mb: 1 }}>Editar guardia médica:</DialogTitle>
        <DialogContent
          sx={{
            alignContent: "center",
            alignItems: "center",
            maxWidth: 400,
          }}
        >
          <FormControl sx={{ mb: 1, mt: 1 }} id="event-add" fullWidth>
            <InputLabel>Médico</InputLabel>
            <Select
              label="Médico"
              id="id"
              name="id"
              value={event.userId || initialEvent.userId}
              onChange={(e) => {
                handleChange("id", e);
                handleFormChange();
              }}
            >
              {userList.length !== 0 ? (
                userList.map((user) => (
                  <MenuItem
                    key={user.id}
                    value={user.id}
                  >{`${user.name} ${user.lastname}`}</MenuItem>
                ))
              ) : (
                <Grid container alignItems="center" justifyContent="center">
                  <CircularProgress item sx={{ margin: 2 }} />
                </Grid>
              )}
            </Select>
            <FormControl sx={{ mt: 1 }}>
              <InputLabel>Turno</InputLabel>
              <Select
                label="Turno"
                value={event.color || initialEvent.color}
                onChange={(e) => {
                  handleChange("color", e);
                  handleFormChange();
                }}
                renderValue={(selectedValue) => {
                  const selectedItem = ColorOptions.find(
                    (item) => item.value === selectedValue
                  );
                  return (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Circle
                        sx={{
                          color: selectedItem?.value,
                          mr: 2,
                        }}
                      />
                      {selectedItem?.name}
                    </Box>
                  );
                }}
              >
                {ColorOptions.map((color, index) => (
                  <MenuItem
                    key={color.value}
                    value={color.value}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                    }}
                  >
                    <ListSubheader
                      sx={{
                        borderRadius: 15,
                      }}
                    >
                      {color.category}
                    </ListSubheader>
                    <Box
                      style={{
                        borderRadius: 15,
                      }}
                    >
                      <Circle sx={{ color: color.value, mr: 2 }} />
                      {color.name}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormControl>
          <Divider sx={{ mt: 1, mb: 2 }} />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              sx={{
                mb: 1,
                width: "100%",
              }}
              label="Inicia"
              format="DD/MM/YYYY HH:mm"
              ampm={false}
              value={event.startDate || initialEvent.startDate}
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: null,
                seconds: null,
              }}
              onChange={(e) => {
                handleChange("start", e);
                handleFormChange();
              }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              sx={{
                mb: 1,
                width: "100%",
              }}
              label="Finaliza"
              format="DD/MM/YYYY HH:mm"
              ampm={false}
              value={event.endDate || initialEvent.endDate}
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: null,
                seconds: null,
              }}
              onChange={(e) => {
                handleChange("end", e);
                handleFormChange();
              }}
              shouldDisableDate={shouldDisableEndDate}
            />
          </LocalizationProvider>
          <Divider sx={{ mt: 1, mb: 2 }} />
          <TextField
            label="Estado"
            value={
              event.eventStatus === "CREATED"
                ? "❌ Sin publicar"
                : "✅ Publicado"
            }
            disabled
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {},
            }}
          ></TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelFormChange} color="error">
            cerrar
          </Button>
          <Button
            onClick={() => {
              handleEditEvent(currentDates, departmentId, updateKey);
              handleCancelFormChange();
              handleClose();
            }}
            disabled={!formChanged}
          >
            Actualizar
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default EventEditDialog;
