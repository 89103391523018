import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React from "react";
import { useLogin } from "../../hooks/useLogin";
import { useForm } from "../../hooks/useForm";
import { TableTypography, TableTypographyLight } from "../../Styles";

const RestorePassword = ({ open, handleDialogClose }) => {
  const initialForm = { email: "" };
  const { handleChange, form } = useForm(initialForm);
  const { onRestorePassword } = useLogin(form);
  return (
    <Dialog open={open} onClose={handleDialogClose}>
      <DialogTitle>
        <TableTypography>Recupera tu contraseña:</TableTypography>
      </DialogTitle>
      <DialogContent
        sx={{
          alignContent: "center",
          alignItems: "center",
          maxWidth: 400,
        }}
      >
        <DialogContentText mb={2}>
          <TableTypographyLight>
            Ingresa el e-mail asociado a la contraseña que quieres reestablecer:
          </TableTypographyLight>
        </DialogContentText>
        <FormControl fullWidth>
          <InputLabel>@ e-mail</InputLabel>
          <OutlinedInput
            required
            fullWidth
            label="@ e-mail"
            id="email"
            name="email"
            onChange={handleChange}
            type="email"
          />
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          onClick={onRestorePassword}
          disableElevation
          sx={{
            mt: 3,
            mb: 1,
            width: "100%",
          }}
        >
          recuperar
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default RestorePassword;
