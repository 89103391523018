import React, { useState } from "react";
import {
  Avatar,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Pagination,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Description, KeyboardArrowRight, Search } from "@mui/icons-material";
import { useProtocolList } from "../../../../../hooks/useProtocolList";
import LoadingMain from "../../../../public/LoadingMain";
import {
  TableTypography,
  TableTypographyLight,
  paperStyle,
} from "../../../../../Styles";
import { useNavigate } from "react-router-dom";
import { useIsMobile } from "../../../../../hooks/useIsMobile";

export default function ProtocolList() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { protocolList } = useProtocolList();
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const maxLength = 50;
  if (!protocolList) {
    return <LoadingMain />;
  }
  const protocolsPerPage = 6;
  const filteredProtocols = protocolList.filter(
    (protocol) =>
      protocol.title.toLowerCase().includes(searchText.toLowerCase()) ||
      protocol.protocolGroup.toLowerCase().includes(searchText.toLowerCase())
  );

  function generate() {
    const startIndex = (page - 1) * protocolsPerPage;
    const endIndex = startIndex + protocolsPerPage;
    const currentPageProtocols = filteredProtocols.slice(startIndex, endIndex);

    return currentPageProtocols.map((protocol) => (
      <Paper sx={{ ...paperStyle, mb: 0.5, padding: 0 }} key={protocol.id}>
        <Stack direction="row" alignItems="center">
          <Tooltip
            onClick={() => navigate(`../protocol/view/${protocol.id}`)}
            title="Abrir"
          >
            <IconButton sx={{ backgroundColor: "#13713640", ml: 1, mr: 1 }}>
              <Description sx={{ color: "#dddddd" }} />
            </IconButton>
          </Tooltip>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                textAlign: "left",
              }}
            >
              <TableTypographyLight>
                {protocol.protocolGroup.toLowerCase()}{" "}
              </TableTypographyLight>
            </Grid>
            <Grid
              item
              xs={12}
              sm={7}
              sx={{
                display: "flex",
                justifyContent: "left",
                textAlign: "left",
              }}
            >
              <Typography color="#484848" fontSize={"1rem"}>
                {protocol.title.length > maxLength
                  ? `${protocol.title.substring(0, 50).toLowerCase()} ...`
                  : protocol.title.toLowerCase()}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              sx={{
                display: { xs: "none", sm: "flex" },
                justifyContent: "left",
              }}
            >
              <TableTypographyLight>
                {protocol.publicationDate}
              </TableTypographyLight>
            </Grid>
          </Grid>
          <Tooltip title="Abrir">
            <IconButton
              onClick={() => navigate(`../protocol/view/${protocol.id}`)}
            >
              <Avatar>
                <KeyboardArrowRight />
              </Avatar>
            </IconButton>
          </Tooltip>
        </Stack>
      </Paper>
    ));
  }

  return (
    <Container>
      <Grid container display={"flex"} flexDirection={"column"} flexGrow={1}>
        <Grid item>
          <Paper
            sx={{
              ...paperStyle,
              mb: 1,
            }}
          >
            <Grid
              container
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              marginInline={!isMobile ? 5 : 0}
            >
              <Grid item sm={6} display={isMobile && "none"}>
                <TableTypography>Protocolos Médicos</TableTypography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Buscar en protocolos..."
                  variant="outlined"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  sx={{ maxWidth: "20rem" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search color="#747474" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item sx={{ display: { xs: "none", sm: "block" } }}>
          <Paper
            sx={{
              ...paperStyle,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 0.5,
            }}
          >
            <Grid item xs={12}>
              <TableTypography fontSize={"1.2rem"}>Grupo</TableTypography>
            </Grid>
            <Grid item xs={12}>
              <TableTypography fontSize={"1.2rem"}>Título</TableTypography>
            </Grid>
            <Grid item xs={12}>
              <TableTypography fontSize={"1.2rem"}>Publicación</TableTypography>
            </Grid>
          </Paper>
        </Grid>
        <Grid item>{generate()}</Grid>
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "end",
            flexGrow: 1,
            mt: 2,
          }}
        >
          <Pagination
            count={Math.ceil(filteredProtocols.length / protocolsPerPage)}
            page={page}
            onChange={(event, value) => {
              setPage(value);
            }}
            color="primary"
            variant="text"
          />
        </Grid>
      </Grid>
    </Container>
  );
}
