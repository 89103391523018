import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import esLocale from "@fullcalendar/core/locales/es";
import interactionPlugin from "@fullcalendar/interaction";
import EventAddDialog from "../Dialogs/EventAddDialog.js";
import EventInfoDialog from "../Dialogs/EventInfoDialog.js";
import { Box, Container, Paper, Grid } from "@mui/material";
import { useGetEvents } from "../../../../hooks/useGetEvents.js";
import EventPublishDialogList from "../Dialogs/EventPublishDialogList.js";
import { useIsMobile } from "../../../../hooks/useIsMobile.js";
import { useParams } from "react-router-dom";
import "../../../../FullCalendarStyles.css";
import { paperStyle, statusMap, TableTypography } from "../../../../Styles.js";

const AdminCalendar = () => {
  const isMobile = useIsMobile();
  const { id, title } = useParams();
  const [updateKey, setUpdateKey] = useState(0);
  const [currentDates, setCurrentDates] = useState(null);
  const [open, setOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [publishOpen, setPublishOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const { events, unpublishedEvents, fetchUnpublishedEvents } = useGetEvents(
    currentDates,
    "",
    "",
    id,
    setUpdateKey
  );
  const handleEditOpen = () => {
    setOpen(true);
  };
  const handlePublishOpen = () => {
    setPublishOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setInfoOpen(false);
    setPublishOpen(false);
  };
  const handleEventClick = (eventClickInfo) => {
    setSelectedEvent(eventClickInfo.event);
    setInfoOpen(true);
  };

  const renderEvents = () => {
    return events.map((event) => ({
      id: event.id,
      title: event.title,
      start: new Date(event.startDate),
      end: new Date(event.endDate),
      color: event.color,
      userId: event.userId,
      eventStatus: event.eventStatus,
      extendedProps: {
        customProperty: statusMap[event.eventStatus],
      },
    }));
  };

  const handleDatesSet = (dateInfo) => {
    setCurrentDates({
      start: dateInfo.startStr,
      end: dateInfo.endStr,
    });
  };

  return (
    <Container key={updateKey}>
      <Grid container display="flex" flexDirection="column" textAlign="center">
        <Paper sx={paperStyle}>
          <TableTypography>
            {title === "MANAGER"
              ? "Administradores"
              : title === "PHARMACY"
              ? "Farmacia"
              : title === "URGENCY"
              ? "Guardia"
              : title === "MANAGEMENT"
              ? "Gerencia"
              : title}
          </TableTypography>
          <Box mt={2}>
            <FullCalendar
              eventContent={(eventInfo) => {
                return (
                  <>
                    <Box>{eventInfo.timeText}</Box>
                    <Box>{eventInfo.event.title}</Box>
                    <Box>{eventInfo.event.extendedProps.customProperty}</Box>
                  </>
                );
              }}
              headerToolbar={{
                center: "addEvent publishEvent",
                right: "today prev,next",
              }}
              plugins={[timeGridPlugin, interactionPlugin]}
              titleFormat={{ year: "numeric", month: "short" }}
              locale={esLocale}
              initialView="timeGridWeek"
              height={800}
              views={{
                timeGridWeek: {
                  type: "timeGrid",
                  duration: isMobile ? { days: 1 } : { days: 4 },
                  slotDuration: { hours: 1 },
                  expandRows: "true",
                  nowIndicator: "true",
                },
              }}
              allDaySlot={false}
              events={events ? renderEvents() : null}
              selectable={true}
              eventClick={handleEventClick}
              customButtons={{
                addEvent: {
                  text: "Agendar +",
                  hint: "agenda una guardia médica en el calendario",
                  click: function () {
                    handleEditOpen();
                  },
                },
                publishEvent: {
                  text: "Publicar ↑",
                  hint: "publica y hace efectivas las guardias médicas agendadas",
                  click: function () {
                    fetchUnpublishedEvents(currentDates);
                    handlePublishOpen();
                  },
                },
              }}
              datesSet={handleDatesSet}
            />
          </Box>
        </Paper>
      </Grid>
      {currentDates && (
        <EventAddDialog
          open={open}
          handleClose={handleClose}
          currentDates={currentDates}
          updateKey={setUpdateKey}
        />
      )}
      {currentDates && (
        <EventInfoDialog
          open={infoOpen}
          handleClose={handleClose}
          selectedEvent={selectedEvent}
          currentDates={currentDates}
          updateKey={setUpdateKey}
          departmentId={id}
        />
      )}
      {currentDates && (
        <EventPublishDialogList
          open={publishOpen}
          handleClose={handleClose}
          unpublishedEvents={unpublishedEvents}
          currentDates={currentDates}
          updateKey={setUpdateKey}
          departmentId={id}
        />
      )}
    </Container>
  );
};

export default AdminCalendar;
