import React from "react";
import { setAuthHeader } from "../../../services/axios/axios_helper.js";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Grid,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AccountCircle,
  AddIcCall,
  ArrowDropDownSharp,
  CalendarMonth,
  ChangeCircle,
  Description,
  Home,
  Logout,
  MonetizationOn,
  Newspaper,
  Person,
  PersonSearch,
  Source,
  UploadFile,
  YouTube,
} from "@mui/icons-material";
import TodayIcon from "@mui/icons-material/Today";
import {
  appBarStyle,
  appBarButtons,
  AppBarImage,
  TableTypographyLight,
} from "../../../Styles.js";
import { useUserList } from "../../../hooks/useUserList.js";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import { Header } from "../../public/Header.js";
import { useIsMobile } from "../../../hooks/useIsMobile.js";
import {
  adminPages,
  contentPages,
  userPages,
  userSettings,
} from "../../../constants/Options.js";

export const AppBar = ({ userRole, profession }) => {
  const isMobile = useIsMobile();
  const [departmentList, setDepartmentList] = useState([]);
  const { fetchDepartmentList } = useUserList(departmentList);
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElChanged, setAnchorElChanged] = React.useState(null);
  const [anchorElContent, setAnchorElContent] = React.useState(null);
  const open = Boolean(anchorEl);
  const openChanged = Boolean(anchorElChanged);
  const openPages = Boolean(anchorElContent);
  const handleClick = (event) => {
    fetchDepartmentList(setDepartmentList);
    setAnchorEl(event.currentTarget);
  };
  const handleClickContent = (event) => {
    setAnchorElContent(event.currentTarget);
  };
  const handleClickChanged = (event) => {
    fetchDepartmentList(setDepartmentList);
    setAnchorElChanged(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElChanged(null);
    setAnchorElContent(null);
  };
  const handleOption = (departmentId, title) => {
    setAnchorEl(null);
    navigate(`/manager/calendar/${title}/${departmentId}`);
    handleCloseNavMenu();
  };
  const handleChangedOption = (departmentId, title) => {
    setAnchorEl(null);
    navigate(`/manager/requested-changes/${title}/${departmentId}`);
    handleClose();
    handleCloseNavMenu();
  };
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handlePageClick = (selectedPage) => {
    if (selectedPage === "Protocolos") {
      handleCloseNavMenu();
      navigate("/protocol/list");
    } else if (selectedPage === "Videos") {
      handleCloseNavMenu();
      navigate("/videos");
    } else if (selectedPage === "Proc. Institucionales") {
      handleCloseNavMenu();
      navigate("/procedure/list");
    } else if (selectedPage === "Mis Guardias") {
      handleCloseNavMenu();
      navigate("/calendar");
    } else if (selectedPage === "Home") {
      handleCloseNavMenu();
      navigate("/home");
    }
  };
  const handleAdminPageClick = (selectedPage) => {
    if (selectedPage === "Personal") {
      handleCloseNavMenu();
      navigate("/manager/data?table=personal");
    } else if (selectedPage === "Home") {
      handleCloseNavMenu();
      navigate("/home");
    }
  };
  const handlePagesClick = (selectedPage) => {
    if (selectedPage === "Subir Contenido") {
      setAnchorElContent(null);
      handleCloseNavMenu();
      navigate("/protocol/upload");
    } else if (selectedPage === "Protocolos") {
      setAnchorElContent(null);
      handleCloseNavMenu();
      navigate("/manager/data?table=protocol");
    } else if (selectedPage === "Videos") {
      setAnchorElContent(null);
      handleCloseNavMenu();
      navigate("/manager/data?table=video");
    } else if (selectedPage === "Proc. Institucionales") {
      setAnchorElContent(null);
      handleCloseNavMenu();
      navigate("/manager/data?table=procedure");
    }
  };
  // eslint-disable-next-line default-case
  switch (userRole) {
    case "MANAGER":
    case "ADMIN":
    case "SUPERADMIN":
      return (
        <>
          <Header userRole={userRole} />
          <Grid
            container
            sx={{
              ...appBarStyle,
              justifyContent: isMobile ? "space-between" : "center",
              height: isMobile ? "80px" : "60px",
            }}
          >
            {profession !== "ACCOUNTANT" ? (
              <>
                <Grid
                  item
                  display={isMobile ? "flex" : "none"}
                  ml={isMobile ? 2.5 : 0}
                >
                  <Tooltip title="Menu">
                    <IconButton size="large" onClick={handleOpenNavMenu}>
                      <MenuIcon sx={{ color: "#ffffff" }} />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={anchorElNav}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                  >
                    {adminPages.map((pages) => (
                      <MenuItem
                        key={pages}
                        onClick={() => handleAdminPageClick(pages)}
                        sx={{
                          "&:hover": {
                            backgroundColor: "#ebebeb",
                          },
                        }}
                      >
                        <ListItemIcon>
                          {pages === "Personal" ? (
                            <PersonSearch />
                          ) : pages === "Calendario" ? (
                            <TodayIcon />
                          ) : pages === "Cambios" ? (
                            <ChangeCircle />
                          ) : pages === "Home" ? (
                            <Home />
                          ) : null}
                        </ListItemIcon>
                        {pages}
                      </MenuItem>
                    ))}
                    <MenuItem
                      onClick={handleClickContent}
                      sx={{
                        "&:hover": {
                          backgroundColor: "#ebebeb",
                        },
                      }}
                    >
                      <ListItemIcon>
                        <Source />
                      </ListItemIcon>
                      Contenido
                      <ListItemIcon>
                        <ArrowDropDownSharp />
                      </ListItemIcon>
                      <Menu
                        anchorEl={anchorElContent}
                        open={openPages}
                        onClose={handleClose}
                      >
                        {contentPages.map((pages) => (
                          <MenuItem
                            key={pages}
                            onClick={() => {
                              handlePagesClick(pages);
                            }}
                          >
                            <ListItemIcon>
                              {pages === "Subir Contenido" ? (
                                <UploadFile />
                              ) : pages === "Protocolos" ? (
                                <Description />
                              ) : pages === "Videos" ? (
                                <YouTube />
                              ) : pages === "Proc. Institucionales" ? (
                                <Newspaper />
                              ) : null}
                            </ListItemIcon>
                            {pages}
                          </MenuItem>
                        ))}
                      </Menu>
                    </MenuItem>
                    <MenuItem
                      onClick={handleClick}
                      sx={{
                        "&:hover": {
                          backgroundColor: "#ebebeb",
                        },
                      }}
                    >
                      <ListItemIcon>
                        <TodayIcon />
                      </ListItemIcon>
                      Calendario
                      <ListItemIcon>
                        <ArrowDropDownSharp />
                      </ListItemIcon>
                      <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                      >
                        {departmentList.length !== 0 ? (
                          departmentList.map((department) => (
                            <MenuItem
                              key={department.id}
                              onClick={() => {
                                handleOption(department.id, department.title);
                              }}
                            >
                              {department.title === "MANAGER"
                                ? "Administradores"
                                : department.title === "PHARMACY"
                                ? "Farmacia"
                                : department.title === "URGENCY"
                                ? "Guardia"
                                : department.title === "MANAGEMENT"
                                ? "Gerencia"
                                : department.title === "ACCOUNTING"
                                ? "Contaduría"
                                : department.title}
                            </MenuItem>
                          ))
                        ) : (
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="center"
                          >
                            <CircularProgress sx={{ margin: 2 }} />
                          </Grid>
                        )}
                      </Menu>
                    </MenuItem>
                    <MenuItem
                      onClick={handleClickChanged}
                      sx={{
                        "&:hover": {
                          backgroundColor: "#ebebeb",
                        },
                      }}
                    >
                      <ListItemIcon>
                        <CalendarMonth />
                      </ListItemIcon>
                      Cambios
                      <ListItemIcon>
                        <ArrowDropDownSharp />
                      </ListItemIcon>
                      <Menu
                        anchorEl={anchorElChanged}
                        open={openChanged}
                        onClose={handleClose}
                      >
                        {departmentList.length !== 0 ? (
                          departmentList.map((department) => (
                            <MenuItem
                              key={department.id}
                              onClick={() => {
                                handleChangedOption(
                                  department.id,
                                  department.title
                                );
                              }}
                            >
                              {department.title === "MANAGER"
                                ? "Administradores"
                                : department.title === "PHARMACY"
                                ? "Farmacia"
                                : department.title === "URGENCY"
                                ? "Guardia"
                                : department.title === "MANAGEMENT"
                                ? "Gerencia"
                                : department.title === "ACCOUNTING"
                                ? "Contaduría"
                                : department.title}
                            </MenuItem>
                          ))
                        ) : (
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="center"
                          >
                            <CircularProgress sx={{ margin: 2 }} />
                          </Grid>
                        )}
                      </Menu>
                    </MenuItem>
                  </Menu>
                </Grid>
                <Grid item display={isMobile ? "flex" : "none"}>
                  <Button
                    disableRipple
                    sx={{ "&:hover": { backgroundColor: "inherit" } }}
                    onClick={() => {
                      navigate("/home");
                    }}
                  >
                    <Box sx={{ ...AppBarImage({ userRole }) }} />
                  </Button>
                </Grid>
                <Grid
                  item
                  sx={{
                    flexGrow: 1,
                    display: isMobile ? "none" : "flex",
                    justifyContent: "center",
                    ml: "10%",
                  }}
                >
                  {adminPages.map((page) => (
                    <Button
                      key={page}
                      onClick={() => handleAdminPageClick(page)}
                      sx={appBarButtons}
                    >
                      {page}
                    </Button>
                  ))}
                  <Button
                    onClick={(event) => {
                      setAnchorElContent(event.currentTarget);
                    }}
                    sx={appBarButtons}
                    endIcon={<ArrowDropDownSharp sx={{ ml: -0.8, mt: -0.3 }} />}
                  >
                    Contenido
                  </Button>
                  <Menu
                    anchorEl={anchorElContent}
                    open={openPages}
                    onClose={handleClose}
                  >
                    {contentPages.map((pages) => (
                      <MenuItem
                        key={pages}
                        onClick={() => {
                          handlePagesClick(pages);
                        }}
                      >
                        <ListItemIcon>
                          {pages === "Subir Contenido" ? (
                            <UploadFile />
                          ) : pages === "Protocolos" ? (
                            <Description />
                          ) : pages === "Videos" ? (
                            <YouTube />
                          ) : pages === "Proc. Institucionales" ? (
                            <Newspaper />
                          ) : null}
                        </ListItemIcon>
                        {pages}
                      </MenuItem>
                    ))}
                  </Menu>
                  <Button
                    onClick={handleClick}
                    sx={appBarButtons}
                    endIcon={<ArrowDropDownSharp sx={{ ml: -0.8, mt: -0.3 }} />}
                  >
                    Calendario
                  </Button>
                  <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    {departmentList.length !== 0 ? (
                      departmentList.map((department) => (
                        <MenuItem
                          key={department.id}
                          onClick={() => {
                            handleOption(department.id, department.title);
                          }}
                        >
                          <ListItemIcon>
                            <CalendarMonth />
                          </ListItemIcon>
                          {department.title === "MANAGER"
                            ? "Administradores"
                            : department.title === "PHARMACY"
                            ? "Farmacia"
                            : department.title === "URGENCY"
                            ? "Guardia"
                            : department.title === "MANAGEMENT"
                            ? "Gerencia"
                            : department.title === "ACCOUNTING"
                            ? "Contaduría"
                            : department.title}
                        </MenuItem>
                      ))
                    ) : (
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                      >
                        <CircularProgress sx={{ margin: 2 }} />
                      </Grid>
                    )}
                  </Menu>
                  <Button
                    onClick={handleClickChanged}
                    sx={appBarButtons}
                    endIcon={<ArrowDropDownSharp sx={{ ml: -0.8, mt: -0.3 }} />}
                  >
                    Cambios
                  </Button>
                  <Menu
                    anchorEl={anchorElChanged}
                    open={openChanged}
                    onClose={handleClose}
                  >
                    {departmentList.length !== 0 ? (
                      departmentList.map((department) => (
                        <MenuItem
                          key={department.id}
                          onClick={() => {
                            handleChangedOption(
                              department.id,
                              department.title
                            );
                          }}
                        >
                          <ListItemIcon>
                            <CalendarMonth />
                          </ListItemIcon>
                          {department.title === "MANAGER"
                            ? "Administradores"
                            : department.title === "PHARMACY"
                            ? "Farmacia"
                            : department.title === "URGENCY"
                            ? "Guardia"
                            : department.title === "MANAGEMENT"
                            ? "Gerencia"
                            : department.title === "ACCOUNTING"
                            ? "Contaduría"
                            : department.title}
                        </MenuItem>
                      ))
                    ) : (
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                      >
                        <CircularProgress sx={{ margin: 2 }} />
                      </Grid>
                    )}
                  </Menu>
                </Grid>
                <Grid item sx={{ flexGrow: 0, mr: isMobile ? 4.5 : "10%" }}>
                  {Utils()}
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  item
                  sx={{
                    flexGrow: 1,
                    display: isMobile ? "none" : "flex",
                    justifyContent: "center",
                    ml: "15%",
                    alignItems: "center",
                  }}
                >
                  <TableTypographyLight fontSize={"2rem"} color={"white"}>
                    Contaduría
                  </TableTypographyLight>
                  <MonetizationOn sx={{ color: "white", ml: 1 }} />
                </Grid>
                <Grid item sx={{ flexGrow: 0, mr: isMobile ? 4.5 : "10%" }}>
                  {Utils()}
                </Grid>
              </>
            )}
          </Grid>
        </>
      );
    case "USER":
      return (
        <Box>
          <Header userRole={userRole} />
          <Grid
            container
            sx={{
              ...appBarStyle,
              justifyContent: isMobile ? "space-between" : "center",
              height: isMobile ? "80px" : "60px",
            }}
          >
            <Grid
              item
              display={isMobile ? "flex" : "none"}
              ml={isMobile ? 2.5 : 0}
            >
              <Tooltip title="Menu">
                <IconButton onClick={handleOpenNavMenu}>
                  <MenuIcon sx={{ color: "#ffffff" }} />
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorElNav}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
              >
                {userPages.map((pages) => (
                  <MenuItem
                    key={pages}
                    onClick={() => handlePageClick(pages)}
                    sx={{
                      "&:hover": {
                        backgroundColor: "#ebebeb",
                      },
                    }}
                  >
                    <ListItemIcon>
                      {pages === "Protocolos" ? (
                        <Description />
                      ) : pages === "Videos" ? (
                        <YouTube />
                      ) : pages === "Proc. Institucionales" ? (
                        <Newspaper />
                      ) : pages === "Mis Guardias" ? (
                        <TodayIcon />
                      ) : pages === "Home" ? (
                        <Home />
                      ) : null}
                    </ListItemIcon>
                    {pages}
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
            <Grid item display={isMobile ? "flex" : "none"}>
              <Button
                disableRipple
                sx={{ "&:hover": { backgroundColor: "inherit" } }}
                onClick={() => {
                  navigate("/home");
                }}
              >
                <Box sx={{ ...AppBarImage({ userRole }) }} />
              </Button>
            </Grid>
            <Grid
              item
              xs={isMobile ? false : true}
              sx={{
                flexGrow: 1,
                display: isMobile ? "none" : "flex",
                justifyContent: "center",
                ml: "15%",
              }}
            >
              {userPages.map((page) => (
                <Button
                  key={page}
                  onClick={() => handlePageClick(page)}
                  sx={appBarButtons}
                >
                  {page}
                </Button>
              ))}
            </Grid>
            <Grid item sx={{ flexGrow: 0, mr: isMobile ? 4.5 : "10%" }}>
              {Utils()}
            </Grid>
          </Grid>
        </Box>
      );
  }
};

const Utils = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("auth_token");
    setAuthHeader(null);
    window.location.href = "/landing";
  };
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleSettingClick = (selectedSetting) => {
    if (selectedSetting === "Cerrar Sesión") {
      handleCloseUserMenu();
      handleLogout();
    } else if (selectedSetting === "Perfil") {
      handleCloseUserMenu();
      navigate("/profile");
    }
  };
  return (
    <>
      <Tooltip title="Números útiles">
        <IconButton
          onClick={() => {
            navigate("/info");
          }}
          sx={{ mr: 1 }}
        >
          <AddIcCall sx={{ color: "white" }} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Opciones">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar
            sx={{
              backgroundColor: "#ffffff",
              color: "#a19ba8",
              width: 32,
              height: 32,
            }}
          >
            <Person />
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {userSettings.map((setting) => (
          <MenuItem
            key={setting}
            onClick={() => handleSettingClick(setting)}
            sx={{
              "&:hover": {
                backgroundColor: "#ebebeb",
              },
            }}
          >
            <ListItemIcon>
              {setting === "Perfil" ? (
                <AccountCircle />
              ) : setting === "Cerrar Sesión" ? (
                <Logout />
              ) : null}
            </ListItemIcon>
            {setting}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
