import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import vid from "./assets/videos/landing_video.mp4";
import { useIsMobile } from "../../hooks/useIsMobile";
import Login from "./Login";
import logo from "./assets/images/dh-logo.svg";
import Register from "./Register";

const Landing = () => {
  const isMobile = useIsMobile();
  const [showLogin, setShowLogin] = React.useState(true);
  const hiddeLogin = () => {
    setShowLogin(false);
  };
  const unhiddeLogin = () => {
    setShowLogin(true);
  };
  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <video
        autoPlay
        loop
        muted
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          objectFit: "cover",
          zIndex: 1,
        }}
      >
        <source src={vid} type="video/mp4" />
      </video>
      <Box
        sx={{
          zIndex: 2,
          position: "relative",
          backgroundColor: "rgba(0, 0, 0, 0.55)",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginInline={!isMobile ? 5 : 2}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundImage: `url(${logo})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: !isMobile ? "300px" : "100px",
              height: "100px",
              filter: "brightness(0) invert(0.9)",
              mt: 2,
            }}
          />
          <Box
            display={!isMobile ? "flex" : "none"}
            flexDirection={"row"}
            alignItems={"center"}
          >
            <Typography
              color={"white"}
              fontWeight={"300"}
              sx={{
                fontSize: isMobile ? "1rem" : "1.5rem",
              }}
            >
              Tienes cuenta?
            </Typography>
            <Typography
              color={"white"}
              fontWeight={"600"}
              sx={{
                fontSize: isMobile ? "1rem" : "1.4rem",
                ml: 1,
                "&:hover": {
                  cursor: "pointer",
                  color: "#137136",
                },
              }}
              onClick={unhiddeLogin}
            >
              inicia sesión
            </Typography>
            <Typography
              color={"white"}
              fontWeight={"300"}
              sx={{
                fontSize: isMobile ? "1rem" : "1.4rem",
              }}
            >
              , o bien puedes
            </Typography>
            <Typography
              color={"white"}
              fontWeight={"600"}
              sx={{
                fontSize: isMobile ? "1rem" : "1.4rem",
                ml: 1,
                "&:hover": {
                  cursor: "pointer",
                  color: "#137136",
                },
              }}
              onClick={hiddeLogin}
            >
              registrarte.
            </Typography>
          </Box>
          <Box
            display={isMobile ? "flex" : "none"}
            flexDirection={"row"}
            alignItems={"center"}
          >
            <Typography
              color={"white"}
              fontWeight={"600"}
              sx={{
                fontSize: isMobile ? "1rem" : "1.4rem",
                ml: 1,
                "&:hover": {
                  cursor: "pointer",
                  color: "#137136",
                },
              }}
              onClick={unhiddeLogin}
            >
              iniciar sesión
            </Typography>
            <Typography
              color={"white"}
              fontWeight={"600"}
              sx={{
                fontSize: isMobile ? "1rem" : "1.4rem",
                ml: 2,
                "&:hover": {
                  cursor: "pointer",
                  color: "#137136",
                },
              }}
              onClick={hiddeLogin}
            >
              registrarme
            </Typography>
          </Box>
        </Box>
        <Grid
          display={"flex"}
          flexDirection={!isMobile ? "row" : "column"}
          alignItems={"center"}
          justifyContent={"center"}
          mt={!isMobile ? (showLogin ? 25 : 10) : 5}
          mb={!isMobile ? (showLogin ? 40 : 25) : (showLogin ? 20 : 5)}
        >
          <Grid item sm={6} mb={!isMobile ? 0 : 3}>
            <Typography
              color={"white"}
              sx={{
                fontSize: isMobile ? "2rem" : "4rem",
                fontWeight: "300",
              }}
            >
              Excelencia en gestión
            </Typography>
            <Typography
              color={"white"}
              fontWeight={"600"}
              sx={{
                fontSize: isMobile ? "2.5rem" : "4rem",
              }}
            >
              intrahospitalaria
            </Typography>
          </Grid>
          <Grid item sm={6} ml={!isMobile && 5}>
            <Login showLogin={showLogin} />
            <Register showLogin={showLogin} setShowLogin={setShowLogin} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Landing;
