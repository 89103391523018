import React from "react";
import { footerStyle, TableTypographyLight } from "../../Styles";
import { Box, Grid } from "@mui/material";
import { useIsMobile } from "../../hooks/useIsMobile";
import { Header } from "./Header";

const EmptyAppBar = () => {
  const isMobile = useIsMobile();
  return (
    <Box>
      <Header />
      <Grid
        container
        sx={{
          ...footerStyle,
          minHeight: "100px",
          justifyContent: "center",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <TableTypographyLight fontSize={isMobile ? "1.4rem" : "2rem"} color={"#ffffff"}>
          Es bueno verte de nuevo!
        </TableTypographyLight>
      </Grid>
    </Box>
  );
};

export default EmptyAppBar;
