import React from "react";
import { useState } from "react";
import {
  AdminPanelSettings,
  Check,
  Close,
  Delete,
  Edit,
  ExpandMore,
  SupervisedUserCircle,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useUserList } from "../../../../hooks/useUserList";
import {
  accordionStyle,
  TableTypography,
  tooltipAdmin,
  tooltipDelete,
  tooltipEdit,
} from "../../../../Styles";
import { tableStyle } from "../../../../Styles";
import { esESGrid } from "../../../../constants/esESGrid";

const AdminsTable = ({ userRole }) => {
  const [hover, setHover] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [userId, setUserId] = useState("");
  const [toUserDialogOpen, setToUserDialogOpen] = useState(false);
  const {
    fetchAdminsList,
    editUser,
    deleteUser,
    fromAdmin,
    activateUser,
    inactivateUser,
  } = useUserList();
  const handleGetAdmins = () => {
    fetchAdminsList(setAdmins);
  };
  const handleToUserOpen = (id) => {
    setToUserDialogOpen(true);
    setUserId(id);
  };
  const handleToUserClose = () => {
    setToUserDialogOpen(false);
  };
  const adminsList = [
    {
      field: "name",
      headerName: "Nombre",
      flex: 1,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "lastname",
      headerName: "Apellido",
      flex: 1,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1.5,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "dni",
      headerName: "DNI",
      typeof: "number",
      flex: 1,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "specialtie",
      headerName: "Especialidad",
      flex: 1,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "medicalRegistration",
      headerName: "Matrícula",
      typeof: "number",
      flex: 1,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actions",
      headerName: "Acciones",
      flex: 2,
      editable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Box>
          <Tooltip
            title={
              params.row.status === "ACTIVE"
                ? "Dar de baja usuario"
                : "Activar usuario"
            }
            onClick={() => {
              if (params.row.status === "ACTIVE") {
                inactivateUser(handleGetAdmins, params.id);
              } else if (params.row.status === "INACTIVE") {
                activateUser(handleGetAdmins, params.id);
              }
            }}
          >
            <IconButton
              size="small"
              sx={{
                backgroundColor:
                  params.row.status === "ACTIVE"
                    ? "green"
                    : params.row.status === "INACTIVE"
                    ? "#f75757"
                    : "",
                "&:hover": {
                  backgroundColor:
                    hover && params.row.status === "ACTIVE"
                      ? "#cd5d55"
                      : hover && params.row.status === "INACTIVE"
                      ? "green"
                      : "",
                },
                mr: 1,
              }}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              {hover && params.row.status === "ACTIVE" ? (
                <Close />
              ) : hover && params.row.status === "INACTIVE" ? (
                <Check />
              ) : params.row.status === "INACTIVE" ? (
                <Close />
              ) : (
                <Check />
              )}
            </IconButton>
          </Tooltip>
          {userRole === "SUPERADMIN" && (
            <Tooltip
              title={`Quitar permisos de ADMIN a ${params.row.name} ${params.row.lastname}`}
              onClick={() => handleToUserOpen(params.id)}
            >
              <IconButton size="small" sx={tooltipAdmin}>
                <SupervisedUserCircle />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Editar usuario" onClick={() => editUser(params.id)}>
            <IconButton size="small" sx={tooltipEdit}>
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Enviar a la papelera"
            onClick={() => deleteUser(params.id)}
          >
            <IconButton size="small" sx={tooltipDelete}>
              <Delete />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
  return (
    <Grid item xs={12} mt={5}>
      <Accordion
        onChange={(event, isExpanded) => {
          if (isExpanded) {
            handleGetAdmins();
          }
        }}
        sx={accordionStyle}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <TableTypography fontSize={"1.2rem"}>
            <AdminPanelSettings sx={{ mr: 1, color: "#137136" }} />
            Administradores
          </TableTypography>
        </AccordionSummary>
        <AccordionDetails>
          {admins.length === 0 ? (
            <Typography sx={{ color: "#959595", fontSize: 15 }}>
              Sin administradores registrados aún
            </Typography>
          ) : (
            <Box>
              <DataGrid
                sx={{ ...tableStyle }}
                rows={admins ? admins : []}
                columns={[...adminsList]}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterValues: [""],
                    },
                  },
                }}
                disableDensitySelector
                disableRowSelectionOnClick
                disableColumnMenu
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                pageSizeOptions={[5, 10, 20, 30]}
                localeText={esESGrid}
              />
              <Dialog
                open={toUserDialogOpen}
                onClose={handleToUserClose}
                style={{ textAlign: "center" }}
              >
                <DialogTitle>Atención!</DialogTitle>
                <DialogContent>
                  Seguro/a desea QUITAR permisos de ADMIN al usuario
                  seleccionado?
                </DialogContent>
                <DialogContentText>
                  -Se otorgaran permisos de MANAGER.
                </DialogContentText>
                <DialogActions style={{ justifyContent: "center" }}>
                  <Button onClick={handleToUserClose} color="primary">
                    Cancelar
                  </Button>
                  <Button
                    onClick={async () => {
                      handleToUserClose();
                      await fromAdmin(userId, handleGetAdmins);
                    }}
                    color="error"
                  >
                    Quitar permisos
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default AdminsTable;
