import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Box,
  ListSubheader,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useUserList } from "../../../../hooks/useUserList";
import { useAddEvents } from "../../../../hooks/useAddEvents";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Circle, EditCalendar } from "@mui/icons-material";
import { ColorOptions } from "./../../../../constants/Options";
import { TableTypography } from "../../../../Styles";

const initialEvent = {
  startDate: undefined,
  endDate: undefined,
  userId: "",
  color: "",
  eventStatus: "CREATED",
};

const EventAddDialog = ({ open, handleClose, currentDates, updateKey }) => {
  const { id } = useParams();
  const [userList, setUserList] = useState([]);
  const { fetchUserList } = useUserList();
  const { event, handleChange, handleAddEvent } = useAddEvents(initialEvent);
  const shouldDisableEndDate = (day) => {
    return event.startDate && day.isBefore(event.startDate, "day");
  };
  const handleOpen = () => {
    fetchUserList(id, setUserList);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <TableTypography sx={{ mb: 1 }}>
          <EditCalendar sx={{ mr: 1 }} />
          Agendar
        </TableTypography>
      </DialogTitle>
      <DialogContent
        sx={{
          alignContent: "center",
          alignItems: "center",
          maxWidth: 400,
        }}
      >
        <FormControl sx={{ mb: 1, mt: 1 }} id="event-add" fullWidth>
          <InputLabel>Médico</InputLabel>
          <Select
            label="Médico"
            id="id"
            name="id"
            value={event.userId || ""}
            onChange={(e) => handleChange("id", e)}
            onOpen={handleOpen}
          >
            {userList.length !== 0 ? (
              userList.map((user) => (
                <MenuItem
                  key={user.id}
                  value={user.id}
                >{`${user.name} ${user.lastname}`}</MenuItem>
              ))
            ) : (
              <Grid container alignItems="center" justifyContent="center">
                <CircularProgress item sx={{ margin: 2 }} />
              </Grid>
            )}
          </Select>
          <FormControl sx={{ mt: 1 }}>
            <InputLabel>Turno</InputLabel>
            <Select
              label="Turno"
              value={event.color || initialEvent.color}
              onChange={(e) => {
                handleChange("color", e);
              }}
              renderValue={(selectedValue) => {
                const selectedItem = ColorOptions.find(
                  (item) => item.value === selectedValue
                );
                return (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Circle
                      sx={{
                        color: selectedItem?.value,
                        mr: 2,
                      }}
                    />
                    {selectedItem?.name}
                  </Box>
                );
              }}
            >
              {ColorOptions.map((color, index) => (
                <MenuItem
                  key={color.value}
                  value={color.value}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <ListSubheader
                    sx={{
                      borderRadius: 15,
                    }}
                  >
                    {color.category}
                  </ListSubheader>
                  <Box
                    style={{
                      borderRadius: 15,
                    }}
                  >
                    <Circle sx={{ color: color.value, mr: 2 }} />
                    {color.name}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Divider sx={{ mt: 2, mb: 1 }} />
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            sx={{
              mb: 1,
              width: "100%",
            }}
            label="Inicia"
            format="DD/MM/YYYY HH:mm"
            ampm={false}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: null,
              seconds: null,
            }}
            onChange={(e) => handleChange("start", e)}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            sx={{
              mb: 1,
              width: "100%",
            }}
            label="Finaliza"
            format="DD/MM/YYYY HH:mm"
            ampm={false}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: null,
              seconds: null,
            }}
            onChange={(e) => handleChange("end", e)}
            shouldDisableDate={shouldDisableEndDate}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error">
          cerrar
        </Button>
        <Button
          onClick={() => {
            handleAddEvent(currentDates, updateKey, id);
            handleClose();
          }}
        >
          Agendar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EventAddDialog;
