import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  ThemeProvider,
} from "@mui/material";
import EventEditDialog from "./EvenEditDialog";
import dayjs from "dayjs";
import { useAddEvents } from "../../../../hooks/useAddEvents";
import {
  customTheme,
  TableTypography,
  TableTypographyLight,
} from "../../../../Styles";
import { useUserList } from "../../../../hooks/useUserList";
import { Info } from "@mui/icons-material";
import { useIsMobile } from "../../../../hooks/useIsMobile";

function myDate(date) {
  const myDate = new Date(date);
  const day = myDate.getDate();
  const month = myDate.getMonth() + 1;
  const year = myDate.getFullYear();
  const hours = myDate.getHours();
  const minutes = myDate.getMinutes();

  const formattedDay = day < 10 ? `0${day}` : `${day}`;
  const formattedMonth = month < 10 ? `0${month}` : `${month}`;
  const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

  const formattedDate = `${formattedDay}/${formattedMonth}/${year} ${formattedHours}:${formattedMinutes}`;
  return formattedDate;
}

const EventInfoDialog = ({
  open,
  handleClose,
  selectedEvent,
  currentDates,
  updateKey,
  departmentId,
}) => {
  const isMobile = useIsMobile();
  const [userList, setUserList] = useState([]);
  const { fetchUserList } = useUserList();
  const initialEvent = {
    startDate: selectedEvent ? dayjs(selectedEvent.startStr) : undefined,
    endDate: selectedEvent ? dayjs(selectedEvent.endStr) : undefined,
    userId: selectedEvent ? selectedEvent.extendedProps.userId : undefined,
    color: selectedEvent ? selectedEvent.backgroundColor : undefined,
    id: selectedEvent ? selectedEvent.id : undefined,
    eventStatus: selectedEvent
      ? selectedEvent.extendedProps.eventStatus
      : undefined,
  };
  const { handleDeleteEvent } = useAddEvents();
  const [editOpen, setEditOpen] = useState(false);
  const handleEditOpen = () => {
    fetchUserList(departmentId, setUserList);
    setEditOpen(true);
  };
  const [confirmOpen, setConfirmOpen] = useState(false);
  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };
  const handleConfirmOpen = () => {
    setConfirmOpen(true);
  };

  return (
    <ThemeProvider theme={customTheme}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <TableTypography>
            <Info sx={{ mr: 1 }} />
            Información
          </TableTypography>
        </DialogTitle>
        <DialogContent
          sx={{
            minWidth: isMobile ? "100%" : 400,
          }}
        >
          {selectedEvent ? (
            <>
              <TableTypographyLight fontSize={"1.4rem"} color={"#137136"}>
                {selectedEvent.title}
              </TableTypographyLight>
              {selectedEvent.extendedProps.customProperty && (
                <TableTypographyLight>
                  Estado: {selectedEvent.extendedProps.customProperty}
                </TableTypographyLight>
              )}
              <Divider sx={{ mt: 1, mb: 1 }} />
              <TableTypographyLight>
                Desde: {myDate(selectedEvent.startStr)} hs
              </TableTypographyLight>
              <TableTypographyLight>
                Hasta: {myDate(selectedEvent.endStr)} hs
              </TableTypographyLight>
            </>
          ) : (
            <CircularProgress color="success" />
          )}
        </DialogContent>
        <Box
          sx={{
            textAlign: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <DialogActions>
            <Button onClick={handleClose} color="error">
              cerrar
            </Button>
            <Button color="error" onClick={handleConfirmOpen}>
              Borrar
            </Button>
            <Button onClick={handleEditOpen}>Editar</Button>
          </DialogActions>
        </Box>
      </Dialog>
      <EventEditDialog
        open={editOpen}
        handleClose={handleClose}
        setEditOpen={setEditOpen}
        initialEvent={initialEvent}
        currentDates={currentDates}
        updateKey={updateKey}
        departmentId={departmentId}
        userList={userList}
      />
      <Dialog open={confirmOpen} onClose={handleConfirmClose}>
        <DialogTitle>¡Atención!</DialogTitle>
        <DialogContent sx={{ minWidth: 350, minHeight: 120, margin: 1 }}>
          <DialogContentText>
            Esta acción no puede deshacerse!
          </DialogContentText>
          <DialogContentText>
            Desea borrar el evento seleccionado?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="error">
            cerrar
          </Button>
          <Button
            sx={{ color: "#fb82a0" }}
            onClick={() => {
              handleDeleteEvent(
                selectedEvent.id,
                currentDates,
                departmentId,
                updateKey
              );
              handleConfirmClose();
              handleClose();
            }}
          >
            Borrar
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default EventInfoDialog;
