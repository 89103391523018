import axios from "./axios_instance.js";
import { getAuthToken } from "./axios_helper.js";
import { getBackendUrl } from "../../config.js";

const baseURL = getBackendUrl();
const EVENTVALUE_API_BASE_URL = baseURL + "/event-value";
const token = getAuthToken();
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

class EventValueService {
  createEventValue(form) {
    return axios.post(EVENTVALUE_API_BASE_URL + "/create", form, config);
  }

  getLastValue() {
    return axios.get(EVENTVALUE_API_BASE_URL + "/last", config);
  }
}

const instance = new EventValueService();
export default instance;
