import { useEffect, useState } from "react";
import ProtocolService from "../services/axios/ProtocolService.js";
import dayjs from "dayjs";
import { useSnackBarContext } from "../context/SnackBarContext.js";

export const useProtocolList = () => {
  // LOGIC FOR PROTOCOL RENDERING ////////////////////////////////////////////////
  const { triggerSnackbar } = useSnackBarContext();
  const [protocolList, setProtocolList] = useState();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedProtocolId, setSelectedProtocolId] = useState(null);

  useEffect(() => {
    ProtocolService.protocolList()
      .then((r) => {
        // Cambio la fecha a formato de dayjs
        r.data.forEach((element) => {
          element.publicationDate = dayjs(element.publicationDate).format(
            "DD/MM/YYYY"
          );
        });
        setProtocolList(r.data);
      })
      .catch((err) => {
        triggerSnackbar(err?.response?.data?.message, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  ///////////////////////////////////////////////////////////////////////////////

  const fetchProtocolList = async () => {
    try {
      const response = await ProtocolService.protocolList();
      const updatedProtocolList = response.data;
      setProtocolList(updatedProtocolList);
    } catch (error) {
      triggerSnackbar(error?.response?.data?.message, "error");
    }
  };

  const deleteProtocol = (id) => {
    setSelectedProtocolId(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirmed = async () => {
    setDeleteDialogOpen(false);
    await ProtocolService.deleteProtocol(selectedProtocolId)
      .then(async () => {
        triggerSnackbar("Documento eliminado correctamente", "success");
        await fetchProtocolList();
      })
      .catch((err) => {
        triggerSnackbar(err?.response?.data?.message, "error");
      });
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };
  return {
    protocolList,
    deleteDialogOpen,
    deleteProtocol,
    handleDeleteConfirmed,
    handleCloseDeleteDialog,
  };
};
