import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, DialogContent } from "@mui/material";
import { useAddEvents } from "../../../../hooks/useAddEvents";
import { TableTypography, TableTypographyLight } from "../../../../Styles";
import { Label } from "@mui/icons-material";

function myDate(date) {
  const myDate = new Date(date);
  const day = myDate.getDate();
  const month = myDate.getMonth() + 1;
  const hours = myDate.getHours();
  const minutes = myDate.getMinutes();

  const formattedDay = day < 10 ? `0${day}` : `${day}`;
  const formattedMonth = month < 10 ? `0${month}` : `${month}`;
  const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

  const formattedDate = `${formattedDay}/${formattedMonth} - ${formattedHours}:${formattedMinutes} hs`;
  return formattedDate;
}
const EventPublishDialogList = ({
  open,
  handleClose,
  unpublishedEvents,
  currentDates,
  updateKey,
  departmentId,
}) => {
  const { handlePublishEvents } = useAddEvents();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <TableTypography>Publicar guardias</TableTypography>
      </DialogTitle>
      {unpublishedEvents.length !== 0 ? (
        unpublishedEvents.map((event) => (
          <DialogContent>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Label sx={{ color: "green", mr: 1 }} />
              <TableTypographyLight fontSize={"1rem"} color={"black"}>
                {`${event.title} `}&nbsp;
              </TableTypographyLight>
            </Box>
            <TableTypographyLight>{`Desde: ${myDate(
              event.startDate
            )}`}</TableTypographyLight>
            <TableTypographyLight>{`Hasta: ${myDate(
              event.endDate
            )}`}</TableTypographyLight>
          </DialogContent>
        ))
      ) : (
        <DialogContent sx={{ textAlign: "center" }}>
          <TableTypographyLight>
            No hay eventos para publicar
          </TableTypographyLight>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
          }}
        >
          Cerrar
        </Button>
        <Button
          onClick={() => {
            handlePublishEvents(
              unpublishedEvents,
              currentDates,
              departmentId,
              updateKey
            );
            handleClose();
          }}
          disabled={unpublishedEvents.length === 0}
        >
          Publicar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EventPublishDialogList;
