import React, { useState } from "react";
// eslint-disable-next-line no-unused-vars
import Chart from "chart.js/auto";
import { Container,  Grid, Paper } from "@mui/material";
import { TableTypography, paperStyle } from "../../../../Styles";
import { Bar, Doughnut } from "react-chartjs-2";
import { Date } from "core-js";
import { useEffect } from "react";
import WhoIsNow from "./WhoIsNow";
import { useHome } from "../../../../hooks/useHome";
import { monthLabels } from "../../../../constants/Options";

export const UserHome = () => {
  const [user, setUser] = useState({});
  const [doughnut, setDoughnut] = useState({});
  const [bar, setBar] = useState({});
  const { fetchProfile, fetchUserBar, fetchDoughnut } = useHome();
  const monthName = new Intl.DateTimeFormat("es-ES", { month: "long" }).format(
    new Date()
  );
  useEffect(() => {
    fetchProfile(setUser);
    fetchDoughnut(setDoughnut);
    fetchUserBar(setBar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const doughnutConfig = {
    type: "doughnut",
    data: {
      labels: ["Terminadas", "Faltantes"],
      datasets: [
        {
          label: ["Horas"],
          data: [doughnut.hsDone, doughnut.hsLeft],
          borderRadius: 10,
          backgroundColor: ["#0d604c", "#747474"],
          hoverOffset: 5,
        },
      ],
    },
  };
  const barConfig = {
    type: "bar",
    data: {
      labels: monthLabels,
      datasets: [
        {
          label: "total de hs (guardias terminadas)",
          data: [
            bar[1],
            bar[2],
            bar[3],
            bar[4],
            bar[5],
            bar[6],
            bar[7],
            bar[8],
            bar[9],
            bar[10],
            bar[11],
            bar[12],
          ],
          fill: false,
          backgroundColor: ["#0d604c", "#747474"],
          borderRadius: 10,
        },
      ],
    },
  };

  return (
    <Container>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <Paper sx={paperStyle}>
            <TableTypography>
              {user.name
                ? user.gender === "MALE"
                  ? `Bienvenido ${user.name} ${user.lastname}`
                  : `Bienvenida ${user.name} ${user.lastname}`
                : "..."}
            </TableTypography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12}>
          <WhoIsNow />
        </Grid>
        <Grid item xs={12} md={4.15}>
          <Paper sx={{ ...paperStyle, padding: 2 }}>
            <TableTypography fontSize={20}>
              {`Horas ${
                monthName.charAt(0).toUpperCase() + monthName.slice(1)
              }`}
            </TableTypography>
            <Doughnut config={doughnutConfig} data={doughnutConfig.data} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={7.85}>
          <Paper sx={{ ...paperStyle, padding: 2 }}>
            <TableTypography fontSize={20}>
              Guardias {new Date().getFullYear()}
            </TableTypography>
            <Bar config={barConfig} data={barConfig.data} />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
