import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";
import { useForm } from "../../hooks/useForm";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import useResetPassword from "../../hooks/useResetPassword";
import { useNavigate, useParams } from "react-router-dom";
import { useIsMobile } from "../../hooks/useIsMobile";
import { TableTypography } from "../../Styles";
import EmptyFooter from "./EmptyFooter";
import EmptyAppBar from "./EmptyAppBar";

const ResetPassword = () => {
  const { token } = useParams();
  const isMobile = useIsMobile();
  const initialForm = { password: "" };
  const { form, handleChange } = useForm(initialForm);
  const {
    onResetPassword,
    areEquals,
    handlePasswordConfirmation,
    showPassword,
    handleClickShowPassword,
    handleMouseDownPassword,
    showPassword2,
    handleClickShowPassword2,
    handleMouseDownPassword2,
  } = useResetPassword(form, token);
  const navigate = useNavigate();

  // Estado para rastrear si los campos de contraseña han sido modificados.
  const [passwordTouched, setPasswordTouched] = useState(false);

  // Función para manejar los cambios y marcar como 'tocado'
  const handlePasswordChange = (e) => {
    handleChange(e);
    if (!passwordTouched) setPasswordTouched(true);
  };

  return (
    <Box>
      <EmptyAppBar />
      <Container>
        <Grid container justifyContent={"center"}>
          <Box maxWidth={isMobile ? "95%" : "sm"}>
            <Paper
              sx={{
                backgroundColor: "#dddddd",
                boxShadow: 0,
                padding: 3,
                borderRadius: 5,
                textAlign: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                mt: isMobile ? 3 : 12,
                mb: isMobile ? 1 : 8,
              }}
            >
              <TableTypography>Restablece tu contraseña</TableTypography>
              <Grid container justifyContent={"center"} mt={4}>
                <FormControl fullWidth>
                  <InputLabel>Nueva contraseña</InputLabel>
                  <OutlinedInput
                    sx={{ mb: 1 }}
                    label="nueva contraseña"
                    id="password"
                    name="password"
                    onChange={handlePasswordChange}
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Repite tu contraseña</InputLabel>
                  <OutlinedInput
                    label="repite tu contraseña"
                    name="password2"
                    id="password2"
                    onChange={handlePasswordConfirmation}
                    type={showPassword2 ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword2}
                          onMouseDown={handleMouseDownPassword2}
                          edge="end"
                        >
                          {showPassword2 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                {!areEquals && passwordTouched && (
                  <Typography
                    align="center"
                    fontSize={"small"}
                    color={"red"}
                    marginTop={0.1}
                  >
                    Las contraseñas no coinciden!
                  </Typography>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disableElevation
                  onClick={onResetPassword}
                  disabled={!areEquals}
                  sx={{
                    mt: 3,
                    width: "100%",
                  }}
                >
                  RESTABLECER
                </Button>
                <Button
                  type="submit"
                  fullWidth
                  variant="outlined"
                  disableElevation
                  onClick={() => navigate("/landing")}
                  disabled={!areEquals}
                  sx={{
                    mt: 5,
                    width: "100%",
                  }}
                >
                  inicio de sesión
                </Button>
              </Grid>
            </Paper>
          </Box>
        </Grid>
      </Container>
      <EmptyFooter />
    </Box>
  );
};

export default ResetPassword;
