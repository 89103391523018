import axios from "./axios_instance";
import { getAuthToken } from "./axios_helper";
import { getBackendUrl } from "../../config";

const mailURL = getBackendUrl() + "/mail";
const token = getAuthToken();
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

class MailService {
  ////////////////////// Mail API //////////////////////
  sendMail(id, mail) {
    return axios.post(mailURL + "/send/" + id, mail, config);
  }

  sendMailToAll(mail) {
    return axios.post(mailURL + "/send-mail-to-all", mail, config);
  }

  sendMailToUser(mail, id) {
    return axios.post(mailURL + "/send-mail-to-user/" + id, mail, config);
  }

  sendMailToDepartment(mail, department) {
    return axios.post(
      mailURL + "/send-mail-to-department/" + department,
      mail,
      config
    );
  }

  sendMailToRole(mail, role) {
    return axios.post(mailURL + "/send-mail-to-role/" + role, mail, config);
  }
}
const instance = new MailService();
export default instance;
