import axios from "axios";
import { getBackendUrl } from "../../config";

const baseURL = getBackendUrl();
axios.defaults.baseURL = baseURL;

let isAlreadyRedirecting = false;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401: // No autorizado
          if (
            !isAlreadyRedirecting &&
            !window.location.href.includes("/landing")
          ) {
            isAlreadyRedirecting = true; // Evitar múltiples redirecciones
            localStorage.removeItem("auth_token");
            window.location.href = "/landing";
          }
          break;
        case 500: // Error interno del servidor
          console.log(error);
          break;
        default:
          console.log("Error no manejado: ", error.response.status);
      }
    } else {
      console.log("Error sin respuesta: ", error.message);
    }
    return Promise.reject(error);
  }
);

export default axios;
