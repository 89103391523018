import React from "react";
import { useState } from "react";
import {
  AdminPanelSettings,
  Check,
  Close,
  Delete,
  Edit,
  Email,
  ExpandMore,
  SupervisedUserCircle,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useUserList } from "../../../../hooks/useUserList";
import {
  accordionStyle,
  TableTypography,
  TableTypographyLight,
  tooltipAdmin,
  tooltipDelete,
  tooltipEdit,
} from "../../../../Styles";
import { tableStyle } from "../../../../Styles";
import { esESGrid } from "../../../../constants/esESGrid";
import MailDialog from "../Mail/MailDialog";

const ManagersTable = ({ userRole }) => {
  const [hover, setHover] = useState(false);
  const [managers, setManagers] = useState([]);
  const [userId, setUserId] = useState("");
  const [toUserDialogOpen, setToUserDialogOpen] = useState(false);
  const [toAdminDialogOpen, setToAdminDialogOpen] = useState(false);
  const {
    fetchManagersList,
    editUser,
    deleteUser,
    toUser,
    toAdmin,
    activateUser,
    inactivateUser,
  } = useUserList();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [userName, setUserName] = useState("");
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  const handleGetManagers = () => {
    fetchManagersList(setManagers);
  };
  const handleToUserOpen = (id) => {
    setToUserDialogOpen(true);
    setUserId(id);
  };
  const handleToUserClose = () => {
    setToUserDialogOpen(false);
  };
  const handleToAdminOpen = (id) => {
    setToAdminDialogOpen(true);
    setUserId(id);
  };
  const handleToAdminClose = () => {
    setToAdminDialogOpen(false);
  };
  const managersList = [
    {
      field: "name",
      headerName: "Nombre",
      flex: 1,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "lastname",
      headerName: "Apellido",
      flex: 1,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1.5,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "dni",
      headerName: "DNI",
      typeof: "number",
      flex: 1,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "specialtie",
      headerName: "Especialidad",
      flex: 1,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "medicalRegistration",
      headerName: "Matrícula",
      typeof: "number",
      flex: 1,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actions",
      headerName: "Acciones",
      flex: 2,
      editable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Box>
          <Tooltip
            title={
              params.row.status === "ACTIVE"
                ? "Dar de baja usuario"
                : "Activar usuario"
            }
            onClick={() => {
              if (params.row.status === "ACTIVE") {
                inactivateUser(handleGetManagers, params.id);
              } else if (params.row.status === "INACTIVE") {
                activateUser(handleGetManagers, params.id);
              }
            }}
          >
            <IconButton
              size="small"
              sx={{
                backgroundColor:
                  params.row.status === "ACTIVE"
                    ? "green"
                    : params.row.status === "INACTIVE"
                    ? "#f75757"
                    : "",
                "&:hover": {
                  backgroundColor:
                    hover && params.row.status === "ACTIVE"
                      ? "#cd5d55"
                      : hover && params.row.status === "INACTIVE"
                      ? "green"
                      : "",
                },
                mr: 1,
              }}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              {hover && params.row.status === "ACTIVE" ? (
                <Close />
              ) : hover && params.row.status === "INACTIVE" ? (
                <Check />
              ) : params.row.status === "INACTIVE" ? (
                <Close />
              ) : (
                <Check />
              )}
            </IconButton>
          </Tooltip>
          {(userRole === "ADMIN" || userRole === "SUPERADMIN") && (
            <Tooltip
              title={`Quitar permisos de MANAGER a ${params.row.name} ${params.row.lastname}`}
              onClick={() => handleToUserOpen(params.id)}
            >
              <IconButton size="small" sx={tooltipAdmin}>
                <SupervisedUserCircle />
              </IconButton>
            </Tooltip>
          )}
          {userRole === "SUPERADMIN" && (
            <Tooltip
              title={`Otorgar permisos de ADMIN a ${params.row.name} ${params.row.lastname}`}
              onClick={() => handleToAdminOpen(params.id)}
            >
              <IconButton size="small" sx={tooltipAdmin}>
                <AdminPanelSettings />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip
            size="small"
            title={`Enviar email a ${params.row.name} ${params.row.lastname}`}
            onClick={() => {
              setIsDialogOpen(true);
              setSelectedUserId(params.id);
              setUserName(
                `${params.row.name} ${params.row.lastname} <${params.row.email}>`
              );
            }}
          >
            <IconButton sx={tooltipAdmin}>
              <Email />
            </IconButton>
          </Tooltip>
          <Tooltip title="Editar usuario" onClick={() => editUser(params.id)}>
            <IconButton size="small" sx={tooltipEdit}>
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Enviar a la papelera"
            onClick={() => deleteUser(params.id)}
          >
            <IconButton size="small" sx={tooltipDelete}>
              <Delete />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
  return (
    <Grid item xs={12} mt={5}>
      <Accordion
        onChange={(event, isExpanded) => {
          if (isExpanded) {
            handleGetManagers();
          }
        }}
        sx={accordionStyle}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <TableTypography fontSize={"1.2rem"}>
            <AdminPanelSettings sx={{ mr: 1, color: "#137136" }} />
            Managers
          </TableTypography>
        </AccordionSummary>
        <AccordionDetails>
          {managers.length === 0 ? (
            <TableTypographyLight>
              Sin administradores registrados aún
            </TableTypographyLight>
          ) : (
            <Box>
              <DataGrid
                sx={{ ...tableStyle }}
                rows={managers ? managers : []}
                columns={[...managersList]}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterValues: [""],
                    },
                  },
                }}
                disableDensitySelector
                disableRowSelectionOnClick
                disableColumnMenu
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                pageSizeOptions={[5, 10, 20, 30]}
                localeText={esESGrid}
              />
              <Dialog
                open={toUserDialogOpen}
                onClose={handleToUserClose}
                style={{ textAlign: "center" }}
              >
                <DialogTitle>Atención!</DialogTitle>
                <DialogContent>
                  Seguro/a desea QUITAR permisos de MANAGER al usuario
                  seleccionado?
                </DialogContent>
                <DialogActions style={{ justifyContent: "center" }}>
                  <Button onClick={handleToUserClose} color="primary">
                    Cancelar
                  </Button>
                  <Button
                    onClick={async () => {
                      handleToUserClose();
                      await toUser(userId, handleGetManagers);
                    }}
                    color="error"
                  >
                    Quitar permisos
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={toAdminDialogOpen}
                onClose={handleToAdminClose}
                style={{ textAlign: "center" }}
              >
                <DialogTitle>Atención!</DialogTitle>
                <DialogContent>
                  Seguro/a desea otorgar permisos de ADMIN al usuario
                  seleccionado?
                </DialogContent>
                <DialogActions style={{ justifyContent: "center" }}>
                  <Button onClick={handleToAdminClose} color="primary">
                    Cancelar
                  </Button>
                  <Button
                    onClick={async () => {
                      handleToAdminClose();
                      await toAdmin(userId, handleGetManagers);
                    }}
                    color="error"
                  >
                    Otrogar permisos
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
      {isDialogOpen && (
        <MailDialog
          userId={selectedUserId}
          userName={userName}
          open={isDialogOpen}
          onClose={handleCloseDialog}
        />
      )}
    </Grid>
  );
};
export default ManagersTable;
