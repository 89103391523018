import { createTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import userslogo from "./components/public/assets/images/dh-logo.svg";
import managersLogo from "./components/public/assets/images/dh-logo-header-managers.svg";
import adminsLogo from "./components/public/assets/images/dh-logo-header-admins.svg";
import superadminsLogo from "./components/public/assets/images/dh-logo-header-superadmin.svg";
import { useIsMobile } from "./hooks/useIsMobile";

/* 
Color Pallete:
Primary: #083a2e
Primary lighter: #0d604c
Secondary: #137136
Terciary: #
Gray primary: #747474
Gray secondary: #dddddd

Logo Font: Bauhaus 93
Subtitle Logo Font: Gill Sans Ultra Bold
*/

export const customTheme = createTheme({
  typography: {
    fontFamily: "Figtree",
    fontWeight: 400,
  },
  palette: {
    primary: {
      main: "#083a2e",
    },
    background: {
      default: "#ffffff",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      html, body, #root {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
      }
        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus, 
        input:-webkit-autofill:active  {
            -webkit-box-shadow: 0 0 0 30px #dddddd inset !important;
        }
      `,
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "calc(100vh - 250px)",
          marginTop: "10px",
          marginBottom: "10px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Figtree",
          fontWeight: 400,
          borderRadius: 15,
          transition: "background-color 150ms linear, color 150ms ease-in-out", // Transición para color y fondo
          "&:hover": {
            backgroundColor: "#137136", // Color en hover
            color: "#ffffff", // Color del texto en hover
          },
        },
        containedError: {
          "&:hover": {
            backgroundColor: "red", // Color en hover para el color "error"
          },
        },
        outlinedError: {
          "&:hover": {
            backgroundColor: "red", // Color en hover para el color "error"
          },
        },
        textError: {
          "&:hover": {
            backgroundColor: "red", // Color en hover para el color "error"
          },
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        inputProps: {
          maxLength: 50,
        },
      },
      styleOverrides: {
        root: {
          borderRadius: 15,
          textAlign: "left",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: 15,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 15,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "15px !important",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: "15px !important",
          "&.Mui-expanded": {
            borderRadius: "15px !important",
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          borderRadius: "15px !important",
          mb: "1 !important",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Figtree",
          fontWeight: 400,
          fontSize: "1.15rem",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        inputProps: {
          maxLength: 70,
        },
      },
      styleOverrides: {
        root: {
          "& label": {
            color: "#083a2e", // Color del label
          },
          "& label.Mui-focused": {
            color: "#083a2e", // Color del label cuando el TextField está enfocado
          },
        },
      },
    },
  },
});

export const headerStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  height: "80px",
  width: "100%",
};

export const appBarStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#083a2e",
};

export const AppBarImage = ({ userRole }) => {
  const isMobile = useIsMobile();
  return {
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${
      !isMobile
        ? userRole === "MANAGER"
          ? managersLogo
          : userRole === "ADMIN"
          ? adminsLogo
          : userRole === "SUPERADMIN"
          ? superadminsLogo
          : userslogo
        : userslogo
    })`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "200px",
    height: isMobile ? "50px" : "70px",
    filter: "brightness(0) invert(1)",
  };
};

export const footerStyle = {
  backgroundColor: "#083a2e",
  minHeight: "200px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingX: 3,
  textAlign: "center",
};

export const accordionStyle = {
  boxShadow: 0,
  marginBottom: 0.5,
  backgroundColor: "#dddddd",
  "&:before": { display: "none" },
};

export const footerTextStyle = {
  color: "#ffffff",
};

export const footerTextSecStyle = {
  color: "#c5c5c5",
  fontSize: "small",
};

export const appBarButtons = {
  color: "white",
  mx: 0.5,
  letterSpacing: 0.5,
  "&:hover": {
    backgroundColor: "#ffffff",
    color: "#083a2e",
    fontWeight: "600",
  },
};

////////////////////////  Protocol Form //////////////////////////

export const protocolFormTitleBox = {
  backgroundColor: "#083a2e",
  maxWidth: "100%",
  color: "#dddddd",
  borderTopLeftRadius: 15,
  borderTopRightRadius: 15,
  marginBottom: -0.1,
};

export const protocolFormTextField = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderWidth: 1,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  },
};

export const textButton = {
  textTransform: "none",
  "&:hover": {
    fontWeight: "600",
    backgroundColor: "#dddddd",
    color: "#137136",
  },
};

////////////////////////  Admin Tables Styles //////////////////////////

export const tableStyle = {
  border: 0,
  // borderRadius: 5,
  borderColor: "#747474",
  ".MuiDataGrid-columnHeaderTitle": {
    color: "#0d604c",
    fontFamily: "Figtree",
    fontWeight: "600",
    fontSize: "1rem",
  },
  "& .MuiDataGrid-columnHeader": {
    background: "#dddddd",
    color: "#dddddd",
  },
  "& .MuiDataGrid-cell:hover": {
    color: "#083a2e",
  },
  "& .MuiButton-root": {
    padding: 1,
    backgroundColor: "#fffff",
    color: "#747474",
    "&:hover": {
      color: "#fffff",
    },
  },
};

export const tooltipAdmin = {
  mr: 1,
  backgroundColor: "#e0e0e0",
  "&:hover": {
    backgroundColor: "#2ca5ff",
  },
};

export const tooltipEdit = {
  mr: 1,
  backgroundColor: "#e0e0e0",
  "&:hover": {
    backgroundColor: "green",
  },
};

export const tooltipDelete = {
  backgroundColor: "#e0e0e0",
  "&:hover": {
    backgroundColor: "#cd5d55",
  },
};

export const tootltipWhatsapp = {
  backgroundColor: "#dddddd",
  color: "#178641",
  "&:hover": {
    backgroundColor: "#dddddd",
  },
};

export const tootltipPhone = {
  backgroundColor: "#dddddd",
  color: "#2b7cf4",
  "&:hover": {
    backgroundColor: "#dddddd",
  },
};

export const paperStyle = {
  backgroundColor: "#dddddd",
  boxShadow: 0,
  padding: 1,
  borderRadius: 5,
  textAlign: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",
};

////////////////////////  User Tables Styles //////////////////////////

export const TableTypography = ({ children, fontSize, color, jc }) => {
  const isMobile = useIsMobile();
  return (
    <Typography
      sx={{
        color: color ? color : "#0d604c",
        fontWeight: "600",
        display: "flex",
        textAlign: "center",
        justifyContent: jc ? jc : "center",
        alignItems: "center",
      }}
      fontSize={fontSize ? fontSize : isMobile ? "1.4rem" : "2rem"}
    >
      {children}
    </Typography>
  );
};
export const TableTypographyLight = ({ children, fontSize, color }) => (
  <Typography
    sx={{ color: color ? color : "grey" }}
    fontSize={fontSize ? fontSize : "1rem"}
  >
    {children}
  </Typography>
);

export const VideoPlayer = ({ url, mt }) => (
  <video
    src={`${url}`}
    controls
    controlsList="nodownload"
    onContextMenu={(e) => e.preventDefault()}
    style={{
      maxWidth: "100%",
      height: "auto",
      borderRadius: "15px",
      marginTop: mt ? mt : "20px",
    }}
  />
);

////////////////////////  Admin Calendar //////////////////////////

export const statusMap = {
  CREATED: "❌ Sin publicar",
  ASSIGNED: "✅ Publicado",
  CHANGED: "⏳ En espera de aprobación",
  REQUESTED: "⏳ Cambio solicitado",
  APPROVED: "🔄 Cambio aceptado",
};
