import React from "react";
import { Grid, Link, Typography } from "@mui/material";
import logo from "../public/assets/images/dh-logo.svg";
import { footerStyle } from "../../Styles";
import { useIsMobile } from "./../../hooks/useIsMobile";

function Copyright() {
  return (
    <Typography color="white" variant="body2">
      {"© "}
      <Link href="#" color={"#FFFFFF"}>
        .DocHealth APP ®
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}

export const Footer = ({ userRole }) => {
  const isMobile = useIsMobile();
  if (
    userRole === "MANAGER" ||
    userRole === "USER" ||
    userRole === "ADMIN" ||
    userRole === "SUPERADMIN"
  ) {
    return (
      <Grid
        container
        sx={{ ...footerStyle, flexDirection: isMobile ? "column" : "row" }}
      >
        <Grid
          item
          sx={{
            mb: isMobile && 2,
            mt: isMobile && 2,
            marginLeft: !isMobile && 2,
            backgroundImage: `url(${logo})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "150px",
            height: "150px",
            filter: "brightness(0) invert(0.9)",
          }}
        />
        {/* <Grid item mb={isMobile && 3}>
          <Typography sx={footerTextStyle}>Clínica de Cuyo</Typography>
          <Typography sx={footerTextSecStyle}>
            Guardia (Interno 1405)
          </Typography>
          <Tooltip size="small" sx={{ color: "#2b7cf4" }} href={`tel:4059000`}>
            <IconButton>
              <Call />
            </IconButton>
          </Tooltip>
        </Grid> */}
        {/* <Grid item mb={isMobile && 3}>
          <Typography sx={footerTextStyle}>Hector Lamacchia</Typography>
          <Typography sx={footerTextSecStyle}>Jefe de Guardia</Typography>
          <Tooltip size="small">
            <IconButton
              sx={{ color: "#25d366" }}
              href="https://api.whatsapp.com/send?phone=2613366186"
              target="_blank"
              rel="noopener noreferrer"
            >
              <WhatsApp />
            </IconButton>
          </Tooltip>
          <Tooltip size="small">
            <IconButton sx={{ color: "#2b7cf4" }} href={`tel:2613366186`}>
              <Call />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item mb={isMobile && 3}>
          <Typography sx={footerTextStyle}>Anabel Farachi</Typography>
          <Typography sx={footerTextSecStyle}>
            Coordinación Médica General
          </Typography>
          <Tooltip size="small">
            <IconButton
              sx={{ color: "#25d366" }}
              href="https://api.whatsapp.com/send?phone=2615720705"
              target="_blank"
              rel="noopener noreferrer"
            >
              <WhatsApp />
            </IconButton>
          </Tooltip>
          <Tooltip size="small">
            <IconButton sx={{ color: "#2b7cf4" }} href={`tel:2615720705`}>
              <Call />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item mb={isMobile && 3}>
          <Typography sx={footerTextStyle}>Vanina Solavallone</Typography>
          <Typography sx={footerTextSecStyle}>
            Capacitación médica, Protocolización y Auditoría
          </Typography>
          <Tooltip size="small">
            <IconButton
              sx={{ color: "#25d366" }}
              href="https://api.whatsapp.com/send?phone=2616409172"
              target="_blank"
              rel="noopener noreferrer"
            >
              <WhatsApp />
            </IconButton>
          </Tooltip>
          <Tooltip size="small">
            <IconButton sx={{ color: "#2b7cf4" }} href={`tel:2616409172`}>
              <Call />
            </IconButton>
          </Tooltip>
        </Grid> */}
        <Grid item mb={isMobile && 5} mt={isMobile && 2} mr={!isMobile && 4}>
          <Copyright />
        </Grid>
      </Grid>
    );
  } else {
    return <></>;
  }
};
