import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Fade,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
} from "@mui/material";
import { useForm } from "../../hooks/useForm";
import { useLogin } from "../../hooks/useLogin";
import { useState } from "react";
import RestorePassword from "./RestorePassword";
import { TableTypography, textButton } from "../../Styles";
import { useIsMobile } from "./../../hooks/useIsMobile";

const initialForm = {
  email: "",
  password: "",
};

export default function Login({ showLogin }) {
  const isMobile = useIsMobile();
  const { handleChange, form } = useForm(initialForm);
  const {
    onLogin,
    showPassword,
    handleMouseDownPassword,
    handleClickShowPassword,
  } = useLogin(form);
  const [open, setOpen] = useState(false);
  const handleDialogClose = () => setOpen(false);

  return (
    <Fade in={showLogin} timeout={800}>
      <Grid
        container
        justifyContent={"center"}
        display={showLogin === false && "none"}
      >
        <Box maxWidth={isMobile ? "95%" : "sm"}>
          <Paper
            sx={{
              backgroundColor: "#dddddd",
              boxShadow: 0,
              padding: 3,
              borderRadius: 5,
              textAlign: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TableTypography>Inicia Sesión</TableTypography>
            <Grid container>
              <TextField
                margin="normal"
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={handleChange}
              />
              <FormControl fullWidth>
                <InputLabel>Contraseña</InputLabel>
                <OutlinedInput
                  required
                  fullWidth
                  label="Contraseñ"
                  id="password"
                  name="password"
                  onChange={handleChange}
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={onLogin}
                disableElevation
                sx={{
                  mt: 3,
                  mb: 1,
                  width: "100%",
                }}
              >
                Iniciar Sesión
              </Button>
              <Button
                onClick={() => setOpen(true)}
                variant="text"
                sx={textButton}
              >
                Olvide mi contraseña
              </Button>
            </Grid>
          </Paper>
          <RestorePassword open={open} handleDialogClose={handleDialogClose} />
        </Box>
      </Grid>
    </Fade>
  );
}
