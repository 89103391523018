import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  CircularProgress,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { ArrowBack, Update } from "@mui/icons-material";
import { useSnackBarContext } from "./../../../../../context/SnackBarContext";
import { groupOptions } from "../../../../../constants/Options";
import VideoService from "../../../../../services/axios/VideoService.js";
import { paperStyle, TableTypography, VideoPlayer } from "../../../../../Styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useUserList } from './../../../../../hooks/useUserList';
import LoadingMain from "../../../../public/LoadingMain.js";

function UpdateVideo() {
  const { id } = useParams();
  const { triggerSnackbar } = useSnackBarContext();
  const [departmentList, setDepartmentList] = useState([]);
  const { fetchDepartmentList } = useUserList(departmentList);
  const [video, setVideo] = useState({
    title: "",
    group: "",
    department: "",
    date: undefined,
    url: "",
  });
  const handleDateChange = (newDate) => {
    const updatedDate = dayjs(newDate);
    setVideo((prevVideo) => ({
      ...prevVideo,
      date: updatedDate,
    }));
  };
  const handleGoBack = () => {
    window.history.back();
  };
  useEffect(() => {
    VideoService.getVideoById(id).then((res) => {
      setVideo(res.data);
    });
  }, [id]);
  const updateVideo = (e) => {
    e.preventDefault();
    VideoService.updateVideo(id, video)
      .then((r) => {
        triggerSnackbar(r.data, "success");
        handleGoBack();
      })
      .catch((error) => {
        if (error.response) {
          triggerSnackbar(error.response.data.message, "error");
        } else {
          triggerSnackbar(
            "Servidor en mantenimiento, intente nuevamente en unos instantes.",
            "warning"
          );
        }
      });
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setVideo({
      ...video,
      [name]: value,
    });
  };  
const handleClick = (event) => {
    fetchDepartmentList(setDepartmentList);
  };
  if (video.date === undefined) {
    return <LoadingMain />;
  }

  return (
    <Container maxWidth="sm">
      <Paper
        sx={{
          ...paperStyle,
          mb: 1,
        }}
      >
        <TableTypography>
          Editar Video
        </TableTypography>
      </Paper>
      <Paper sx={{ ...paperStyle, padding: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={7}>
            <TextField
              fullWidth
              label={"Título"}
              name={"title"}
              value={video.title}
              onChange={handleChange}
            />
          </Grid>
          <Grid item sm={5}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Publicación"
                  id="date"
                  value={dayjs(video.date)}
                  onChange={handleDateChange}
                  format="DD/MM/YYYY"
                  maxDate={dayjs(new Date())}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Área de interés</InputLabel>
              <Select
                name="group"
                value={video.group}
                onChange={handleChange}
                label="Área de interés"
              >
                {Object.entries(groupOptions).map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Departamento</InputLabel>
              <Select
                id="department"
                name="department"
                label="Departmentoc"
                value={video.department.toUpperCase()}
                onChange={handleChange}
                onOpen={handleClick}
              >
                {departmentList.length !== 0 ? (
                  departmentList.map((department) => (
                    <MenuItem key={department.id} value={department.title}>
                      {department.title === "MANAGER"
                        ? "Administradores"
                        : department.title === "PHARMACY"
                        ? "Farmacia"
                        : department.title === "URGENCY"
                        ? "Guardia"
                        : department.title === "MANAGEMENT"
                        ? "Gerencia"
                        : department.title}
                    </MenuItem>
                  ))
                ) : (
                  <Grid container alignItems="center" justifyContent="center">
                    <CircularProgress item sx={{ margin: 2 }} />
                  </Grid>
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <VideoPlayer url={video.url} />
        </Grid>
        <Grid display={"flex"} mt={3}>
          <Button
            fullWidth
            variant="contained"
            disableElevation
            endIcon={<Update />}
            onClick={updateVideo}
            sx={{ mr: 1 }}
          >
            Actualizar
          </Button>
          <Button
            type="button"
            fullWidth
            variant="contained"
            disableElevation
            endIcon={<ArrowBack />}
            sx={{
              backgroundColor: "#942121",
            }}
            onClick={handleGoBack}
          >
            Cancelar
          </Button>
        </Grid>
      </Paper>
    </Container>
  );
}

export default UpdateVideo;
