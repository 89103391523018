import { Container, Grid } from "@mui/material";
import { useState } from "react";
import UserEvents from "./UserEvents.js";
import RequiredEvents from "./RequiredEvents";

export default function Events() {
  const [updateKey, setUpdateKey] = useState(0);

  return (
    <Container key={updateKey} maxWidth={"l"}>
      <Grid container spacing={1} sx={{ textAlign: "center" }}>
        <UserEvents updateKey={setUpdateKey} />
        <RequiredEvents updateKey={setUpdateKey} />
      </Grid>
    </Container>
  );
}
