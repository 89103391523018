import React from "react";
import { Box, Grid, IconButton, TextField, Tooltip } from "@mui/material";
import { Send } from "@mui/icons-material";
import MailService from "../../../../services/axios/MailService.js";
import { useSnackBarContext } from "../../../../context/SnackBarContext.js";
import { useForm } from "../../../../hooks/useForm.js";
import { tooltipEdit } from "../../../../Styles.js";

const MailForm = ({ userId, onClose, preMssg }) => {
  const { form, handleChange } = useForm({ mssg: "" });
  const { triggerSnackbar } = useSnackBarContext();
  const handleSendMail = () => {
    if (form.mssg === "") return;
    onClose();
    MailService.sendMail(userId, form)
      .then((res) => {
        triggerSnackbar(res.data, 'success');
      })
      .catch((err) => {});
  };
  return (
    <Box mt={1}>
      <Grid container spacing={1} display={"flex"} flexDirection={"column"}>
        <Grid item>
          <TextField
            fullWidth
            multiline
            inputProps={{ maxLength: 300 }}
            id="mssg"
            label="Mensaje"
            name="mssg"
            autoComplete="mssg"
            onChange={handleChange}
            value={preMssg ? preMssg : form.mssg}
          />
        </Grid>
        <Grid item container justifyContent={"end"}>
          <Tooltip title="Enviar">
            <IconButton sx={tooltipEdit} onClick={handleSendMail}>
              <Send />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MailForm;
