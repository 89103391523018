import React, { Component } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Footer } from "./components/public/Footer";
import { customTheme } from "./Styles";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box>
          <ThemeProvider theme={customTheme}>
            <Container sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h1">Algo salió mal</Typography>
              <Typography variant="h6">
                Te pedimos disculpas. Nuestro equipo ha sido notificado y
                estamos trabajando para solucionar el problema.
              </Typography>
              <Divider sx={{ mt: 5, mb: 5 }} />
              <Typography variant="h7">
                Si el error persiste, por favor contacta a nuestro equipo de
                soporte.
              </Typography>
              <Button
                href="/home"
                size="large"
                sx={{ mt: 5, backgroundColor: "#083a2e", color: "#ffffff" }}
              >
                ir a la pagina principal
              </Button>
            </Container>
          </ThemeProvider>
          <Footer />
        </Box>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
