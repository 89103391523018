import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { paperStyle, TableTypography } from "../../../../../Styles.js";
import { Delete, DocumentScanner, Send } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ProtocolService from "../../../../../services/axios/ProtocolService.js";
import { useSnackBarContext } from "../../../../../context/SnackBarContext.js";
import { groupOptions } from "../../../../../constants/Options.js";
import { useNavigate } from "react-router-dom";

export default function DocumentUpload() {
  const { triggerSnackbar } = useSnackBarContext();
  const navigate = useNavigate();
  // API Key de TinyMCE
  const api_key = process.env.REACT_APP_TINYMCE_APIKEY;

  // Estados para el contenido del editor
  const [group, setGroup] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [pDate, setPDate] = React.useState(null);
  const [content, setContent] = React.useState("");
  const [author, setAuthor] = React.useState("");

  // Handlers para los cambios en los campos del formulario
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleGroupChange = (event) => {
    setGroup(event.target.value);
  };
  const handlePDateChange = (newValue) => {
    setPDate(newValue);
  };
  const handleEditorChange = (content, editor) => {
    setContent(content);
  };
  const handleAuthorChange = (event) => {
    setAuthor(event.target.value);
  };

  // Funcion para validar los datos
  const validateData = (data) => {
    if (data.title === "") {
      triggerSnackbar("El título no puede estar vacío", "error");
      return false;
    }
    if (data.author === "") {
      triggerSnackbar("El autor no puede estar vacío", "error");
      return false;
    }
    if (data.protocolGroup === "") {
      triggerSnackbar("El grupo no puede estar vacío", "error");
      return false;
    }
    if (data.publicationDate === null) {
      triggerSnackbar("La fecha de publicación no puede estar vacía", "error");
      return false;
    }
    if (data.content === "") {
      triggerSnackbar("El contenido no puede estar vacío", "error");
      return false;
    }
    return true;
  };
  // Funcion para subir el contenido
  const uploadContent = async () => {
    const formData = {
      title,
      protocolGroup: group.toUpperCase(),
      publicationDate: pDate,
      content,
      author,
    };
    // valido los datos
    validateData(formData) &&
      ProtocolService.createProtocol(formData)
        .then((r) => {
          triggerSnackbar(r.data, "success");
          navigate(
            formData.protocolGroup !== "PROCEDIMIENTO"
              ? "/manager/data?table=protocol"
              : "/manager/data?table=procedure"
          );
        })
        .catch((e) => {
          console.log("error: ", e);
          triggerSnackbar(e.response.data.message, "error");
        });
  };

  // Funcion para borrar el contenido
  const eraseData = () => {
    setTitle("");
    setGroup("");
    setPDate(null);
    setContent("");
    setAuthor("");
  };
  return (
    <Paper sx={{ ...paperStyle, display: "flex", padding: 2 }}>
      <TableTypography fontSize={"1.4rem"}>
        Publicación de documento <DocumentScanner sx={{ ml: 1 }} />
      </TableTypography>
      <Grid container spacing={2} mt={1}>
        <Grid item sm={12}>
          <FormControl fullWidth>
            <TextField
              label="Título"
              fullWidth
              id="title"
              value={title}
              onChange={handleTitleChange}
            />
          </FormControl>
        </Grid>
        <Grid item sm={6}>
          <FormControl fullWidth>
            <TextField
              label="Autor/es"
              fullWidth
              placeholder="autores separados por coma (Ej.: Juan Perez, Maria Lopez)"
              id="author"
              value={author}
              onChange={handleAuthorChange}
            />
          </FormControl>
        </Grid>
        <Grid item sm={3}>
          <FormControl fullWidth>
            <InputLabel>Grupo</InputLabel>
            <Select
              id="protocolGroup"
              value={group}
              label="Group"
              onChange={handleGroupChange}
            >
              {Object.entries(groupOptions).map(([value, label]) => (
                <MenuItem key={value} value={value.toUpperCase()}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={3}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Fecha Publicación"
                id="createdDate"
                value={pDate}
                onChange={handlePDateChange}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item sm={12}>
          <Editor
            apiKey={api_key}
            onEditorChange={handleEditorChange}
            init={{
              plugins:
                "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker",
              toolbar:
                "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | checklist numlist bullist indent outdent | removeformat",
              language: "es",
              height: 800,
            }}
          />
        </Grid>
        <Grid item sm={12}>
          <Button
            variant="contained"
            color="error"
            disableElevation
            onClick={() => {
              eraseData();
            }}
            endIcon={<Delete />}
            sx={{ mr: 2 }}
          >
            Borrar datos
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={() => {
              uploadContent();
            }}
            endIcon={<Send />}
          >
            Publicar
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
