import UserService from "../services/axios/UserService.js";
import StatsService from "../services/axios/StatsService.js";

export const useHome = () => {
  const fetchProfile = (setUser) =>
    UserService.getProfile().then((res) => {
      setUser(res.data);
    });
  const fetchDoughnut = (setDoughnut) => {
    StatsService.getUserDoughnut()
      .then((res) => {
        setDoughnut(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const fetchUserBar = (setBar) => {
    StatsService.getUserBar()
      .then((res) => {
        setBar(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const fetcWhoIsNow = (setDepartments) => {
    StatsService.getWhoIsNow()
      .then((res) => {
        setDepartments(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const fetchManagerBar = (selectedOption, setBar) => {
    StatsService.getManagerBar(selectedOption)
      .then((res) => {
        setBar(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const fetchManagerDoughnut = (selectedOption, setDoughnut) => {
    StatsService.getManagerDoughnut(selectedOption)
      .then((res) => {
        setDoughnut(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return {
    fetchProfile,
    fetchDoughnut,
    fetchUserBar,
    fetcWhoIsNow,
    fetchManagerBar,
    fetchManagerDoughnut
  };
};
