import { useState } from "react";
import UserService from "../services/axios/UserService.js";
import { useNavigate } from "react-router-dom";
import { useSnackBarContext } from "../context/SnackBarContext.js";

export const useUserList = (departmentList) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const navigate = useNavigate();
  const { triggerSnackbar } = useSnackBarContext();

  const fetchUserList = async (departmentId, setUserList) => {
    await UserService.getUserList(departmentId)
      .then((res) => {
        setUserList(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchManagersList = async (setManagers) => {
    await UserService.getManagersList()
      .then((res) => {
        setManagers(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchAdminsList = async (setAdmins) => {
    await UserService.getAdminsList()
      .then((res) => {
        setAdmins(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchErasedUserList = async (setBin) => {
    await UserService.getBinList().then((res) => {
      setBin(res.data);
    });
  };

  const fetchDepartmentList = async (setDepartmentList) => {
    await UserService.getAdminTables().then((res) => {
      setDepartmentList(res.data);
    });
  };

  const editUser = (id) => {
    navigate(`/user/update/${id}`);
  };

  const handleGetTable = async (name, departmentList, setDepartmentList) => {
    try {
      const response = await UserService.getTableByDepartmentName(name);
      const newUsers = response.data;

      // Encuentra el índice del departamento a actualizar
      const deptIndex = departmentList.findIndex((dept) => dept.title === name);
      if (deptIndex !== -1) {
        // Crea una copia de userTables para no modificar el estado directamente
        const updatedUserTables = [...departmentList];

        // Actualiza solo el departamento específico con los nuevos usuarios
        updatedUserTables[deptIndex] = {
          ...updatedUserTables[deptIndex],
          // Mapea sobre newUsers para formatear la fecha de inicio de cada usuario
          users: newUsers.map((user) => ({
            ...user,
            startDate: myDate(user.startDate), // Utiliza tu función myDate para formatear la fecha
          })),
        };

        // Actualiza el estado con la nueva versión de userTables
        setDepartmentList(updatedUserTables);
      }
    } catch (error) {
      console.error("Error al obtener lista de usuarios: ", error);
    }
  };

  // obtencion de tablas segun el nombre de la misma, unicamente para el manager contador
  const handleGetAccountantTables = async (
    name,
    departmentList,
    setDepartmentList
  ) => {
    try {
      const response = await UserService.getAccountantTablesByDepartmentName(name);
      const newUsers = response.data;

      // Encuentra el índice del departamento a actualizar
      const deptIndex = departmentList.findIndex((dept) => dept.title === name);
      if (deptIndex !== -1) {
        // Crea una copia de userTables para no modificar el estado directamente
        const updatedUserTables = [...departmentList];

        // Actualiza solo el departamento específico con los nuevos usuarios
        updatedUserTables[deptIndex] = {
          ...updatedUserTables[deptIndex],
          // Mapea sobre newUsers para formatear la fecha de inicio de cada usuario
          users: newUsers.map((user) => ({
            ...user,
            startDate: myDate(user.startDate), // Utiliza tu función myDate para formatear la fecha
          })),
        };

        // Actualiza el estado con la nueva versión de userTables
        setDepartmentList(updatedUserTables);
      }
    } catch (error) {
      console.error("Error al obtener lista de usuarios: ", error);
    }
  };

  const toManager = (id, tableName, departmentList, setDepartmentList) => {
    UserService.toManager(id)
      .then(async (response) => {
        await handleGetTable(tableName, departmentList, setDepartmentList);
        triggerSnackbar(response.data, "success");
      })
      .catch((error) => {
        console.log(error);
        triggerSnackbar(error.response.data.message, "error");
      });
  };
  const toAccountant = (id, tableName, departmentList, setDepartmentList) => {
    UserService.toAccountant(id)
      .then(async (response) => {
        await handleGetTable(tableName, departmentList, setDepartmentList);
        triggerSnackbar(response.data, "success");
      })
      .catch((error) => {
        console.log(error);
        triggerSnackbar(error.response.data.message, "error");
      });
  };
  const fromAdmin = (id, handleGetAdmins) => {
    UserService.toManager(id)
      .then(async (response) => {
        triggerSnackbar(response.data, "success");
        handleGetAdmins();
      })
      .catch((error) => {
        console.log(error);
        triggerSnackbar(error.response.data.message, "error");
      });
  };
  const toUser = (id, handleGetManagers) => {
    UserService.toUser(id)
      .then(async (response) => {
        triggerSnackbar(response.data, "success");
        handleGetManagers();
      })
      .catch((error) => {
        console.log(error);
        triggerSnackbar(error.response.data.message, "error");
      });
  };
  const toAdmin = (id, handleGetManagers) => {
    UserService.toAdmin(id)
      .then(async (response) => {
        triggerSnackbar(response.data, "success");
        handleGetManagers();
      })
      .catch((error) => {
        console.log(error);
        triggerSnackbar(error.response.data.message, "error");
      });
  };
  const deleteUser = (id) => {
    setSelectedUserId(id);
    setDeleteDialogOpen(true);
  };
  const handleEraseConfirmed = async (
    tableName,
    departmentList,
    setDepartmentList
  ) => {
    setDeleteDialogOpen(false);
    await UserService.eraseUser(selectedUserId)
      .then(async (response) => {
        await handleGetTable(tableName, departmentList, setDepartmentList);
        triggerSnackbar(response.data, "success");
      })
      .catch((error) => {
        console.log(error);
        triggerSnackbar(error.response.data.message, "error");
      });
  };
  const handleDeleteConfirmed = async (id, setBin) => {
    await UserService.deleteUser(id)
      .then(async (response) => {
        await fetchErasedUserList(setBin);
        triggerSnackbar(response.data, "success");
      })
      .catch((error) => {
        console.log(error);
        triggerSnackbar(error.response.data.message, "error");
      });
  };
  const handleRestoreUser = async (id, setBin) => {
    await UserService.restoreUser(id)
      .then(async (response) => {
        await fetchErasedUserList(setBin);
        triggerSnackbar(response.data, "success");
      })
      .catch((error) => {
        console.log(error);
        triggerSnackbar(error.response.data.message, "error");
      });
  };
  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };
  const activateUser = async (
    fetchAdminorManagerTable,
    id,
    title,
    departmentList,
    setDepartmentList
  ) => {
    await UserService.activateUser(id)
      .then((res) => {
        triggerSnackbar(res.data, "success");
        if (title === undefined) {
          return fetchAdminorManagerTable();
        }
        handleGetTable(title, departmentList, setDepartmentList);
      })
      .catch((err) => {
        console.error(err);
        triggerSnackbar(err.response.data.message, "error");
      });
  };
  const inactivateUser = (
    fetchAdminorManagerTable,
    id,
    title,
    departmentList,
    setDepartmentList
  ) => {
    UserService.inactivateUser(id)
      .then((res) => {
        triggerSnackbar(res.data, "success");
        if (title === undefined) {
          return fetchAdminorManagerTable();
        }
        handleGetTable(title, departmentList, setDepartmentList);
      })
      .catch((err) => {
        console.error(err);
        triggerSnackbar(err.response.data.message, "error");
      });
  };

  function myDate(date) {
    const myDate = new Date(date);
    const day = myDate.getDate();
    const month = myDate.getMonth() + 1;
    const year = myDate.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  }

  return {
    departmentList,
    deleteDialogOpen,
    editUser,
    deleteUser,
    handleEraseConfirmed,
    handleDeleteConfirmed,
    handleCloseDeleteDialog,
    handleGetTable,
    handleGetAccountantTables,
    myDate,
    fetchUserList,
    fetchDepartmentList,
    fetchErasedUserList,
    handleRestoreUser,
    fetchManagersList,
    fetchAdminsList,
    toManager,
    toUser,
    fromAdmin,
    toAdmin,
    activateUser,
    inactivateUser,
    toAccountant,
  };
};
