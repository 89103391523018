import React from "react";
import { Grid, Link, Typography } from "@mui/material";
import { footerStyle } from "../../Styles";
import { useIsMobile } from "../../hooks/useIsMobile";
import logo from "./assets/images/dh-logo.svg";

const EmptyFooter = () => {
  const isMobile = useIsMobile();
  return (
    <Grid
      container
      sx={{
        ...footerStyle,
        minHeight: "200px",
        justifyContent: isMobile ? "center" : "space-between",
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      <Grid
        item
        sx={{
          marginLeft: isMobile ? 1 : 8,
          backgroundImage: `url(${logo})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "150px",
          height: "80px",
        }}
      ></Grid>
      <Grid item mb={isMobile && 5} mt={isMobile && 5} mr={!isMobile && 8}>
        <Typography color="white" variant="body2">
          {"© "}
          <Link href="#" color={"#FFFFFF"}>
            .DocHealth APP
          </Link>{" "}
          {new Date().getFullYear()}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default EmptyFooter;
