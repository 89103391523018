import {
  Chip,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  keyframes,
} from "@mui/material";
import React, { useEffect } from "react";
import {
  TableTypography,
  paperStyle,
  tootltipPhone,
  tootltipWhatsapp,
} from "../../../../Styles";
import { useState } from "react";
import { useHome } from "../../../../hooks/useHome";
import { Call, LabelImportant, Schedule, WhatsApp } from "@mui/icons-material";

const WhoIsNow = () => {
  const [departments, setDepartments] = useState([]);
  const { fetcWhoIsNow } = useHome();
  useEffect(() => {
    fetcWhoIsNow(setDepartments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const departmentTitles = {
    MANAGER: "Administradores",
    PHARMACY: "Farmacia",
    URGENCY: "Guardia",
    MANAGEMENT: "Gerencia",
    UCO: "uco",
    UCI: "uci",
    UTI: "uti",
    NEO: "neo",
  };
  const scrollAnimation = keyframes`
  from {
    transform: translateX(10%);
  }
  to {
    transform: translateX(-100%);
  }
`;
  return (
    <Paper sx={paperStyle}>
      <Grid
        container
        alignItems={"center"}
        justifyContent={"space-between"}
        flexDirection={"row"}
        sx={{
          display: "flex",
          overflow: "hidden",
          flexWrap: "nowrap", 
          whiteSpace: "nowrap", 
          position: "relative",
          marginY: 1 
        }}
      >
        <Grid item sx={{ zIndex: 2, position: "relative" }}>
          <Chip label={"de guardia"} color="success" icon={<Schedule />} />
        </Grid>
        {departments.length === 0 ? (
          <Grid item>
            <Chip label={"cargando ..."} color="info" />
          </Grid>
        ) : (
          <Grid
            item
            display={"flex"}
            flexDirection={"row"}
            sx={{
              animation: `${scrollAnimation} 35s linear infinite`,
            }}
          >
            {departments.map(
              (department) =>
                department.users.length !== 0 && (
                  <Grid
                    item
                    key={department.id}
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"row"}
                  >
                    <Chip
                      label={`${
                        departmentTitles[department.title] || department.title
                      }`}
                      color="info"
                      variant="outlined"
                      size="small"
                      sx={{ mr: 1, ml: 2 }}
                      icon={<LabelImportant />}
                    />
                    {department.users.map((user) => (
                      <Grid
                        item
                        key={user.id}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        alignContent="center"
                      >
                        <TableTypography>{`${user.name.toLowerCase()} ${user.lastname.toLowerCase()}`}</TableTypography>
                        <Tooltip>
                          <IconButton
                            size="small"
                            sx={{ ...tootltipWhatsapp, marginRight: -0.5 }}
                            href={`https://api.whatsapp.com/send?phone=${user.phone}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <WhatsApp />
                          </IconButton>
                          <IconButton
                            size="small"
                            sx={tootltipPhone}
                            href={`tel:${user.phone}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Call />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    ))}
                  </Grid>
                )
            )}
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default WhoIsNow;
