import axios from "./axios_instance.js";
import { getAuthToken } from "../axios/axios_helper.js";
import { getBackendUrl } from "../../config.js";

const baseURL = getBackendUrl();
const USER_API_BASE_URL = baseURL+"/content";
const NOTICE_API_BASE_URL = baseURL+"/notice";
const token = getAuthToken();
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

class ProtocolService {
  createProtocol(protocol) {
    return axios
      .post(USER_API_BASE_URL + "/upload-protocol", protocol, config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  getProtocol(id) {
    return axios
      .get(USER_API_BASE_URL + "/get/" + id, config)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        console.error("Error obteniendo protocolo!", err);
      });
  }

  editProtocol(protocol) {
    return axios
      .post(USER_API_BASE_URL + "/edit-protocol", protocol, config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  protocolList() {
    return axios.get(USER_API_BASE_URL + "/list-protocols", config);
  }

  procedureList() {
    return axios.get(USER_API_BASE_URL + "/list-procedures", config);
  }

  mostViewedProtocol() {
    return axios.get(USER_API_BASE_URL + "/mostviewed", config);
  }

  improveViews(id) {
    return axios.get(USER_API_BASE_URL + "/mostviewed/" + id, config);
  }

  deleteProtocol(id) {
    return axios.get(USER_API_BASE_URL + "/delete/" + id, config);
  }

  createNotice(notice) {
    return axios
      .post(NOTICE_API_BASE_URL + "/create", notice, config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  getNotice() {
    return axios.get(NOTICE_API_BASE_URL + "/get", config);
  }
}

const instance = new ProtocolService();
export default instance;
