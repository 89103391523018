import axios from "./axios_instance";
import { getAuthToken } from "./axios_helper";
import { getBackendUrl } from "../../config";

const baseURL = getBackendUrl();
const VIDEO_URL = baseURL + "/video";

const token = getAuthToken();

const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

class VideoService {
  // add video
  addVideo(video) {
    return axios.post(VIDEO_URL + "/add", video, config);
  }

  // get url to upload video
  getPresignedUrl(contentType, hash) {
    return axios.get(VIDEO_URL + `/upload-video`, {
      ...config,
      params: {
        contentType: contentType,
        hash: hash,
      },
    });
  }

  // upload video to s3
  uploadVideo(presignedUrl, file) {
    return axios.put(presignedUrl, file);
  }

  // check if video exists
  checkHashedVideo(hash) {
    return axios.get(VIDEO_URL + `/check-hash/${hash}`, config);
  }

  // get all videos
  videoList() {
    return axios.get(VIDEO_URL + "/list", config);
  }

  // get video by id
  getVideoById(id) {
    return axios.get(VIDEO_URL + "/get/" + id, config);
  }

  // edit video
  updateVideo(id, video) {
    return axios.put(VIDEO_URL + "/edit/" + id, video, config);
  }

  // delete video
  deleteVideo(id) {
    return axios.delete(VIDEO_URL + "/delete/" + id, config);
  }
}
const instance = new VideoService();
export default instance;
