import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Tooltip,
} from "@mui/material";
import {
  paperStyle,
  TableTypography,
  TableTypographyLight,
} from "../../../Styles";
import { Cached, Visibility } from "@mui/icons-material";
import { useForm } from "../../../hooks/useForm";
import EventValueService from "../../../services/axios/EventValueService";
import { useSnackBarContext } from "./../../../context/SnackBarContext";
import { useEffect, useState } from "react";

const EventValue = () => {
  return (
    <Grid item xs={12} sm={12} container display={"flex"} alignItems={"center"} spacing={1}>
      <Grid item xs={12} sm={4}>
        <Paper sx={paperStyle}>
          <TableTypography>Valores</TableTypography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={4}>
        <ShowValues />
      </Grid>
      <Grid item xs={12} sm={4}>
        <AddValue />
      </Grid>
    </Grid>
  );
};

const ShowValues = () => {
  const [values, setValues] = useState({
    habiles: 0,
    noHabiles: 0,
  });
  const updateValues = () => {
    EventValueService.getLastValue()
      .then((response) => {
        setValues(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    updateValues();
  }, []);
  return (
    <Paper
      sx={{
        ...paperStyle,
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
        gap: 1,
      }}
    >
      <Grid container justifyContent={"center"}>
        <Grid item xs={5} alignContent={"center"}>
          <TableTypographyLight fontSize={"1.2rem"}>
            Ultimo valor registrado
          </TableTypographyLight>
        </Grid>
        <Grid item xs={6} container alignContent={"center"}>
          <Grid item xs={12}>
            <TableTypographyLight>
              Dias hábiles: $ {values.habiles}
            </TableTypographyLight>
          </Grid>
          <Grid item xs={12}>
            <TableTypographyLight>
              Días no hábiles: $ {values.noHabiles}
            </TableTypographyLight>
          </Grid>
        </Grid>
        <Grid item xs={1} alignContent={"center"}>
          <Tooltip title="actualizar valores" onClick={updateValues}>
            <IconButton>
              <Cached />
            </IconButton>
          </Tooltip>
          <Tooltip title="valores historicos">
            <IconButton>
              <Visibility />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Paper>
  );
};

const AddValue = () => {
  const { triggerSnackbar } = useSnackBarContext();
  const inputs = ["habiles", "noHabiles"];
  const initialForm = {
    habiles: null,
    noHabiles: null,
  };
  const { handleChange, form, setForm } = useForm(initialForm);
  const validateForm = () => {
    if (
      form.habiles === 0 ||
      form.noHabiles === 0 ||
      form.habiles === "" ||
      form.noHabiles === "" ||
      form.habiles === null ||
      form.noHabiles === null
    ) {
      triggerSnackbar("Debe llenar todos los campos.", "error");
      return false;
    } else {
      return true;
    }
  };
  const resetForm = () => {
    setForm({
      habiles: null,
      noHabiles: null,
    });
  };
  const onSend = () => {
    if (validateForm()) {
      EventValueService.createEventValue(form)
        .then((response) => {
          triggerSnackbar(response?.data, "success");
          resetForm();
        })
        .catch((error) => {
          console.log(error);
          triggerSnackbar(error?.response?.data, "error");
        });
    }
  };
  return (
    <>
      <Paper
        sx={{
          ...paperStyle,
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          gap: 1,
        }}
      >
        <TableTypographyLight>Añadir nuevo valor:</TableTypographyLight>
        {inputs.map((input) => (
          <OutlinedInput
            key={input}
            size="small"
            name={input}
            placeholder={input === "habiles" ? "Días hábiles" : "Días no hábiles"}
            value={form[input] || ''}
            onChange={handleChange}
            type="number"
            startAdornment={
              <InputAdornment position="start">
                <TableTypography fontSize={"1rem"}>$</TableTypography>
              </InputAdornment>
            }
          />
        ))}
        <Button onClick={onSend}>Añadir</Button>
      </Paper>
    </>
  );
};

export default EventValue;
