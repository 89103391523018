import { useEffect, useState } from "react";
import ProtocolService from "../services/axios/ProtocolService.js";
import { useSnackBarContext } from "../context/SnackBarContext.js";
import dayjs from "dayjs";

export const useProcedureList = () => {
  // LOGIC FOR PROCEDURE RENDERING ////////////////////////////////////////////////
  const { triggerSnackbar } = useSnackBarContext();
  const [procedureList, setProcedureList] = useState();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedProcedureId, setSelectedProcedureId] = useState(null);

  useEffect(() => {
    ProtocolService.procedureList()
      .then((r) => {
        // Cambio la fecha a formato de dayjs
        r.data.forEach((element) => {
          element.publicationDate = dayjs(element.publicationDate).format(
            "DD/MM/YYYY"
          );
        });
        setProcedureList(r.data);
      })
      .catch((err) => {
        triggerSnackbar(err?.response?.data?.message, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchProcedureList = async () => {
    try {
      const response = await ProtocolService.procedureList();
      const updatedProcedureList = response.data;
      setProcedureList(updatedProcedureList);
    } catch (error) {
      triggerSnackbar(error?.response?.data?.message, "error");
    }
  };

  const deleteProcedure = (id) => {
    setSelectedProcedureId(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirmed = async () => {
    setDeleteDialogOpen(false);
    await ProtocolService.deleteProtocol(selectedProcedureId)
      .then(async () => {
        triggerSnackbar("Documento eliminado correctamente", "success");
        await fetchProcedureList();
      })
      .catch((err) => {
        triggerSnackbar(err?.response?.data?.message, "error");
      });
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };
  return {
    procedureList,
    deleteDialogOpen,
    deleteProcedure,
    handleDeleteConfirmed,
    handleCloseDeleteDialog,
  };
};
