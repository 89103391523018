import React from "react";
import { useState } from "react";
import { Delete, ExpandMore, Restore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useUserList } from "../../../../hooks/useUserList";
import {
  accordionStyle,
  TableTypography,
  TableTypographyLight,
  tooltipDelete,
  tooltipEdit,
} from "../../../../Styles";
import { tableStyle } from "../../../../Styles";
import { esESGrid } from "../../../../constants/esESGrid";

const PersonalBin = () => {
  const [deleteDialogBin, setDeleteDialogBin] = useState(false);
  const [restoreDialogBin, setRestoreDialogBin] = useState(false);
  const [bin, setBin] = useState([]);
  const [currentId, setCurrentId] = useState();
  const { fetchErasedUserList, handleDeleteConfirmed, handleRestoreUser } =
    useUserList();
  const handleCloseDialogBin = () => {
    setDeleteDialogBin(false);
    setRestoreDialogBin(false);
  };
  const handleGetErased = () => {
    fetchErasedUserList(setBin);
  };
  const erased = [
    {
      field: "name",
      headerName: "Nombre",
      flex: 1,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "lastname",
      headerName: "Apellido",
      flex: 1,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "dni",
      headerName: "DNI",
      typeof: "number",
      flex: 1,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "specialtie",
      headerName: "Especialidad",
      flex: 1,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actions",
      headerName: "Acciones",
      flex: 2,
      editable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Box>
          <Tooltip
            title="Restaurar usuario"
            onClick={() => {
              setRestoreDialogBin(true);
              setCurrentId(params.id);
            }}
          >
            <IconButton size="small" sx={tooltipEdit}>
              <Restore />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Borrar usuario permanentemente"
            onClick={() => {
              setDeleteDialogBin(true);
              setCurrentId(params.id);
            }}
          >
            <IconButton size="small" sx={tooltipDelete}>
              <Delete />
            </IconButton>
          </Tooltip>
          <Dialog
            open={deleteDialogBin}
            onClose={handleCloseDialogBin}
            style={{ textAlign: "center" }}
          >
            <DialogTitle>Atención!</DialogTitle>
            <DialogContent>
              Si selecciona borrar, el usuario y los eventos del calendario
              relacionados seran borrados permanentemente de la base de datos.
              Desea continuar?
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button onClick={handleCloseDialogBin} color="primary">
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  handleDeleteConfirmed(currentId, setBin);
                  handleCloseDialogBin();
                }}
                color="error"
              >
                Borrar
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={restoreDialogBin}
            onClose={handleCloseDialogBin}
            style={{ textAlign: "center" }}
          >
            <DialogTitle>Atención!</DialogTitle>
            <DialogContent>
              Desea restaurar la cuenta del usuario seleccionado?
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button onClick={handleCloseDialogBin} color="primary">
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  handleRestoreUser(currentId, setBin);
                  handleCloseDialogBin();
                }}
                color="error"
              >
                Restaurar
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      ),
    },
  ];
  return (
    <Grid item xs={12} mt={5}>
      <Accordion
        onChange={(event, isExpanded) => {
          if (isExpanded) {
            handleGetErased();
          }
        }}
        sx={accordionStyle}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <TableTypography fontSize={"1.2rem"}>
            <Delete sx={{ mr: 1, color: "#137136" }} />
            Papelera
          </TableTypography>
        </AccordionSummary>
        <AccordionDetails>
          {bin.length === 0 ? (
            <TableTypographyLight>Papelera vacía</TableTypographyLight>
          ) : (
            <Box>
              <DataGrid
                sx={{ ...tableStyle }}
                rows={bin ? bin : []}
                columns={[...erased]}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterValues: [""],
                    },
                  },
                }}
                disableDensitySelector
                disableRowSelectionOnClick
                disableColumnMenu
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                pageSizeOptions={[5, 10, 20, 30]}
                localeText={esESGrid}
              />
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
export default PersonalBin;
