import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import MailForm from "./MailForm";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { Mail } from "@mui/icons-material";
import { TableTypography } from "../../../../Styles";
import { Date } from "core-js";
import EventValueService from "../../../../services/axios/EventValueService";

const FacMailDialog = ({
  userId,
  userName,
  userEmail,
  open,
  onClose,
  userHours,
}) => {
  const isMobile = useIsMobile();
  const date = new Date();
  // Restar un mes a la fecha actual
  date.setMonth(date.getMonth() - 1);
  // Obtener el nombre del mes anterior
  const month = date.toLocaleString("default", { month: "long" });

  const [values, setValues] = useState({
    habiles: 0,
    noHabiles: 0,
  });
  const updateValues = () => {
    EventValueService.getLastValue()
      .then((response) => {
        setValues(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    updateValues();
  }, []);

  const montoTotal = userHours * (values.habiles / 24);
  const preMssg = `Estimado/a ${userName},\n\nAdjunto encontrará el monto a facturar correspondiente a al mes de ${month} de ${date.getFullYear()}.\n\nHoras trabajadas del mes de ${month}: ${userHours} hs.\n\nMonto a facturar del mes de ${month}: $${montoTotal}.\n\nSaludos cordiales,\nAdministración.`;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <TableTypography>
          Envio de facturación
          <Mail sx={{ ml: 1 }} />
        </TableTypography>
      </DialogTitle>
      <DialogContent sx={{ minWidth: isMobile ? 300 : 500 }}>
        <DialogContentText ml={0.5}>{`De: Administración`}</DialogContentText>
        <DialogContentText ml={0.5}>{`Para: ${userName}`}</DialogContentText>
        <DialogContentText ml={0.5} mb={3}>{`${userEmail}`}</DialogContentText>
        <MailForm userId={userId} onClose={onClose} preMssg={preMssg} />
      </DialogContent>
    </Dialog>
  );
};

export default FacMailDialog;
