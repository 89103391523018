import { useState } from "react";
import CalendarService from "../services/axios/CalendarService.js";
import { useEffect } from "react";
import { useGetEvents } from "./useGetEvents.js";
import { useSnackBarContext } from "./../context/SnackBarContext";

export const useAddEvents = (initialEvent) => {
  const { triggerSnackbar } = useSnackBarContext();
  const [event, setEvent] = useState(initialEvent);
  const {
    fetchEvents,
    fetchUserEvents,
    fetchUserReqEvents,
    fetchChangedEvents,
  } = useGetEvents();
  const handleChange = (name, e) => {
    if (name === "start") {
      setEvent({
        ...event,
        startDate: e,
      });
    } else if (name === "end") {
      setEvent({
        ...event,
        endDate: e,
      });
    } else if (name === "id") {
      setEvent({
        ...event,
        userId: e.target.value,
      });
    } else if (name === "color") {
      setEvent({
        ...event,
        color: e.target.value,
      });
    }
  };

  const validateData = (event) => {
    if (
      event.startDate &&
      event.endDate &&
      (event.startDate.isAfter(event.endDate) ||
        event.startDate.isSame(event.endDate))
    ) {
      triggerSnackbar(
        "La fecha de finalización del evento no puede ser igual o anterior a la de inicio",
        "warning"
      );
      return false;
    } else if (
      event.endDate === undefined ||
      event.startDate === undefined ||
      event.endDate.isValid() === false ||
      event.startDate.isValid() === false ||
      event.userId.trim() === "" ||
      event.color.trim() === ""
    ) {
      triggerSnackbar("Todos los campos son requeridos", "warning");
      return false;
    } else {
      return true;
    }
  };

  const handleAddEvent = async (currentDates, updateKey, id) => {
    try {
      if (validateData(event)) {
        const response = await CalendarService.createEvent(id, event);
        await fetchEvents(currentDates.start, currentDates.end, id);
        triggerSnackbar(response.data.message, "success");
        updateKey((prevKey) => prevKey + 1);
      }
    } catch (error) {
      triggerSnackbar(
        error.response?.data?.message ||
          "Este usuario ya posse un evento en la misma fecha y/o horario.",
        "error"
      );
    }
  };

  const handleDeleteEvent = async (
    id,
    currentDates,
    departmentId,
    updateKey
  ) => {
    await CalendarService.deleteEvent(id)
      .then(async (r) => {
        await fetchUserEvents(
          currentDates.start,
          currentDates.end,
          departmentId
        );
        triggerSnackbar(r.data, "success");
      })
      .catch((err) => {
        triggerSnackbar(err.response.data.message, "error");
      });
    updateKey((prevKey) => prevKey + 1);
  };

  const handleEditEvent = async (currentDates, departmentId, updateKey) => {
    if (validateData(event)) {
      await CalendarService.editEvent(departmentId, event)
        .then(async (r) => {
          await fetchEvents(currentDates.start, currentDates.end, departmentId);
          triggerSnackbar(r.data, "success");
        })
        .catch((err) => {
          triggerSnackbar(
            err.response?.data?.message ||
              "Este usuario ya posse un evento en la misma fecha y/o horario.",
            "error"
          );
        });
      updateKey((prevKey) => prevKey + 1);
    }
  };

  const handlePublishEvents = async (events, currentDates, id, updateKey) => {
    await CalendarService.publishEvents(id, events)
      .then(async (r) => {
        await fetchEvents(currentDates.start, currentDates.end, id);
        triggerSnackbar(r.data, "success");
      })
      .catch((err) => {
        triggerSnackbar(err.response.data.message, "error");
      });
    updateKey((prevKey) => prevKey + 1);
  };

  const handleRequestChange = async (id, userCurrentDates, updateKey) => {
    await CalendarService.requestChange(id)
      .then(async (r) => {
        await fetchUserEvents(userCurrentDates.start, userCurrentDates.end);
        await fetchUserReqEvents(userCurrentDates.start, userCurrentDates.end);
        triggerSnackbar(r.data, "info");
      })
      .catch((error) => {
        console.log(error);
      });
    updateKey((prevKey) => prevKey + 1);
  };

  const handleRequestCancel = async (id, updateKey) => {
    await CalendarService.requestCancel(id)
      .then(async (r) => {
        await fetchUserReqEvents();
        triggerSnackbar(r.data, "info");
      })
      .catch((error) => {
        console.log(error);
        triggerSnackbar(error.response.data.message, "error");
      });
    updateKey((prevKey) => prevKey + 1);
  };

  const handleRequestAccept = async (id, updateKey) => {
    await CalendarService.requestAccept(id)
      .then(async (r) => {
        triggerSnackbar(r.data, "success");
      })
      .catch((error) => {
        console.log(error);
      });
    updateKey((prevKey) => prevKey + 1);
  };

  const handleRequestPartialAccept = async (event, updateKey) => {
    await CalendarService.requestPartialAccept(event)
      .then(async (r) => {
        triggerSnackbar(r.data, "success");
      })
      .catch((error) => {
        console.log(error);
      });
    updateKey((prevKey) => prevKey + 1);
  };

  const handleRequestPartial = async (event, updateKey) => {
    await CalendarService.requestPartial(event)
      .then(async (r) => {
        triggerSnackbar(r.data, "success");
      })
      .catch((error) => {
        console.log(error);
      });
    updateKey((prevKey) => prevKey + 1);
  };

  const handleRequestApprove = async (id, updateKey) => {
    await CalendarService.requestChangeAccept(id)
      .then(async (r) => {
        await fetchChangedEvents().then(() => {});
        triggerSnackbar(r.data, "info");
      })
      .catch((error) => {
        console.log(error);
      });
    updateKey((prevKey) => prevKey + 1);
  };

  const handleChangeCancel = async (id, updateKey) => {
    await CalendarService.handleChangeCancel(id)
      .then(async (r) => {
        await fetchChangedEvents();
        triggerSnackbar(r.data, "info");
      })
      .catch((error) => {
        console.log(error);
      });
    updateKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    setEvent(initialEvent);
  }, [initialEvent]);

  return {
    event,
    handleChange,
    handleAddEvent,
    handleEditEvent,
    handleDeleteEvent,
    handlePublishEvents,
    handleRequestChange,
    handleRequestCancel,
    handleRequestAccept,
    handleRequestPartialAccept,
    handleRequestPartial,
    handleRequestApprove,
    handleChangeCancel,
  };
};
