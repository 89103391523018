export const ColorOptions = [
  { name: "Médico 1", value: "#2e3e9b", category: "Turno 1" },
  { name: "Médico 2", value: "#8887e2" },
  { name: "Médico 1", value: "#307d36", category: "Turno 2" },
  { name: "Médico 2", value: "#7cb981" },
  { name: "Refuerzo 1", value: "#dd5050", category: "Refuerzos" },
  { name: "Refuerzo 2", value: "#7e7d89" },
  { name: "Refuerzo 3", value: "#edc950" },
];
export const specialtieOptions = {
  ANESTESIOLOGIA: "Anestesiología",
  CARDIOVASCULAR: "Cardiovascular",
  CXCARDIOVASCULAR: "Cirugía Cardiovascular",
  CIRUGIA: "Cirugía (otras)",
  CLINICA: "Clínica Médica",
  DERMATOLOGIA: "Dermatología",
  DXIMAGENES: "Diagnóstico por Imágenes",
  ENDOCRINOLOGIA: "Endocrinología",
  FISIATRIA: "Fisiatria",
  GASTROENTEROLOGIA: "Gastroenterología",
  GENETICA: "Genética Médica",
  GINECOLOGIA: "Ginecoobstetricia",
  HEMATOLOGIA: "Hematología",
  INFECTOLOGIA: "Infectología",
  GENERALISTA: "Medicina General",
  FAMILIA: "Medicina de Familia",
  NEFROLOGIA: "Nefrología",
  NEUMONOLOGIA: "Neumonología",
  NEUROLOGIA: "Neurología",
  NUTRICION: "Nutrición",
  OFTALMOLOGIA: "Oftalmología",
  ONCOLOGIA: "Oncología",
  ORL: "ORL",
  PEDIATRIA: "Pediatría",
  PSIQUIATRIA: "Psiquiatría",
  REUMATOLOGIA: "Reumatología",
  INTENSIVA: "Terapia Intensiva",
  TRAUMATOLOGIA: "Traumatología",
  UROLOGIA: "Urología",
};
export const professionOptions = {
  MEDIC: "Médico/a",
  NURSE: "Enfermero/a",
  //ADMINISTRATIVE: "Administrativo/a",
  //CLEANING: "Limpieza",
  PHARMACIST: "Farmaceutico/a",
};
export const genderOptions = {
  MALE: "Masculino",
  FEMALE: "Femenino",
  OTHER: "Otro",
};
export const statusOptions = {
  ACTIVE: "Activo",
  INACTIVE: "Inactivo",
};
export const initialForm = {
  name: "",
  lastname: "",
  email: "",
  password: "",
  dni: "",
  gender: "",
  specialtie: "",
  medicalRegistration: "",
  profession: "",
  department: "",
};
export const profileInitialForm = {
  name: "",
  lastname: "",
  medicalRegistration: "",
  dni: "",
  email: "",
  specialtie: "",
  startDate: undefined,
  profession: "",
  gender: "",
  phone: "",
  position: null,
};
export const updateUserInitialForm = {
  name: "",
  lastname: "",
  medicalRegistration: "",
  dni: "",
  status: "",
  email: "",
  specialtie: "",
  startDate: undefined,
  profession: "",
  gender: "",
  phone: "",
  departments: [],
  allDepartments: [],
};
export const fieldLabels = {
  name: "Nombre",
  lastname: "Apellido",
  email: "Email",
  dni: "DNI",
  phone: "Nro. teléfono",
  medicalRegistration: "Matrícula Médica",
};
////////////////////////////// AppBar //////////////////////////////
export const adminPages = ["Home", "Personal"];
export const contentPages = [
  "Protocolos",
  "Proc. Institucionales",
  "Videos",
  "Subir Contenido",
];
export const adminSettings = ["Perfil", "Cerrar Sesión"];
export const userPages = [
  "Home",
  "Protocolos",
  "Videos",
  "Proc. Institucionales",
  "Mis Guardias",
];
export const userSettings = ["Perfil", "Cerrar Sesión"];
////////////////////////////////////////////////////////////////////
//////////////////////////// Register //////////////////////////////
export const professionGroups = [
  // { name: "Administrativo/a", value: "ADMINISTRATIVE" },
  { name: "Contador/a", value: "ACCOUNTANT" },
  { name: "Enfermero/a", value: "NURSE" },
  { name: "Farmacéutico/a", value: "PHARMACIST" },
  { name: "Médico/a", value: "MEDIC" },
  { name: "Otra", value: "OTHER" },
];
export const departmentGroups = [
  { name: "Contaduría", value: "ACCOUNTING" },
  { name: "Farmacia", value: "PHARMACY" },
  { name: "Guardia", value: "URGENCY" },
  { name: "Uci", value: "UCI" },
  { name: "Uco", value: "UCO" },
  { name: "Uti", value: "UTI" },
  { name: "Gerencia", value: "MANAGEMENT" },
  { name: "Neonatología", value: "NEO" },
];
export const specialtieGroups = [
  { name: "Otra / Ninguna", value: "OTRA" },
  { name: "Anestesiología", value: "ANESTESIOLOGIA" },
  { name: "Cardiología", value: "CARDIOVASCULAR" },
  { name: "Cirugía Cardiovascular", value: "CXCARDIOVASCULAR" },
  { name: "Cirugía (otras)", value: "CIRUGIA" },
  { name: "Clínica Médica", value: "CLINICA" },
  { name: "Dermatología", value: "DERMATOLOGIA" },
  { name: "Diagnóstico por Imágenes", value: "DXIMAGENES" },
  { name: "Endocrinología", value: "ENDOCRINOLOGIA" },
  { name: "Fisiatria", value: "FISIATRIA" },
  { name: "Gastroenterología", value: "GASTROENTEROLOGIA" },
  { name: "Genética Médica", value: "GENETICA" },
  { name: "Ginecoobstetricia", value: "GINECOLOGIA" },
  { name: "Hematología", value: "HEMATOLOGIA" },
  { name: "Infectología", value: "INFECTOLOGIA" },
  { name: "Medicina General", value: "GENERALISTA" },
  { name: "Medicina de Familia", value: "FAMILIA" },
  { name: "Nefrología", value: "NEFROLOGIA" },
  { name: "Neumonología", value: "NEUMONOLOGIA" },
  { name: "Neurologia", value: "NEUROLOGIA" },
  { name: "Nutrición", value: "NUTRICION" },
  { name: "Oftalmología", value: "OFTALMOLOGIA" },
  { name: "Oncología", value: "ONCOLOGIA" },
  { name: "ORL", value: "ORL" },
  { name: "Pediatría", value: "PEDIATRIA" },
  { name: "Psiquiatría", value: "PSIQUIATRIA" },
  { name: "Reumatología", value: "REUMATOLOGIA" },
  { name: "Terapia Intensiva", value: "INTENSIVA" },
  { name: "Traumatología", value: "TRAUMATOLOGIA" },
  { name: "Urología", value: "UROLOGIA" },
];
export const genderGroups = [
  { name: "Femenino", value: "FEMALE" },
  { name: "Masculino", value: "MALE" },
  { name: "Otro", value: "OTHER" },
];
////////////////////////////////////////////////////////////////////
//////////////////////////// Admin Tables //////////////////////////////

////////////////////////////////////////////////////////////////////////
//////////////////////////// Home Assets //////////////////////////////
export const monthLabels = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];
export const departmentTitles = {
  MANAGER: "Administradores",
  PHARMACY: "Farmacia",
  URGENCY: "Guardia",
  MANAGEMENT: "Gerencia",
  UCO: "Uco",
  UCI: "Uci",
  UTI: "Uti",
  NEO: "Neo",
  ACCOUNTING: "Contaduría",
};
////////////////////////////////////////////////////////////////////////
//////////////////////////// Video Edit //////////////////////////////
export const groupOptions = {
  procedimiento: "Procedimiento Institucional",
  cardiovascular: "Cardiovascular",
  cirugia: "Cirugía",
  endocrinometabolico: "Endocrinometabolico",
  gastroenterologia: "Gastroenterología",
  generales: "Generales",
  ginecoobstetricia: "Ginecoobstetricia",
  homeostasis: "Homeostasis",
  infectologia: "Infectología",
  nefrologia: "Nefrología",
  neumonologia: "Neumonología",
  neurologia: "Neurología",
  otorrinolaringologia: "ORL",
  toxicologia: "Toxicología",
  traumatologia: "Traumatología",
  urologia: "Urología",
};

export const departmentOptions = {
  MANAGEMENT: "Gerencia",
  URGENCY: "Guardia",
  PHARMACY: "Farmacia",
  UCI: "Uci",
  UCO: "Uco",
  UTI: "Uti",
  NEO: "Neo",
};
////////////////////////////////////////////////////////////////////////
