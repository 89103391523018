// Here i am using subdomain to determine the backend url
// src/config.js
export const getSubdomain = () => {
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];
    return subdomain;
  };
  
  export const getBackendUrl = () => {
    const subdomain = getSubdomain();
    switch (subdomain) {
      case 'developer':
        return process.env.REACT_APP_BACKEND_URL_DEV;
      case 'cdc':
        return process.env.REACT_APP_BACKEND_URL_CDC;
      default:
        return process.env.REACT_APP_BACKEND_URL_DEFAULT;
    }
  };
  
