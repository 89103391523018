import {
  Box,
  Container,
  Grid,
  IconButton,
  Paper,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import UserService from "../../../services/axios/UserService";
import {
  paperStyle,
  TableTypography,
  TableTypographyLight,
} from "./../../../Styles";
import { Call, ContactEmergency, CopyAll, WhatsApp } from "@mui/icons-material";
import { useSnackBarContext } from "./../../../context/SnackBarContext";

const Info = () => {
  const { triggerSnackbar } = useSnackBarContext();
  const [info, setInfo] = useState([]);
  useEffect(() => {
    UserService.getInfo()
      .then((res) => {
        setInfo(res.data);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
        }
      });
  }, []);
  return (
    <Container>
      <Paper sx={{ ...paperStyle, mb: 1 }}>
        <TableTypography>
          Contacto <ContactEmergency sx={{ ml: 1 }} />
        </TableTypography>
      </Paper>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <Paper sx={paperStyle}>
            <TableTypography fontSize={"1.4rem"}>
              Clínica de Cuyo
            </TableTypography>
            <TableTypographyLight>Guardia (Interno 1405)</TableTypographyLight>
            <Tooltip
              size="small"
              sx={{ color: "#2b7cf4" }}
              href={`tel:4059000`}
            >
              <IconButton>
                <Call />
              </IconButton>
            </Tooltip>
          </Paper>
        </Grid>
        {info.length !== 0 ? (
          info.map((data) => (
            <Grid item xs={12} sm={4} key={data.id}>
              <Paper sx={paperStyle}>
                <TableTypographyLight color={"gray"}>
                  {data.position
                    ? data.position.toLowerCase()
                    : data.specialtie.toLowerCase()}
                </TableTypographyLight>
                <TableTypography
                  fontSize={"1.4rem"}
                >{`${data.name} ${data.lastname}`}</TableTypography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TableTypographyLight>{data.email}</TableTypographyLight>
                  <Tooltip title="copiar email">
                    <IconButton
                      onClick={() => {
                        navigator.clipboard.writeText(data.email);
                        triggerSnackbar("Email copiado!", "info");
                      }}
                    >
                      <CopyAll sx={{ color: "orange" }} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TableTypographyLight>WhatsApp</TableTypographyLight>
                  <Tooltip title="enviar mensaje">
                    <IconButton
                      href={`https://api.whatsapp.com/send?phone=${data.phone}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <WhatsApp sx={{ color: "green" }} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TableTypographyLight>Teléfono</TableTypographyLight>
                  <Tooltip title="llamar">
                    <IconButton
                      sx={{ color: "#2b7cf4" }}
                      href={`tel:${data.phone}`}
                    >
                      <Call />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Paper>
            </Grid>
          ))
        ) : (
          <Container sx={{ alignItems: "center", justifyContent: "center" }}>
            <TableTypographyLight>
              Aun no hay contactos para mostrar
            </TableTypographyLight>
          </Container>
        )}
      </Grid>
    </Container>
  );
};

export default Info;
