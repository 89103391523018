import React, { useState, useEffect } from "react";
import UserService from "../../../../services/axios/UserService.js";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import {
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
  Paper,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { Cancel, Update } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  fieldLabels,
  genderOptions,
  professionOptions,
  specialtieOptions,
  statusOptions,
  updateUserInitialForm,
} from "../../../../constants/Options.js";
import { TableTypography, paperStyle } from "../../../../Styles.js";
import LoadingMain from "../../../public/LoadingMain.js";
import { useSnackBarContext } from "../../../../context/SnackBarContext.js";

function UpdateUser() {
  const { id } = useParams();
  const { triggerSnackbar } = useSnackBarContext();
  const [user, setUser] = useState(updateUserInitialForm);

  const handleGoBack = () => {
    window.history.back();
  };

  useEffect(() => {
    UserService.getUserById(id).then((res) => {
      setUser(res.data);
    });
  }, [id]);

  if (user.profession === "") {
    return <LoadingMain />;
  }

  const updateUser = (e) => {
    e.preventDefault();

    UserService.updateUser(id, user)
      .then(() => {
        handleGoBack();
      })
      .catch((error) => {
        if (error.response) {
          triggerSnackbar(error.response.data.message, "error");
        } else {
          triggerSnackbar("Error del servidor", "warning");
        }
      });
  };

  const handleDateChange = (e) => {
    setUser((prevUser) => ({
      ...prevUser,
      startDate: e,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleCheckboxChange = (departmentId) => {
    setUser((prevState) => {
      const isDepartmentSelected = prevState.departments.some(
        (dep) => dep.id === departmentId
      );

      if (isDepartmentSelected) {
        return {
          ...prevState,
          departments: prevState.departments.filter(
            (dep) => dep.id !== departmentId
          ),
        };
      } else {
        const departmentToAdd = user.allDepartments.find(
          (dep) => dep.id === departmentId
        );
        return {
          ...prevState,
          departments: [...prevState.departments, departmentToAdd],
        };
      }
    });
  };

  return (
    <Container maxWidth="sm">
      <Paper sx={paperStyle}>
        <TableTypography>Edición de usuario</TableTypography>
      </Paper>
      <Paper sx={{ ...paperStyle, padding: 2, mt: 1 }}>
        <Grid container spacing={1.5} display={"flex"}>
          {Object.keys(fieldLabels).map((fieldName) => (
            <Grid item xs={12} sm={6} key={fieldName}>
              <TextField
                fullWidth
                label={fieldLabels[fieldName]}
                name={fieldName}
                InputProps={{
                  inputProps: {
                    type:
                      fieldName === "dni" ||
                      fieldName === "medicalRegistration" ||
                      fieldName === "phone"
                        ? "number"
                        : "text",
                  },
                }}
                value={user[fieldName]}
                onChange={handleChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderWidth: 1,
                    },
                  },
                }}
              />
            </Grid>
          ))}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Sexo</InputLabel>
              <Select
                name="gender"
                value={user.gender}
                onChange={handleChange}
                label="Sexo"
              >
                {Object.entries(genderOptions).map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Profesión</InputLabel>
              <Select
                name="profession"
                value={user.profession}
                onChange={handleChange}
                label="Profesion"
              >
                {Object.entries(professionOptions).map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <InputLabel>Especialidad</InputLabel>
              <Select
                name="specialtie"
                value={user.specialtie}
                onChange={handleChange}
                label="Especialidad"
              >
                {Object.entries(specialtieOptions).map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: "100%" }}
                label="Inicio de actividad"
                name="startDate"
                value={dayjs(user.startDate)}
                onChange={handleDateChange}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Estado</InputLabel>
              <Select
                name="status"
                value={user.status}
                onChange={handleChange}
                label="Estado"
              >
                {Object.entries(statusOptions).map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            mt={1}
            ml={2}
            container
            display={"flex"}
            flexDirection={"column"}
            textAlign={"left"}
          >
            <Grid item>
              <TableTypography fontSize={20}>Areas de trabajo:</TableTypography>
            </Grid>
            <Grid item display={"flex"} flexDirection={"column"}>
              {user.allDepartments.map((d) => (
                <FormControlLabel
                  sx={{ ml: 1 }}
                  control={
                    <Checkbox
                      checked={user.departments.some((dep) => dep.id === d.id)}
                      onChange={() => handleCheckboxChange(d.id)}
                    />
                  }
                  label={
                    d.title === "PHARMACY"
                      ? "Farmacia"
                      : d.title === "URGENCY"
                      ? "Guardia"
                      : d.title === "MANAGEMENT"
                      ? "Gerencia"
                      : d.title
                  }
                  key={d.id}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid display={"flex"} mt={3}>
          <Button
            type="button"
            fullWidth
            variant="contained"
            disableElevation
            endIcon={<Update />}
            sx={{
              mr: 1,
            }}
            onClick={updateUser}
          >
            Actualizar
          </Button>
          <Button
            type="button"
            fullWidth
            variant="contained"
            disableElevation
            endIcon={<Cancel />}
            color="error"
            onClick={handleGoBack}
          >
            Cancelar
          </Button>
        </Grid>
      </Paper>
    </Container>
  );
}

export default UpdateUser;
