import { useState } from "react";
import { request, setAuthHeader } from "../services/axios/axios_helper.js";
import RestorePassword from "../services/axios/RestorePassword.js";
import { useSnackBarContext } from './../context/SnackBarContext';

export const useLogin = (form, handleDialogClose) => {
  const { triggerSnackbar } = useSnackBarContext();
  const onLogin = (e) => {
    e.preventDefault();
    if (form.email.trim() === "" || form.password.trim() === "") {
      triggerSnackbar("Debes completar todos los campos", "warning");
      return;
    }
    request("POST", "/login", {
      email: form.email,
      password: form.password,
    })
      .then((response) => {
        setAuthHeader(response.data.token);
        window.location.href = "/home";
      })
      .catch((err) => {
        setAuthHeader(null);
        triggerSnackbar(err?.response?.data? err.response.data.message : "Error al iniciar sesión, intente nuevamente en unos instantes!", "error");
      });
  };
  const onRestorePassword = () => {
    if (form.email.trim() === "") {
      triggerSnackbar("Debes completar todos los campos", "info");
      return;
    }
    RestorePassword.restorePassword(form.email)
      .then((response) => {
        triggerSnackbar(response.data, "success");
      })
      .catch((err) => {
        triggerSnackbar(err.response.data.message, "info");
      });
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return {
    onLogin,
    showPassword,
    handleClickShowPassword,
    handleMouseDownPassword,
    onRestorePassword,
  };
};
