import React, { useState } from "react";
import { Box, Container, Paper } from "@mui/material";
import { useGetEvents } from "../../../../hooks/useGetEvents.js";
import FullCalendar from "@fullcalendar/react";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es";
import EventChangedDialog from "../Dialogs/EventChangedDialog.js";
import {
  paperStyle,
  TableTypography,
  TableTypographyLight,
} from "../../../../Styles.js";
import { useParams } from "react-router-dom";
import { useIsMobile } from "../../../../hooks/useIsMobile.js";

function AdminRequestedChanges() {
  const isMobile = useIsMobile();
  const { id, title } = useParams();
  const [updateKey, setUpdateKey] = useState(0);
  const [changedCurrentDates, setChangedCurrentDates] = useState(null);
  const { changedEvents } = useGetEvents("", "", changedCurrentDates, id);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [infoOpen, setInfoOpen] = useState(false);
  const handleClose = () => {
    setInfoOpen(false);
  };
  const handleEventClick = (eventClickInfo) => {
    setSelectedEvent(eventClickInfo.event);
    setInfoOpen(true);
  };
  const handleDatesSet = (dateInfo) => {
    setChangedCurrentDates({
      start: dateInfo.startStr,
      end: dateInfo.endStr,
    });
  };
  const renderChangedEvents = () => {
    return changedEvents.map((event) => ({
      id: event.id,
      title: event.title,
      start: new Date(event.startDate),
      end: new Date(event.endDate),
      color: event.color,
      userId: event.userId,
      eventStatus: event.eventStatus,
      extendedProps: {
        customProperty:
          event.eventStatus === "REQUESTED"
            ? "⏳ Cambio solicitado"
            : "✅ Asignada",
      },
    }));
  };

  return (
    <Container>
      <Paper sx={paperStyle}>
        <TableTypographyLight fontSize={isMobile ? "1.4rem" : "2rem"}>
          Cambios solicitados
        </TableTypographyLight>
        <TableTypography>
          {title === "MANAGER"
            ? "Administradores"
            : title === "PHARMACY"
            ? "Farmacia"
            : title === "URGENCY"
            ? "Guardia"
            : title === "MANAGEMENT"
            ? "Gerencia"
            : title}
        </TableTypography>
        <FullCalendar
          key={updateKey}
          eventContent={(e) => {
            return (
              <Box>
                <Box>Cambio solicitado - Tomará guardia: {e.event.title}</Box>
              </Box>
            );
          }}
          height={600}
          headerToolbar={{
            right: "today prev next",
          }}
          plugins={[listPlugin, interactionPlugin]}
          locale={esLocale}
          views={{
            listMonth: { titleFormat: { month: "long", year: "numeric" } },
          }}
          initialView="listMonth"
          events={changedEvents ? renderChangedEvents() : null}
          selectable={true}
          eventClick={handleEventClick}
          datesSet={handleDatesSet}
        />
      </Paper>
      <EventChangedDialog
        open={infoOpen}
        handleClose={handleClose}
        selectedEvent={selectedEvent}
        updateKey={setUpdateKey}
      />
    </Container>
  );
}

export default AdminRequestedChanges;
