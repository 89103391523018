import React, { useState } from "react";
import {
  Avatar,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Pagination,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { KeyboardArrowRight, Search, YouTube } from "@mui/icons-material";
import { useVideoList } from "../../../../../hooks/useVideoList";
import LoadingMain from "../../../../public/LoadingMain";
import {
  TableTypography,
  TableTypographyLight,
  paperStyle,
} from "../../../../../Styles";
import { useIsMobile } from "../../../../../hooks/useIsMobile";
import { useNavigate } from "react-router-dom";

export default function VideoList() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { videoList, myDate } = useVideoList();
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const maxLength = 50;
  if (!videoList || videoList.length === 0) {
    return <LoadingMain />;
  }
  const videosPerPage = 6;
  const filteredVideos = videoList.filter(
    (video) =>
      video.title.toLowerCase().includes(searchText.toLowerCase()) ||
      video.group.toLowerCase().includes(searchText.toLowerCase())
  );
  const handleVideoView = (id) => {
    navigate(`/video/view/${id}`);
  };
  function generate() {
    const startIndex = (page - 1) * videosPerPage;
    const endIndex = startIndex + videosPerPage;
    const currentPageVideos = filteredVideos.slice(startIndex, endIndex);
    return currentPageVideos.map((video) => (
      <Paper sx={{ ...paperStyle, mb: 0.5, padding: 0 }} key={video.id}>
        <Stack direction="row" alignItems="center">
          <Tooltip href={`${video.link}`} target="_blank" title="Abrir">
            <IconButton
              sx={{
                "&:hover": {
                  backgroundColor: "#d76969",
                  color: "#dddddd",
                },
                color: "#d76969",
                backgroundColor: "#dddddd",
                ml: 1,
                mr: 2,
              }}
            >
              <YouTube />
            </IconButton>
          </Tooltip>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                textAlign: "left",
              }}
            >
              <TableTypographyLight>{video.group} </TableTypographyLight>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: "flex",
                justifyContent: "left",
                textAlign: "left",
              }}
            >
              <Typography color="#484848" fontSize={"1rem"}>
                {video.title.length > maxLength
                  ? `${video.title.substring(0, 50)} ...`
                  : video.title}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              sx={{
                display: { xs: "none", sm: "flex" },
                justifyContent: "left",
              }}
            >
              <TableTypographyLight>{myDate(video.date)}</TableTypographyLight>
            </Grid>
          </Grid>
          <Tooltip title="Abrir">
            <IconButton
              onClick={() => {
                handleVideoView(video.id);
              }}
            >
              <Avatar>
                <KeyboardArrowRight />
              </Avatar>
            </IconButton>
          </Tooltip>
        </Stack>
      </Paper>
    ));
  }

  return (
    <Container>
      <Grid container display={"flex"} flexDirection={"column"} flexGrow={1}>
        <Grid item>
          <Paper
            sx={{
              ...paperStyle,
              mb: 1,
            }}
          >
            <Grid
              container
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              marginInline={!isMobile ? 5 : 0}
            >
              <Grid item sm={6} display={isMobile && "none"}>
                <TableTypography>Videos</TableTypography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Buscar en videos..."
                  variant="outlined"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  sx={{ maxWidth: "20rem" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search color="#747474" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        ></Grid>
        <Grid item sx={{ display: { xs: "none", sm: "block" } }}>
          <Paper
            sx={{
              ...paperStyle,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 0.5,
            }}
          >
            <Grid item xs={12}>
              <TableTypography fontSize={"1.2rem"}>Grupo</TableTypography>
            </Grid>
            <Grid item xs={12}>
              <TableTypography fontSize={"1.2rem"}>Título</TableTypography>
            </Grid>
            <Grid item xs={12}>
              <TableTypography fontSize={"1.2rem"}>Publicación</TableTypography>
            </Grid>
          </Paper>
        </Grid>
        <Grid item>{generate()}</Grid>
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "end",
            flexGrow: 1,
            mt: 2,
          }}
        >
          <Pagination
            count={Math.ceil(filteredVideos.length / videosPerPage)}
            page={page}
            onChange={(event, value) => {
              setPage(value);
            }}
            color="primary"
            variant="text"
          />
        </Grid>
      </Grid>
    </Container>
  );
}
