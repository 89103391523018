import axios from "./axios_instance";
import { getAuthToken } from "./axios_helper";
import { getBackendUrl } from "../../config";

const baseURL = getBackendUrl() + "/stats";
const token = getAuthToken();
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

class StatsService {
  getUserDoughnut() {
    return axios.get(baseURL + "/get-doughnut", config);
  }
  getUserBar() {
    return axios.get(baseURL + "/get-user-bar", config);
  }
  getAccountantBar(id) {
    return axios.get(baseURL + "/get-accountant-bar/" + id, config);
  }
  getWhoIsNow() {
    return axios.get(baseURL + "/get-whoisnow", config);
  }
  getManagerBar(option) {
    return axios.get(baseURL + "/get-manager-bar/" + option, config);
  }
  getManagerDoughnut(option) {
    return axios.get(baseURL + "/get-manager-doughnut/" + option, config);
  }
}
const instance = new StatsService();
export default instance;
