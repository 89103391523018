import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ThemeProvider,
} from "@mui/material";
import { useAddEvents } from "../../../../hooks/useAddEvents";
import {
  customTheme,
  TableTypography,
  TableTypographyLight,
} from "../../../../Styles";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { Info } from "@mui/icons-material";

function myDate(date) {
  const myDate = new Date(date);
  const day = myDate.getDate();
  const month = myDate.getMonth() + 1;
  const year = myDate.getFullYear();
  const hours = myDate.getHours();
  const minutes = myDate.getMinutes();

  const formattedDay = day < 10 ? `0${day}` : `${day}`;
  const formattedMonth = month < 10 ? `0${month}` : `${month}`;
  const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

  const formattedDate = `${formattedDay}/${formattedMonth}/${year} ${formattedHours}:${formattedMinutes}`;
  return formattedDate;
}

const EventChangedDialog = ({
  open,
  handleClose,
  selectedEvent,
  updateKey,
}) => {
  const isMobile = useIsMobile();
  const { handleRequestApprove, handleChangeCancel } = useAddEvents();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const handleChangeOpen = () => {
    setConfirmOpen(true);
  };
  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };
  const handleCancelOpen = () => {
    setCancelOpen(true);
  };
  const handleCancelClose = () => {
    setCancelOpen(false);
  };

  return (
    <ThemeProvider theme={customTheme}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <TableTypography>
            <Info sx={{ mr: 1 }} />
            Información
          </TableTypography>
        </DialogTitle>
        <DialogContent
          sx={{
            minWidth: isMobile ? "100%" : 400,
          }}
        >
          {selectedEvent ? (
            <>
              <TableTypographyLight>Tomará guardia:</TableTypographyLight>
              <TableTypographyLight fontSize={"1.4rem"} color={"#137136"}>
                {selectedEvent.title}
              </TableTypographyLight>
              <Divider sx={{ mt: 1, mb: 1 }} />
              <TableTypographyLight>
                Desde: {myDate(selectedEvent.startStr)} hs
              </TableTypographyLight>
              <TableTypographyLight>
                Hasta: {myDate(selectedEvent.endStr)} hs
              </TableTypographyLight>
            </>
          ) : (
            <CircularProgress color="success" />
          )}
        </DialogContent>
        <Box
          sx={{
            textAlign: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <DialogActions>
            <Button onClick={handleClose} color="error">
              cerrar
            </Button>
            <Button color="error" onClick={handleCancelOpen}>
              Cancelar Cambio
            </Button>{" "}
            <Button onClick={handleChangeOpen}>Aceptar Cambio</Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog open={confirmOpen} onClose={handleChangeOpen}>
        <DialogTitle>
          <TableTypography>¡Atención!</TableTypography>
        </DialogTitle>
        <DialogContent
          sx={{ maxWidth: isMobile ? "100%" : 400, minHeight: 100 }}
        >
          <TableTypographyLight>
            Esta acción validará el cambio de guardia aceptado por{" "}
            {selectedEvent ? selectedEvent.title : ""}.
          </TableTypographyLight>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <TableTypographyLight>Desea continuar?</TableTypographyLight>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="error">
            cerrar
          </Button>
          <Button
            onClick={() => {
              handleRequestApprove(selectedEvent.id, updateKey);
              handleConfirmClose();
              handleClose();
            }}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={cancelOpen} onClose={handleCancelOpen}>
        <DialogTitle>
          <TableTypography>¡Atención!</TableTypography>
        </DialogTitle>
        <DialogContent
          sx={{ maxWidth: isMobile ? "100%" : 400, minHeight: 100 }}
        >
          <TableTypographyLight>
            Esta acción cancelará el cambio de guardia solicitado por{" "}
            {selectedEvent ? selectedEvent.title : ""}, y reasignará dicha
            guardia al usuario original.
          </TableTypographyLight>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <TableTypographyLight>Desea continuar?</TableTypographyLight>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClose} color="error">
            cerrar
          </Button>
          <Button
            onClick={() => {
              handleChangeCancel(selectedEvent.id, updateKey);
              handleCancelClose();
              handleClose();
            }}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default EventChangedDialog;
