import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UseUser } from "../hooks/useUser.js"
import LoadingPage from "../components/public/LoadingPage.js"; 

const AuthHandler = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState("");
  const location = useLocation();

  useEffect(() => {
    const fetchRole = async () => {
      if (!['/landing'].includes(location.pathname) && !location.pathname.startsWith('/reset-password/')) {
        const user = await UseUser();
        setUser(user);
      }
      setIsLoading(false);
    };
  
    fetchRole();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }

  return children(user);
};

export default AuthHandler;