import React, { createContext, useContext, useState } from "react";
import { Snackbar, Alert } from "@mui/material";

const SnackBarContext = createContext();

export const useSnackBarContext = () => useContext(SnackBarContext);

export const SnackBarProvider = ({ children }) => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");

  const handleCloseSnackbar = () => setShowSnackbar(false);

  const triggerSnackbar = (message, severity = "success") => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setShowSnackbar(true);
  };

  return (
    <SnackBarContext.Provider value={{ triggerSnackbar }}>
      {children}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          variant="filled"
          sx={{
            fontFamily: "Figtree",
            width: "100%",
            borderRadius: 5,
            fontWeight: "400",
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </SnackBarContext.Provider>
  );
};
