import React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import MailForm from "./MailForm";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { Mail } from "@mui/icons-material";

const MailDialog = ({ userId, userName, open, onClose }) => {
  const isMobile = useIsMobile();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
        Nuevo mensaje
        <Mail sx={{ ml: 1 }} />
      </DialogTitle>
      <DialogContent sx={{ minWidth: isMobile ? 300 : 500 }}>
        <DialogContentText
          ml={0.5}
        >{`De: Administración`}</DialogContentText>
        <DialogContentText
          ml={0.5}
          mb={3}
        >{`Para: ${userName}`}</DialogContentText>
        <MailForm userId={userId} onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
};

export default MailDialog;
