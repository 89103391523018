import { useEffect } from "react";
import { useState } from "react";
import RestorePassword from "./../services/axios/RestorePassword.js";
import { useNavigate } from "react-router-dom";
import { useSnackBarContext } from "../context/SnackBarContext.js";

const useResetPassword = (form, token) => {
  const { triggerSnackbar } = useSnackBarContext();
  const navigate = useNavigate();
  const onResetPassword = async (event) => {
    event.preventDefault();
    if (form.password.trim() === "") {
      triggerSnackbar("La contraseña no puede estar vacía!", "info");
      return;
    }
    await RestorePassword.resetPassword(token, form)
      .then((res) => {
        triggerSnackbar(res.data, "success");
        navigate("/landing");
      })
      .catch((err) => {
        triggerSnackbar(err.response.data.message, "info");
      });
  };
  ///////////////////////////////////////////////////////////////////////////////
  // handle password confirmation ////////////////////////////////////////////////
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [areEquals, setAreEqual] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  const handlePasswordConfirmation = (e) => {
    setPasswordConfirmation(e.target.value);
  };

  useEffect(() => {
    const { password } = form;
    setAreEqual(password === passwordConfirmation);
  }, [passwordConfirmation, form]);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };
  ///////////////////////////////////////////////////////////////////////////////
  return {
    onResetPassword,
    areEquals,
    handlePasswordConfirmation,
    showPassword,
    handleClickShowPassword,
    handleMouseDownPassword,
    showPassword2,
    handleClickShowPassword2,
    handleMouseDownPassword2,
  };
};

export default useResetPassword;
