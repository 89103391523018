import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Tooltip,
} from "@mui/material";
import { Button } from "@mui/material";
import {
  AdminPanelSettings,
  Check,
  Close,
  Delete,
  Edit,
  Email,
  ExpandMore,
  Label,
  MonetizationOn,
} from "@mui/icons-material";
import { useUserList } from "../../../../hooks/useUserList";
import LoadingMain from "../../../public/LoadingMain";
import {
  accordionStyle,
  paperStyle,
  tableStyle,
  TableTypography,
  TableTypographyLight,
  tooltipAdmin,
} from "../../../../Styles";
import { esESGrid } from "../../../../constants/esESGrid";
import { tooltipDelete } from "../../../../Styles";
import { useState } from "react";
import { useEffect } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import PersonalBin from "./PersonalBin";
import ManagersTable from "./ManagersTable";
import AdminsTable from "./AdminsTable";
import { departmentTitles } from "../../../../constants/Options";
import MailDialog from '../Mail/MailDialog';

export function PersonalTable({ userRole }) {
  const [tooltipVisibility, setTooltipVisibility] = useState({});
  const [departmentList, setDepartmentList] = useState([]);
  const [userId, setUserId] = useState("");
  const [toManagerDialogOpen, setToManagerDialogOpen] = useState(false);
  const [toAccountantDialogOpen, setToAccountantDialogOpen] = useState(false);
  const [toUserDialogOpen, setToUserDialogOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [userName, setUserName] = useState("");
  const {
    deleteDialogOpen,
    fetchDepartmentList,
    editUser,
    handleCloseDeleteDialog,
    handleEraseConfirmed,
    deleteUser,
    handleGetTable,
    toManager,
    toAccountant,
    activateUser,
    inactivateUser,
    toUser,
  } = useUserList();
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  const handleToManagerOpen = (id) => {
    setToManagerDialogOpen(true);
    setUserId(id);
  };
  const handleToAccountant = (id) => {
    setToAccountantDialogOpen(true);
    setUserId(id);
  };
  const handleToUser = (id) => {
    setToUserDialogOpen(true);
    setUserId(id);
  };
  const handleToManagerClose = () => {
    setToManagerDialogOpen(false);
  };
  const handleToAccountantClose = () => {
    setToAccountantDialogOpen(false);
  };
  const handleToUserClose = () => {
    setToUserDialogOpen(false);
  };
  useEffect(() => {
    fetchDepartmentList(setDepartmentList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!departmentList || departmentList.length === 0) {
    return <LoadingMain />;
  }
  return (
    <Container maxWidth={"xl"}>
      <Grid container>
        <Grid item xs={12}>
          <Paper sx={{ ...paperStyle, mb: 2 }}>
            <TableTypography>Personal</TableTypography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {departmentList &&
            departmentList.map((table, index) => (
              <Accordion
                key={table.id}
                onChange={(event, isExpanded) => {
                  if (isExpanded) {
                    handleGetTable(
                      table.title,
                      departmentList,
                      setDepartmentList
                    );
                  }
                }}
                sx={accordionStyle}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <TableTypography fontSize={"1.2rem"}>
                    <Label sx={{ mr: 1, color: "#137136" }} />
                    {departmentTitles[table.title] || table.title}
                  </TableTypography>
                </AccordionSummary>
                <AccordionDetails>
                  {table.users === null ? (
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress color="primary" />
                    </Box>
                  ) : table.users.length !== 0 ? (
                    <Box>
                      <DataGrid
                        sx={tableStyle}
                        rows={table.users ? table.users : []}
                        columns={[
                          {
                            field: "name",
                            headerName: "Nombre",
                            flex: 1,
                            editable: false,
                            align: "center",
                            headerAlign: "center",
                          },
                          {
                            field: "lastname",
                            headerName: "Apellido",
                            flex: 1,
                            editable: false,
                            align: "center",
                            headerAlign: "center",
                          },
                          {
                            field: "email",
                            headerName: "Email",
                            flex: 1.5,
                            editable: false,
                            align: "center",
                            headerAlign: "center",
                          },
                          {
                            field: "dni",
                            headerName: "DNI",
                            typeof: "number",
                            flex: 1,
                            editable: false,
                            align: "center",
                            headerAlign: "center",
                          },
                          {
                            field: "specialtie",
                            headerName: "Especialidad",
                            flex: 1,
                            editable: false,
                            align: "center",
                            headerAlign: "center",
                          },
                          {
                            field: "medicalRegistration",
                            headerName: "Matrícula",
                            typeof: "number",
                            flex: 1,
                            editable: false,
                            align: "center",
                            headerAlign: "center",
                          },
                          {
                            field: "startDate",
                            headerName: "Inicio labores",
                            flex: 1,
                            editable: false,
                            align: "center",
                            headerAlign: "center",
                          },
                          {
                            field: "actions",
                            headerName: "Acciones",
                            flex: 2,
                            editable: false,
                            align: "center",
                            headerAlign: "center",
                            renderCell: (params) => (
                              <Box>
                                <Tooltip
                                  size="small"
                                  title={
                                    params.row.status === "ACTIVE"
                                      ? "Dar de baja usuario"
                                      : "Activar usuario"
                                  }
                                  onMouseEnter={() =>
                                    setTooltipVisibility({
                                      ...tooltipVisibility,
                                      [params.id]: true,
                                    })
                                  }
                                  onMouseLeave={() =>
                                    setTooltipVisibility({
                                      ...tooltipVisibility,
                                      [params.id]: false,
                                    })
                                  }
                                  onClick={() => {
                                    if (params.row.status === "ACTIVE") {
                                      inactivateUser(
                                        "",
                                        params.id,
                                        table.title,
                                        departmentList,
                                        setDepartmentList
                                      );
                                    } else if (
                                      params.row.status === "INACTIVE"
                                    ) {
                                      activateUser(
                                        "",
                                        params.id,
                                        table.title,
                                        departmentList,
                                        setDepartmentList
                                      );
                                    }
                                  }}
                                >
                                  <IconButton
                                    sx={{
                                      backgroundColor:
                                        params.row.status === "ACTIVE"
                                          ? "green"
                                          : params.row.status === "INACTIVE"
                                          ? "#f75757"
                                          : "",
                                      "&:hover": {
                                        backgroundColor:
                                          tooltipVisibility &&
                                          params.row.status === "ACTIVE"
                                            ? "#cd5d55"
                                            : tooltipVisibility &&
                                              params.row.status === "INACTIVE"
                                            ? "green"
                                            : "",
                                      },
                                      mr: 1,
                                    }}
                                  >
                                    {!tooltipVisibility &&
                                    params.row.status === "ACTIVE" ? (
                                      <Close />
                                    ) : !tooltipVisibility &&
                                      params.row.status === "INACTIVE" ? (
                                      <Check />
                                    ) : params.row.status === "INACTIVE" ? (
                                      <Close />
                                    ) : (
                                      <Check />
                                    )}
                                  </IconButton>
                                </Tooltip>
                                {table.title !== "ACCOUNTING" &&
                                  (userRole === "ADMIN" ||
                                    userRole === "SUPERADMIN") && (
                                    <Tooltip
                                      size="small"
                                      title={`Dar permisos de MANAGER a ${params.row.name} ${params.row.lastname}`}
                                      onClick={() =>
                                        handleToManagerOpen(params.id)
                                      }
                                    >
                                      <IconButton sx={tooltipAdmin}>
                                        <AdminPanelSettings />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                {table.title === "ACCOUNTING" &&
                                  (userRole === "ADMIN" ||
                                    userRole === "SUPERADMIN") && (
                                    <Tooltip
                                      size="small"
                                      title={
                                        params.row.role !== "ACCOUNTANT"
                                          ? `Dar permisos de CONTADURIA a ${params.row.name} ${params.row.lastname}`
                                          : `QUITAR permisos de CONTADURIA a ${params.row.name} ${params.row.lastname}`
                                      }
                                      onClick={() =>
                                        params.row.role !== "ACCOUNTANT"
                                          ? handleToAccountant(params.id)
                                          : handleToUser(params.id)
                                      }
                                    >
                                      <IconButton
                                        sx={{
                                          backgroundColor:
                                            params.row.role === "ACCOUNTANT"
                                              ? "green"
                                              : "#cd5d55",
                                          "&:hover": {
                                            backgroundColor:
                                              params.row.role === "ACCOUNTANT"
                                                ? "#cd5d55"
                                                : "green",
                                          },
                                          mr: 1,
                                        }}
                                      >
                                        <MonetizationOn />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                <Tooltip
                                  size="small"
                                  title={`Enviar email a ${params.row.name} ${params.row.lastname}`}
                                  onClick={() => {
                                    setIsDialogOpen(true);
                                    setSelectedUserId(params.id);
                                    setUserName(
                                      `${params.row.name} ${params.row.lastname} <${params.row.email}>`
                                    );
                                  }}
                                >
                                  <IconButton sx={tooltipAdmin}>
                                    <Email />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip
                                  size="small"
                                  title="Editar usuario"
                                  onClick={() => editUser(params.id)}
                                >
                                  <IconButton sx={tooltipAdmin}>
                                    <Edit />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip
                                  size="small"
                                  title="Enviar a la papelera"
                                  onClick={() => deleteUser(params.id)}
                                >
                                  <IconButton sx={tooltipDelete}>
                                    <Delete />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            ),
                          },
                        ]}
                        initialState={{
                          pagination: { paginationModel: { pageSize: 10 } },
                          filter: {
                            filterModel: {
                              items: [],
                              quickFilterValues: [""],
                            },
                          },
                        }}
                        disableDensitySelector
                        disableRowSelectionOnClick
                        disableColumnMenu
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                          },
                        }}
                        pageSizeOptions={[5, 10, 20, 30]}
                        localeText={esESGrid}
                      />
                      <Dialog
                        open={deleteDialogOpen}
                        onClose={handleCloseDeleteDialog}
                        style={{ textAlign: "center" }}
                      >
                        <DialogTitle>Atención!</DialogTitle>
                        <DialogContent>
                          Seguro/a desea enviar a la papelera el usuario
                          seleccionado?
                        </DialogContent>
                        <DialogActions style={{ justifyContent: "center" }}>
                          <Button
                            onClick={handleCloseDeleteDialog}
                            color="primary"
                          >
                            Cancelar
                          </Button>
                          <Button
                            onClick={() => {
                              handleEraseConfirmed(
                                table.title,
                                departmentList,
                                setDepartmentList
                              );
                            }}
                            color="error"
                          >
                            Borrar
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <Dialog
                        open={toManagerDialogOpen}
                        onClose={handleToManagerClose}
                        style={{ textAlign: "center" }}
                      >
                        <DialogTitle>Atención!</DialogTitle>
                        <DialogContent>
                          Seguro/a desea dar permisos de MANAGER al usuario
                          seleccionado?
                        </DialogContent>
                        <DialogActions style={{ justifyContent: "center" }}>
                          <Button
                            onClick={handleToManagerClose}
                            color="primary"
                          >
                            Cancelar
                          </Button>
                          <Button
                            onClick={async () => {
                              handleToManagerClose();
                              await toManager(
                                userId,
                                table.title,
                                departmentList,
                                setDepartmentList
                              );
                            }}
                            color="error"
                          >
                            Otorgar
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <Dialog
                        open={toAccountantDialogOpen}
                        onClose={handleToAccountantClose}
                        style={{ textAlign: "center" }}
                      >
                        <DialogTitle>Atención!</DialogTitle>
                        <DialogContent>
                          Esta acción brindará permisos para acceder al panel
                          del contaduría al usuario seleccionado.
                        </DialogContent>
                        <DialogContent>Desea otorgarlos?</DialogContent>
                        <DialogActions style={{ justifyContent: "center" }}>
                          <Button
                            onClick={handleToAccountantClose}
                            color="primary"
                          >
                            Cancelar
                          </Button>
                          <Button
                            onClick={async () => {
                              handleToAccountantClose();
                              await toAccountant(
                                userId,
                                table.title,
                                departmentList,
                                setDepartmentList
                              );
                            }}
                            color="error"
                          >
                            Otorgar
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <Dialog
                        open={toUserDialogOpen}
                        onClose={handleToUserClose}
                        style={{ textAlign: "center" }}
                      >
                        <DialogTitle>Atención!</DialogTitle>
                        <DialogContent>
                          Esta acción quitará permisos para acceder al panel
                          del contaduría al usuario seleccionado.
                        </DialogContent>
                        <DialogActions style={{ justifyContent: "center" }}>
                          <Button onClick={handleToUserClose} color="primary">
                            Cancelar
                          </Button>
                          <Button
                            onClick={async () => {
                              handleToUserClose();
                              await toUser(userId);
                            }}
                            color="error"
                          >
                            Quitar permisos
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Box>
                  ) : (
                    <TableTypographyLight>
                      departamento {table.title} sin usuarios registrados
                    </TableTypographyLight>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          {isDialogOpen && (
            <MailDialog
              userId={selectedUserId}
              userName={userName}
              open={isDialogOpen}
              onClose={handleCloseDialog}
            />
          )}
          {(userRole === "ADMIN" || userRole === "SUPERADMIN") && (
            <ManagersTable userRole={userRole} />
          )}
          {userRole === "SUPERADMIN" && <AdminsTable userRole={userRole} />}
          <PersonalBin />
        </Grid>
      </Grid>
    </Container>
  );
}
