import * as React from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { PersonalTable } from './PersonalTable';
import { VideoTable } from './../../Content/Video/Admin/VideoTable';
import { ProcedureTable } from "../../Content/Document/Admin/ProcedureTable";
import { ProtocolTable } from './../../Content/Document/Admin/ProtocolTable';


export default function Dashboard({userRole}) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const table = queryParams.get("table"); 

  return (
    <Box>
      {table === "personal" ? (
        <PersonalTable userRole={userRole}/>
      ) : table === "protocol" ? (
        <ProtocolTable userRole={userRole}/>
      ) : table === "video" ? (
        <VideoTable/>
      ) : table === "procedure" ? (
        <ProcedureTable userRole={userRole}/>
      ) : null}
    </Box>
  );
}
