import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Tooltip,
} from "@mui/material";
import {
  CalendarMonth,
  Email,
  ExpandMore,
  Label,
  RequestQuote,
  Visibility,
} from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import { useUserList } from "../../../hooks/useUserList";
import LoadingMain from "../../public/LoadingMain";
import { departmentTitles } from "../../../constants/Options";
import { esESGrid } from "../../../constants/esESGrid";
import {
  tooltipAdmin,
  accordionStyle,
  paperStyle,
  tableStyle,
  TableTypography,
  TableTypographyLight,
} from "../../../Styles";
import FacMailDialog from "../PersonnelManagement/Mail/FactMailDialog";
import ShowUser from "./ShowUser";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import esLocale from "@fullcalendar/core/locales/es";
import { useIsMobile } from "../../../hooks/useIsMobile";
import CalendarService from "../../../services/axios/CalendarService";

const UserCalendar = ({ open, handleClose, id, userName }) => {
  const isMobile = useIsMobile();
  const [userCurrentDates, setUserCurrentDates] = useState(null);
  const [userEvents, setUserEvents] = useState([]);
  const handleDatesSet = (dateInfo) => {
    setUserCurrentDates({
      start: dateInfo.startStr,
      end: dateInfo.endStr,
    });
  };
  const fetchAccountantEvents = async (start, end, id) => {
    try {
      const response = await CalendarService.getAccountantEvents(start, end, id);
      setUserEvents(response.data);
    } catch (error) {
      console.error("Error fetching user events:", error);
    }
  };
  useEffect(() => {
    if (userCurrentDates) {
      const start = userCurrentDates.start;
      const end = userCurrentDates.end;
      fetchAccountantEvents(start, end, id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCurrentDates]);
  const renderUserEvents = () => {
    return userEvents.map((event) => ({
      id: event.id,
      title: event.title,
      start: new Date(event.startDate),
      end: new Date(event.endDate),
      color: event.color,
      userId: event.userId,
      eventStatus: event.eventStatus,
      extendedProps: {
        customProperty:
          event.eventStatus === "REQUESTED"
            ? "⏳ Cambio solicitado"
            : "✅ Asignada",
      },
    }));
  };
  return (
    <Dialog fullWidth maxWidth={"xl"} open={open} onClose={handleClose}>
      <DialogTitle>
        <Paper sx={paperStyle}>
          <TableTypography>
            <CalendarMonth sx={{ mr: 1 }} />
            Guardias:&nbsp;{" "}
            <TableTypographyLight fontSize={"2rem"}>
              {userName}
            </TableTypographyLight>
          </TableTypography>
        </Paper>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Paper sx={{ ...paperStyle, padding: 3 }}>
              <FullCalendar
                headerToolbar={{
                  right: "prev today next",
                }}
                plugins={[interactionPlugin, dayGridPlugin]}
                locale={esLocale}
                views={{
                  listMonth: {
                    titleFormat: { month: "long", year: "numeric" },
                  },
                }}
                initialView="dayGridMonth"
                events={userEvents ? renderUserEvents() : null}
                datesSet={handleDatesSet}
                displayEventEnd={true}
                eventDisplay={"block"}
                height={isMobile ? "auto" : 660}
              />
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export function Tables({ userRole }) {
  const [departmentList, setDepartmentList] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userHours, setUserHours] = useState(0);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const handleDialogClose = () => {
    setOpen(false);
  };
  const { fetchDepartmentList, handleGetAccountantTables } = useUserList();
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  const [openCalendar, setOpenCalendar] = useState(false);
  const [calendarId, setCalendarId] = useState(null);
  useEffect(() => {
    fetchDepartmentList(setDepartmentList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!departmentList || departmentList.length === 0) {
    return <LoadingMain />;
  }
  const monthNames = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const currentDate = new Date();
  const previousMonthIndex = (currentDate.getMonth() - 1 + 12) % 12;
  const previousMonthName = monthNames[previousMonthIndex];

  return (
    <Grid xs={12} sm={12} item container spacing={1}>
      <Grid item xs={12}>
        <Paper sx={paperStyle}>
          <TableTypography>Personal</TableTypography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        {departmentList &&
          departmentList.map((table, index) => (
            <Accordion
              key={table.id}
              onChange={(event, isExpanded) => {
                if (isExpanded) {
                  handleGetAccountantTables(
                    table.title,
                    departmentList,
                    setDepartmentList
                  );
                }
              }}
              sx={accordionStyle}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <TableTypography fontSize={"1.4rem"}>
                  <Label sx={{ mr: 1, color: "#137136" }} />
                  {departmentTitles[table.title] || table.title}
                </TableTypography>
              </AccordionSummary>
              <AccordionDetails>
                {table.users === null ? (
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress color="primary" />
                  </Box>
                ) : table.users.length !== 0 ? (
                  <Box>
                    <DataGrid
                      sx={tableStyle}
                      rows={table.users ? table.users : []}
                      columns={[
                        {
                          field: "name",
                          headerName: "Nombre",
                          flex: 1.5,
                          editable: false,
                          align: "center",
                          headerAlign: "center",
                        },
                        {
                          field: "lastname",
                          headerName: "Apellido",
                          flex: 1.5,
                          editable: false,
                          align: "center",
                          headerAlign: "center",
                        },
                        {
                          field: "email",
                          headerName: "Email",
                          flex: 2,
                          editable: false,
                          align: "center",
                          headerAlign: "center",
                        },
                        {
                          field: "dni",
                          headerName: "DNI",
                          typeof: "number",
                          flex: 1,
                          editable: false,
                          align: "center",
                          headerAlign: "center",
                        },
                        {
                          field: "medicalRegistration",
                          headerName: "Matrícula",
                          typeof: "number",
                          flex: 1,
                          editable: false,
                          align: "center",
                          headerAlign: "center",
                        },
                        {
                          field: "hoursDone",
                          headerName: `Horas ${previousMonthName}`,
                          typeof: "number",
                          flex: 1,
                          editable: false,
                          align: "center",
                          headerAlign: "center",
                        },
                        {
                          field: "actions",
                          headerName: "Acciones",
                          flex: 1.5,
                          editable: false,
                          align: "center",
                          headerAlign: "center",
                          renderCell: (params) => (
                            <Box>
                              <Tooltip
                                size="small"
                                title={`Enviar facturación a ${params.row.name} ${params.row.lastname}`}
                                onClick={() => {
                                  setIsDialogOpen(true);
                                  setSelectedUserId(params.id);
                                  setUserName(
                                    `${params.row.name} ${params.row.lastname}`
                                  );
                                  setUserEmail(`<${params.row.email}>`);
                                  setUserHours(params.row.hoursDone);
                                }}
                              >
                                <IconButton sx={tooltipAdmin}>
                                  <Email />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                size="small"
                                title={`Enviar facturación a ${params.row.name} ${params.row.lastname}`}
                                onClick={() => {
                                  setIsDialogOpen(true);
                                  setSelectedUserId(params.id);
                                  setUserName(
                                    `${params.row.name} ${params.row.lastname}`
                                  );
                                  setUserEmail(`<${params.row.email}>`);
                                  setUserHours(params.row.hoursDone);
                                }}
                              >
                                <IconButton sx={tooltipAdmin}>
                                  <RequestQuote />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                size="small"
                                title={`Mostrar mas datos`}
                                onClick={() => {
                                  setUserId(params.id);
                                  setOpen(true);
                                }}
                              >
                                <IconButton sx={tooltipAdmin}>
                                  <Visibility />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title={`Abrir calendario de ${params.row.name} ${params.row.lastname}`}
                              >
                                <IconButton
                                  onClick={() => {
                                    setCalendarId(params.row.id);
                                    setUserName(
                                      `${params.row.name} ${params.row.lastname}`
                                    );
                                    setOpenCalendar(true);
                                  }}
                                >
                                  <CalendarMonth />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          ),
                        },
                      ]}
                      initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                        filter: {
                          filterModel: {
                            items: [],
                            quickFilterValues: [""],
                          },
                        },
                      }}
                      disableDensitySelector
                      disableRowSelectionOnClick
                      disableColumnMenu
                      slots={{ toolbar: GridToolbar }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                          quickFilterProps: { debounceMs: 500 },
                        },
                      }}
                      pageSizeOptions={[5, 10, 20, 30]}
                      localeText={esESGrid}
                    />
                  </Box>
                ) : (
                  <TableTypographyLight>
                    departamento {table.title} sin usuarios registrados
                  </TableTypographyLight>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        {isDialogOpen && (
          <FacMailDialog
            userId={selectedUserId}
            userName={userName}
            userEmail={userEmail}
            userHours={userHours}
            open={isDialogOpen}
            onClose={handleCloseDialog}
          />
        )}
      </Grid>
      <ShowUser open={open} handleClose={handleDialogClose} userId={userId} />
      <UserCalendar
        open={openCalendar}
        handleClose={() => {
          setOpenCalendar(false);
        }}
        id={calendarId}
        userName={userName}
      />
    </Grid>
  );
}
