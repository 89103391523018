import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Tooltip,
} from "@mui/material";
import { Button } from "@mui/material";
import { Delete, Edit, FileOpen } from "@mui/icons-material";
import { useProtocolList } from "../../../../../hooks/useProtocolList";
import LoadingMain from "../../../../public/LoadingMain";
import {
  TableTypography,
  paperStyle,
  tableStyle,
  tooltipAdmin,
  tooltipEdit,
  tooltipDelete,
} from "../../../../../Styles";
import { esESGrid } from "../../../../../constants/esESGrid";
import { useNavigate } from "react-router-dom";

export function ProtocolTable() {
  const navigate = useNavigate();
  const {
    protocolList,
    deleteDialogOpen,
    handleCloseDeleteDialog,
    handleDeleteConfirmed,
    deleteProtocol,
  } = useProtocolList();
  if (!protocolList || protocolList.length === 0) {
    return <LoadingMain />;
  }
  const columns = [
    {
      field: "protocolGroup",
      headerName: "Grupo",
      flex: 2,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "title",
      headerName: "Título",
      flex: 4,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "publicationDate",
      headerName: "Publicado",
      flex: 1,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "views",
      headerName: "Vistas",
      typeof: "number",
      flex: 1,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actions",
      headerName: "Acciones",
      flex: 2,
      editable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <Tooltip
            title="Abrir protocolo"
            onClick={() => navigate(`../protocol/view/${params.id}`)}
          >
            <IconButton size="small" sx={tooltipEdit}>
              <FileOpen />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Editar protocolo"
            onClick={() => navigate(`../protocol/edit/${params.id}`)}
          >
            <IconButton size="small" sx={tooltipAdmin}>
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Eliminar" onClick={() => deleteProtocol(params.id)}>
            <IconButton size="small" sx={tooltipDelete}>
              <Delete />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];
  return (
    <Container>
      <Paper sx={{ ...paperStyle, mb: 1 }}>
        <TableTypography>Protocolos</TableTypography>
      </Paper>
      <Paper sx={paperStyle}>
        <DataGrid
          sx={tableStyle}
          rows={protocolList}
          columns={[...columns]}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
            filter: {
              filterModel: {
                items: [],
                quickFilterValues: [""],
              },
            },
          }}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableRowSelectionOnClick
          disableColumnMenu
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          pageSizeOptions={[10, 20, 30, 40]}
          localeText={esESGrid}
        />
      </Paper>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
        style={{ textAlign: "center" }}
      >
        <DialogTitle id="delete-dialog-title">Confirmar Borrado</DialogTitle>
        <DialogContent>
          Seguro/a desea borrar el contenido seleccionado?
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteConfirmed} color="error">
            Borrar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
