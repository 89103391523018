import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Footer } from "./components/public/Footer";
import ProtocolForm from "./components/secured/Content/Document/Admin/ProtocolForm.js";
import ProtocolView from "./components/secured/Content/Document/User/ProtocolView.js";
import UpdateUser from "./components/secured/PersonnelManagement/Admin/UpdateUser.js";
import ProtocolList from "./components/secured/Content/Document/User/ProtocolList.js";
import Profile from "./components/secured/Common/Profile.js";
import { AppBar } from "./components/secured/Common/AppBar.js";
import { ProtectedRoute } from "./constants/ProtectedRoute";
import Dashboard from "./components/secured/PersonnelManagement/Admin/TablesRedirect.js";
import ProceduresList from "./components/secured/Content/Document/User/ProceduresList.js";
import NotFound from "./components/public/NotFound";
import Events from "./components/secured/Calendar/User/Events.js";
import AdminRequestedChanges from "./components/secured/Calendar/Admin/AdminRequestedChanges.js";
import AdminCalendar from "./components/secured/Calendar/Admin/AdminCalendar.js";
import ResetPassword from "./components/public/ResetPassword.js";
import AuthHandler from "./context/AuthHandler.js";
import Landing from "./components/public/Landing.js";
import DocumentUpload from "./components/secured/Content/Document/Admin/DocumentUpload.js";
import DocumentEdit from "./components/secured/Content/Document/Admin/DocumentEdit.js";
import VideoView from "./components/secured/Content/Video/User/VideoView.js";
import VideoList from "./components/secured/Content/Video/User/VideoList.js";
import { Home } from "./components/secured/PersonnelManagement/Dashboard/Home.js";
import UpdateVideo from "./components/secured/Content/Video/Admin/UpdateVideo.js";
import Info from "./components/secured/Common/Info.js";

function App() {
  return (
    <BrowserRouter>
      <AuthHandler>
        {(user) => (
          <>
            <AppBar userRole={user?.role} profession={user?.profession} />
            <Routes>
              <Route path="/" element={<Navigate to="/landing" />} />
              <Route path="/landing" element={<Landing />} />
              <Route
                path="/reset-password/:token"
                element={<ResetPassword />}
              />
              <Route path="*" element={<NotFound />} />
              <Route
                element={
                  <ProtectedRoute
                    redirectTo="/home"
                    isAllowed={!!user && user.status === "ACTIVE"}
                  />
                }
              >
                <Route
                  path="/home"
                  element={
                    <Home userRole={user?.role} profession={user?.profession} />
                  }
                />
                <Route path="/protocol/list" element={<ProtocolList />} />
                <Route path="/procedure/list" element={<ProceduresList />} />
                <Route path="/protocol/view/:id" element={<ProtocolView />} />
                <Route path="/videos" element={<VideoList />} />
                <Route path="/video/view/:id" element={<VideoView />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/calendar" element={<Events />} />
                <Route path="/info" element={<Info />} />
                <Route path="*" element={<NotFound />} />
              </Route>
              <Route
                element={
                  <ProtectedRoute
                    redirectTo="/home"
                    isAllowed={
                      !!user &&
                      (user.role === "MANAGER" ||
                        user.role === "ADMIN" ||
                        user.role === "SUPERADMIN") |
                        (user.profession === "ACCOUNTANT")
                    }
                  />
                }
              >
                <Route
                  element={
                    <ProtectedRoute
                      redirectTo="/home"
                      isAllowed={user?.profession !== "ACCOUNTANT"}
                    />
                  }
                >
                  <Route
                    path="/manager/data"
                    element={<Dashboard userRole={user.role} />}
                  />
                  <Route path="/protocol/upload" element={<ProtocolForm />} />
                  <Route path="/protocol/edit/:id" element={<DocumentEdit />} />
                  <Route path="/user/update/:id" element={<UpdateUser />} />
                  <Route path="/video/update/:id" element={<UpdateVideo />} />
                  <Route
                    path="/manager/calendar/:title/:id"
                    element={<AdminCalendar />}
                  />
                  <Route
                    path="/manager/requested-changes/:title/:id"
                    element={<AdminRequestedChanges />}
                  />
                  <Route
                    path="/content/upload-protocol"
                    element={<DocumentUpload />}
                  />
                  <Route path="*" element={<NotFound />} />
                </Route>
              </Route>
            </Routes>
            <Footer userRole={user?.role} />
          </>
        )}
      </AuthHandler>
    </BrowserRouter>
  );
}

export default App;
