import React from "react";
import userslogo from "../public/assets/images/dh-logo.svg";
import managersLogo from "../public/assets/images/dh-logo-header-managers.svg";
import adminsLogo from "../public/assets/images/dh-logo-header-admins.svg";
import superadminsLogo from "../public/assets/images/dh-logo-header-superadmin.svg";
import { Box, Button } from "@mui/material";
import { headerStyle } from "../../Styles";
import { useNavigate } from "react-router-dom";
import { useIsMobile } from "../../hooks/useIsMobile";
export const Header = ({userRole}) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  return (
    <Button
      disableRipple
      sx={{ "&:hover": { backgroundColor: "inherit" } }}
      style={{ ...headerStyle, display: isMobile && "none" }}
      onClick={() => {
        navigate("/home");
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: `url(${userRole === "MANAGER" ? managersLogo : userRole === "ADMIN" ? adminsLogo : userRole === "SUPERADMIN" ? superadminsLogo : userslogo})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "90%",
          height: "90%",
        }}
      />
    </Button>
  );
};
