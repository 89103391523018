import { Container, Grid } from "@mui/material";
import React from "react";
import { Tables } from "./Tables";
import EventValue from "./EventValue";
import { useIsMobile } from "../../../hooks/useIsMobile";

const AccHome = () => {
  const isMobile = useIsMobile();
  return (
    <Container maxWidth={"xl"}>
      <Grid container spacing={isMobile ? 3 : 2}>
        <EventValue />
        <Tables />
      </Grid>
    </Container>
  );
};

export default AccHome;
